<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <!-- <div class="card-body px-0"> -->
            <!-- <div class="page-leftheader my-3">
                <div class="page-title">Apply Extra Time Out</div>
            </div> -->
        <!-- </div> -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card" style="margin-bottom:10px">
                    <div class="card-body row">
                        <div class="col-md-3 col-sm-3 col-xs-3">
                            <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                <label class="form-label">Select Date<span style="color: rgb(248, 111, 111);">
                                        &nbsp;*</span></label>
                                <div class="input-group">
                                    <input required type="date" id="singledate" name="singledaterange"
                                        class="form-control" placeholder="select date" [(ngModel)]="date"
                                        (change)="getBalanceExtraTimeOut(date)" />

                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3">
                            <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                <label class="form-label">From Time <span style="color: rgb(248, 111, 111);">
                                    &nbsp;*</span></label>
                                <div class="input-group">
                                    <input required type="time" id="fromtime" name="fromtime"
                                        class="form-control" placeholder="select time" [(ngModel)]="fromTime"
                                         (change)="calculateTotalMinutes(fromTime,toTime)"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3">
                            <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                <label class="form-label">To Time <span style="color: rgb(248, 111, 111);">
                                    &nbsp;*</span></label>
                                <div class="input-group">
                                    <input required type="time" id="totime" name="totime"
                                        class="form-control" placeholder="select time" [(ngModel)]="toTime"
                                        (change)="calculateTotalMinutes(fromTime,toTime)"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3">
                            <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                <label class="form-label">Total Minutes</label>
                                <div>{{totalMinutes == undefined ? '--' : totalMinutes }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            <div class="form-group label-floating  col-xl-12 col-md-12 col-lg-12">
                                <label class="form-label">Reason<span style="color: rgb(248, 111, 111);">
                                        &nbsp;*</span></label>
                                <textarea required id="reason" [(ngModel)]="reason" class="form-control" rows="1"
                                    placeholder="Please enter reason for extra time out."></textarea>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-3 col-xs-3">
                            <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12"
                            [style.display]="balanceExtraTimeOut !== undefined ? 'block':'none '">
                                <label class="form-label">Balance(minutes)</label>
                                <span class="badge badge-danger badge-pill ms-2">{{balanceExtraTimeOut}}</span>
                            </div>
                        </div>
                       
                    </div>
                    <div class="card-footer">
                        <div class="d-flex">
                            <div class="ms-auto">
                                <button [disabled] class="btn btn-success" type="submit"
                                    (click)="submit()">Submit</button>
                                <button type="button" class="btn btn-danger ms-2" (click)="clear()">Clear</button>
                            </div>
                        </div>
                    </div>
                </div>

<!--Extra Time Out History-->
                <div class="card">
                    <div class="card-body">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="card-header border-bottom-0">
                                    <h3 class="card-title">Extra Time Out History</h3>
                                </div>
                                <div class="card-body card1S p-0">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                            id="hr-table">
                                            <thead class="tablerow">
                                                <tr class="rowcolors stickyatTop">
                                                    
                                                    <th class="border-bottom-0 w-5 fs-6 ">Applied Date </th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">From Time</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">To Time </th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Total Time </th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Status</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Approved By</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody style="max-height: 50vh; overflow: scroll;">
                                                <tr *ngFor="let eto of extraTimeOutList; let i = index">
                                                    
                                                    <td>{{eto.date | date }}</td>
                                                    <td>{{convertTime(eto.fromTime) }}</td>
                                                    <td>{{convertTime(eto.toTime) }}</td>
                                                    <td>{{eto.totalMinutes.slice(0, 5) }}</td>
                                    
                                                    <td>

                                                        <span style="font-size: 13px;font-weight: 500;"
                                                        [ngClass]="eto.status === 'Approved' ? 'bg-custom-green text-custom-dark' : eto.status === 'Extra Time Out Cancel Pending' ? 'bg-custom-orange1 text-custom-dark' : eto.status === 'Extra Time Out Approve Pending' ? 'bg-custom-orange2 text-custom-dark' :  eto.status === 'Rejected' ? 'bg-custom-red text-custom-dark' : eto.status === 'Extra Time Out Cancel Rejected' ? 'badge-danger':eto.status === 'Extra Time Out Cancel Pending' ? 'badge-warning text-custom-dark' : 'bg-custom-warning text-custom-dark'"
                                                        class="badge">{{eto.status}}</span>

                                                    </td>
                                                    <td>{{eto.updatedby }}</td>
                                                    
                                                    <td class="text-start d-flex">
                                                        <button *ngIf="eto.status !== 'Extra Time Out Cancel Rejected'"
                                                            type="button" (click)="onDeleteHandler(eto)"
                                                            class="btn btn-danger btn-icon btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top"
                                                            title="Delete">
                                                            <i class="feather feather-trash-2 "></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Applied extra time out will be deleted permanently. Are you sure you want to continue ?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="confirmDelete()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>

            </div>

        </div>
    </div>
</div>
