<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">

        </div>
        <div class="row pt-3">
            <div class="col-md-12">
                <div class="card">
                    <!-- <div class="card-header border-0">
                        <h4 class="card-title">Reset Employee Password</h4>
                    </div> -->
                    <div class="card-body">
                        <form action="#">
                            <div class="table-responsive">
                                <div class="pt-2 scroll " style="min-height:fit-content;max-height: 60vh;">
                                    <div class="col-xs-12 mobile">
                                        <div>

                                            <table
                                                class="table table-hover  table-vcenter text-nowrap table-bordered border-bottom"
                                                id="hr-notice">
                                                <thead class="tablerow">
                                                    <tr class="rowcolors stickyatTop">
                                                        <th class="border-bottom-0 w-2 fs-6"><input type="checkbox"
                                                                style="cursor: pointer;"
                                                                (change)="checkAllSelected($event)" /></th>
                                                        <th class="border-bottom-0 w-5 fs-6">Employee ID</th>
                                                        <th class="border-bottom-0 w-5 fs-6">Employee Name</th>
                                                        <th class="border-bottom-0 w-5 fs-6">Employee User Name</th>
                                                        <th class="border-bottom-0 w-5 fs-6">Employee Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let employeeList of resetPasswordBulk">
                                                        <td><input type="checkbox" style="cursor: pointer;"
                                                                (change)="checkSelected(employeeList,$event)" /></td>
                                                        <td data-th="employeeList.empId"> {{employeeList.empId}}
                                                        </td>
                                                        <td data-th="employeeList.name">
                                                            {{employeeList.name}}
                                                        </td>
                                                        <td data-th="employeeList.displayName">
                                                            {{employeeList.displayName}}
                                                        </td>
                                                        <td data-th="employeeList.email">{{employeeList.email}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pro-fab">
                                <button type="button" (click)="ResetHandler()" class="btn btn-success">Reset</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>