<div class="app-content main-content">
    <div class="side-app main-container">

        <div class="alertcomp"></div>

        <!-- <div class="card-body px-0"> -->
            <!-- <div class="page-leftheader my-3">
                <div class="page-title">Client Projects</div>
            </div> -->
        <!-- </div> -->

        <div class="col-xl-12 col-md-12 col-lg-12" style="padding: 0; margin-bottom: 30px;">
            <div class="card">

                <div class="card-body row justify-content-between">
                    <div class="col-xs-11 col-md-11 col-sm-10 col-10">

                        <div class="my-3">

                            <select id="employeeMasterLucene" class="form-control" name="Employee Name"
                                id="employeename" placeholder="Search Business Name" autocomplete="off"
                                list="employeeMasterLucene" autofocus="autofocus"
                                [(ngModel)]="contractClientProDataForm.business_name"
                                (ngModelChange)="getclientLocDetail($event,contractClientProDataForm)">
                                <option data-value={{emp.business_name}} *ngFor="let emp of contractClientData">
                                    {{emp.business_name}}
                                </option>
                            </select>

                        </div>
                    </div>

                    <div class="col-xs-1 col-md-1 col-sm-2 col-2 my-3">
                        <button type="button" class="btn btn-primary w-100" (click)="getData()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="contractClientProInfoData.length">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">{{cllientBusName}} project details</h4>
                    </div>
                    <div class="card-body">

                        <form id="contractclientsList" name="contractclientsList" type="multipart/form-data">
                            <div class="col-xs-12 mobile">

                                <div class="scrollsearchresults">
                                    <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class="rowcolors">
                                                <th class="border-bottom-0 w-5 fs-6">Project Name</th>
                                                <th class="border-bottom-0 w-5 fs-6">Start Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">End Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">Remarks</th>
                                                <th class="border-bottom-0 w-5 fs-6">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let contractclient of contractClientProInfoData">
                                                <td data-th="contractclientName">{{contractclient.projectname}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.start_date |
                                                    date:'MM/dd/yyyy'}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.end_date |
                                                    date:'MM/dd/yyyy'}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.remarks}}
                                                </td>

                                                <td>
                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="contractclientLocEdit(contractclient)"
                                                        data-method="edit" title="Edit" id="navigateclientLocTwo">
                                                        <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                            data-original-title="edit"></i>
                                                    </button>

                                                    <button class="btn btn-danger btn-icon btn-sm me-2"
                                                        (click)="contractclientLocDeleteDataStore(contractclient)"
                                                        data-method="delete" title="Delete"
                                                        data-bs-target="#showAlertWarning">
                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                            data-original-title="delete"></i>
                                                    </button>

                                                    <!-- <input type='button' class="btn btn-warning mt-2" value='Warning alert' id='click1'> -->
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body pt-0">

                <!-- <div class="accordion" id="contract-client-project-accordion">
                    <div class="accordion-item bg-transparent">

                        <div class="accordion-header" id="headingOne">
                            <button class="accordion-button shadow-none bg-light text-muted collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                aria-controls="collapseOne">
                                <h4 class="card-title"><i class="fa fa-info-circle"
                                        aria-hidden="true"></i>&nbsp;&nbsp;Add Project Information</h4>
                            </button>
                        </div>

                        <div id="collapseOne" class="accordion-collapse collapse disable-div"
                            aria-labelledby="headingOne">
                            <div class="accordion-body p-0 pt-4 pb-4"> -->
                <div aria-multiselectable="true" class="accordion" id="accordion" role="tablist">
                    <div class="acc-card">

                        <div class="acc-header" id="headingOne" role="tab">
                            <h5 class="mb-0">
                                <a aria-controls="collapseOne" aria-expanded="true" data-bs-toggle="collapse"
                                    href="#collapseOne">
                                    <h4 class="card-title"><i class="fa fa-info-circle"
                                            aria-hidden="true"></i>&nbsp;&nbsp;Add Project Information</h4>
                                </a>
                            </h5>
                        </div>

                        <div aria-labelledby="headingOne" class="collapse" data-parent="#accordion" id="collapseOne"
                            role="tabpanel">
                            <div class="acc-body p-0 pt-4 pb-4">

                                <form id="conCliForm" (ngSubmit)="contractProForm.form.valid" name="registerForm"
                                    #contractProForm="ngForm" novalidate>
                                    <section>
                                        <div class="card-body">
                                            <div class="card-body row">
                                                <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                                    <div class="form-group label-floating">
                                                        <label class="form-label">Project Name</label>
                                                        <div>
                                                            <input type="text" class="form-control col-md-12 col-xs-12"
                                                                autocomplete="off" placeholder="Enter Project Name"
                                                                autofocus="true" name="projectname"
                                                                #projectname="ngModel"
                                                                [(ngModel)]="projectinfo.projectname" required />

                                                            <p *ngIf="submitted && !projectname.valid"
                                                                class="help-block " style="color: #ff5757;">
                                                                ⓘ Project name is required
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                                    <div class="form-group label-floating m-0">
                                                        <label class="form-label">Project Owner</label>

                                                        <div [ngClass]="isUpdate ? 'row' : 'd-none'">
                                                            <div class="col-md-10 col-sm-10 col-xs-10">
                                                                <div class="form-group">
                                                                    <div>
                                                                        <input type="text"
                                                                            class="form-control col-md-12 col-xs-12"
                                                                            autocomplete="off"
                                                                            placeholder="Enter Owner Name"
                                                                            autofocus="true" name="name"
                                                                            [(ngModel)]="projectinfo.name"
                                                                            disabled="true" />

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-2 col-sm-2 col-xs-2">
                                                                <button class="btn btn-primary btn-icon btn-sm w-100"
                                                                    data-method="Owner" title="Add more owners"
                                                                    data-bs-target="#showAddOwners"
                                                                    (click)="addMoreOwner()">
                                                                    <i class="fa fa-plus" data-bs-toggle="tooltip"
                                                                        data-original-title="Owner"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div class="row"
                                                            [ngClass]="isUpdate ? 'd-none' : 'd-flex justify-content-between'">

                                                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                <button class="btn btn-primary w-100"
                                                                    data-method="Owner" title="View owners"
                                                                    data-bs-target="#showAddOwners"
                                                                    (click)="addMoreOwner()">
                                                                    View Owner
                                                                </button>
                                                            </div>

                                                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                <button class="btn btn-primary w-100"
                                                                    data-method="Owner" title="Add more owners"
                                                                    data-bs-target="#showAddOwners"
                                                                    (click)="addMoreOwner()">
                                                                    Add Owner
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="clearfix"></div>

                                            <div class="card-body row">
                                                <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                                    <div class="form-group label-floating">
                                                        <label class="form-label">Start Date</label>
                                                        <div>
                                                            <input type="date" class="form-control col-md-12 col-xs-12"
                                                                autocomplete="off" name="start_date" autofocus="true"
                                                                #start_date="ngModel"
                                                                [(ngModel)]="projectinfo.start_date"
                                                                [disabled]="freezeDateCheckStart"
                                                                (ngModelChange)="toggleDisable($event); checkFromAndToDate()"
                                                                required />

                                                            <p *ngIf="submitted && !start_date.valid"
                                                                class="help-block " style="color: #ff5757;">
                                                                ⓘ Project start date is required
                                                            </p>

                                                            <!-- <p *ngIf="freezeDateCheckStart" class="help-block "
                                                                style="color: #ff5757;">
                                                                ⓘ Project is freezed till {{projectFreezeDate}}
                                                            </p> -->
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                                    <div class="form-group label-floating">
                                                        <label class="form-label">End Date</label>
                                                        <div>
                                                            <input type="date" class="form-control col-md-12 col-xs-12"
                                                                autocomplete="off" name="end_date" autofocus="true"
                                                                #end_date="ngModel" [(ngModel)]="projectinfo.end_date"
                                                                [disabled]="isdisable"
                                                                (ngModelChange)="checkFromAndToDate()" required />

                                                            <p *ngIf="submitted && !end_date.valid" class="help-block "
                                                                style="color: #ff5757;">
                                                                ⓘ Project end date is required
                                                            </p>

                                                            <p *ngIf="endDate" class="help-block "
                                                                style="color: #ff5757;">
                                                                ⓘ End date should be greater than start date
                                                            </p>

                                                            <p *ngIf="freezeDateCheckEnd" class="help-block "
                                                                style="color: #ff5757;">
                                                                ⓘ Project is freezed till {{projectFreezeDate}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="clearfix"></div>

                                            <div class="col-md-6 col-sm-6 col-xs-6 pe-4 ps-4">
                                                <div class="form-group label-floating">
                                                    <label class="form-label">Remarks</label>
                                                    <div>
                                                        <input type="text" class="form-control col-md-12 col-xs-12"
                                                            autocomplete="off" name="remarks"
                                                            placeholder="Enter Remarks" autofocus="true"
                                                            #remarks="ngModel" [(ngModel)]="projectinfo.remarks"
                                                            required />

                                                        <p *ngIf="submitted && !remarks.valid" class="help-block "
                                                            style="color: #ff5757;">
                                                            ⓘ Remarks is required
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="actionBar pe-4 ps-4">
                                                <div class="clearfix"> </div>
                                                <div>
                                                    <button class="btn btn-success" type="button" id="save-btn-1"
                                                        style="display: inline-block"
                                                        (click)="saveProjectInfo(contractProForm.form.valid)">Save</button>
                                                    <button class="btn btn-success" type="button" id="update-btn-1"
                                                        style="display: none"
                                                        (click)="updateProjectInfo(contractProForm.form.valid)">Update</button>
                                                    <button class="btn btn-danger ms-2" type="button"
                                                        (click)="clearProjectInfo()">Clear</button>
                                                </div>
                                            </div>

                                        </div>
                                    </section>
                                    <div class="clearfix"> </div>
                                </form>

                            </div>
                        </div>

                    </div>

                    <!-- <div class="accordion-item bg-transparent disable-div" id="collapseTwoMain">
                        <div class="accordion-header" id="headingTwo">
                            <button class="accordion-button shadow-none bg-light text-muted collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                aria-controls="collapseTwo">
                                <h4 class="card-title"><i class="fa fa-folder" aria-hidden="true"></i>&nbsp;&nbsp;Add
                                    Project Documents</h4>
                            </button>
                        </div>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo">
                            <div class="accordion-body p-0 pt-4 pb-4"> -->
                    <div class="acc-card disable-div" id="collapseTwoMain">
                        <div class="acc-header" id="headingTwo" role="tab">
                            <h5 class="mb-0">
                                <a aria-controls="collapseTwo" aria-expanded="false" class="collapsed"
                                    data-bs-toggle="collapse" href="#collapseTwo">
                                    <h4 class="card-title"><i class="fa fa-folder"
                                            aria-hidden="true"></i>&nbsp;&nbsp;Add
                                        Project Documents</h4>
                                </a>
                            </h5>
                        </div>
                        <div aria-labelledby="headingTwo" class="collapse" data-parent="#accordion" id="collapseTwo"
                            role="tabpanel">
                            <div class="acc-body p-0 pt-4 pb-4">
                                <section>
                                    <div class="card-body">

                                        <div style="padding: 15px;">
                                            <div>
                                                <div class="dropzone" fileDragDrop
                                                    (filesChangeEmiter)="onFileChange($event)">
                                                    <div class="text-wrapper bgcolors">
                                                        <div class="centered">
                                                            <input type="file" name="file" id="file"
                                                                class="dropzone-file"
                                                                (change)="onFileChange($event.target.files)" multiple />
                                                            <label for="file" style="cursor: pointer;"><span
                                                                    class="textLink">Select your file</span> or Drop
                                                                it
                                                                here!</label>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div>
                                                <mat-list>

                                                    <h3 mat-subheader *ngIf="files.length">Selected Files</h3>

                                                    <mat-list-item *ngFor="let file of files;let i=index">

                                                        <div class="form-group form-group-one">
                                                            <mat-icon mat-list-icon>folder</mat-icon>

                                                            <h4 mat-line class="custom-h4">{{file.name}}</h4>
                                                        </div>

                                                        <div class="form-group form-group-two">
                                                            <input type="text" (input)="getcomment($event,i)"
                                                                class="form-control" autocomplete="off"
                                                                name="documentcomment" placeholder="Enter Comment"
                                                                autofocus="true" />

                                                            <button type="button" class="btn btn-danger"
                                                                (click)="deleteFile(file)"><i class="fa fa-trash"
                                                                    data-original-title="Delete document"></i></button>

                                                        </div>
                                                        <mat-divider></mat-divider>

                                                    </mat-list-item>

                                                    <h3 mat-subheader *ngIf="serverFiles.length">Uploaded Files</h3>

                                                    <mat-list-item *ngFor="let file of serverFiles;let i=index">

                                                        <div class="form-group form-group-one">
                                                            <mat-icon mat-list-icon>folder</mat-icon>

                                                            <h4 mat-line class="custom-h4">{{file.documentname}}</h4>
                                                        </div>

                                                        <div class="form-group form-group-two">
                                                            <input type="text" class="form-control" autocomplete="off"
                                                                name="documentcomment" placeholder="Enter Comment"
                                                                autofocus="true"
                                                                [(ngModel)]="file?.comments === 'null' ? undefined : file.comments "
                                                                (ngModelChange)="updateServerFileComment($event,file)" />

                                                            <button type="button" class="btn btn-danger"
                                                                (click)="deleteServerFile(file)"><i class="fa fa-trash"
                                                                    data-original-title="Delete document"></i></button>

                                                        </div>
                                                        <mat-divider></mat-divider>

                                                    </mat-list-item>

                                                </mat-list>

                                            </div>
                                        </div>

                                        <div class="actionBar pe-4 ps-4">
                                            <div class="clearfix"> </div>
                                            <div>
                                                <button class="btn btn-success {{files.length ? '' : 'disable-div' }}"
                                                    type="button" id="save-btn-2" style="display: inline-block"
                                                    (click)="fileUpdate = false; uploadDocs()">Upload
                                                    Documents</button>
                                                <button
                                                    class="btn btn-success {{files.length || updateCommentInDoc ? '' : 'disable-div' }}"
                                                    type="button" id="update-btn-2" style="display: none"
                                                    (click)="fileUpdate = true; updateDocs()">Update
                                                    Documents</button>
                                                <button
                                                    class="btn btn-danger ms-2 {{files.length ? '' : 'disable-div' }}"
                                                    type="button"
                                                    (click)="fileUpdate = false; clearUploadDocs()">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div class="clearfix"> </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="accordion-item bg-transparent disable-div" id="collapseThreeMain">
                        <div class="accordion-header" id="headingThree">
                            <button class="accordion-button shadow-none bg-light text-muted collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                                aria-controls="collapseThree">
                                <h4 class="card-title"><i class="fa fa-map" aria-hidden="true"></i>&nbsp;&nbsp;Add
                                    Project
                                    Locations</h4>
                            </button>
                        </div>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree">
                            <div class="accordion-body p-0 pt-4 pb-4"> -->
                    <div class="acc-card disable-div" id="collapseThreeMain">
                        <div class="acc-header" id="headingThree" role="tab">
                            <h5 class="mb-0">
                                <a aria-controls="collapseThree" aria-expanded="false" class="collapsed"
                                    data-bs-toggle="collapse" href="#collapseThree">
                                    <h4 class="card-title"><i class="fa fa-map" aria-hidden="true"></i>&nbsp;&nbsp;Add
                                        Project
                                        Locations</h4>
                                </a>
                            </h5>
                        </div>
                        <div aria-labelledby="headingThree" class="collapse" data-parent="#accordion" id="collapseThree"
                            role="tabpanel">
                            <div class="acc-body p-0 pt-4 pb-4">
                                <section>
                                    <div class="card-body">

                                        <div class="page-leftheader col-md-12 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-label m-0">Search Location Name</div>
                                        </div>

                                        <div>
                                            <div class="card-body row justify-content-between pt-0">
                                                <div class="col-md-10 col-sm-10 col-xs-12 pe-4 ps-4">

                                                    <div class="my-3">

                                                        <input matInput type="text" class="form-control"
                                                            [matAutocomplete]="autox" name="city"
                                                            placeholder="Enter Location Name"
                                                            [(ngModel)]="contractClientProDataForm.location_name"
                                                            (ngModelChange)="getclientLocNames($event,contractClientProDataForm)" />
                                                        <mat-autocomplete #autox="matAutocomplete">
                                                            <mat-option *ngFor="let street of contractClientLocListData"
                                                                [value]="street.location_name">
                                                                {{street.location_name}}
                                                            </mat-option>
                                                        </mat-autocomplete>

                                                    </div>
                                                </div>

                                                <div class="col-md-2 col-sm-2 col-xs-2 my-3">
                                                    <button type="button" class="btn btn-success w-100"
                                                        (click)="addLocationToPro()">
                                                        Add
                                                    </button>
                                                </div>
                                            </div>

                                            <div style="padding: 15px;">
                                                <mat-list>

                                                    <h3 mat-subheader *ngIf="contractClientLocList.length">Selected
                                                        Locations</h3>

                                                    <mat-list-item
                                                        *ngFor="let locName of contractClientLocList; let i=index">

                                                        <h4 mat-line class="custom-h4">{{locName.location_name}}</h4>

                                                        <button type="button" class="btn btn-danger form-group"
                                                            (click)="deleteLocName(locName, i)"><i class="fa fa-trash"
                                                                data-original-title="Delete Location"></i></button>

                                                        <mat-divider></mat-divider>

                                                    </mat-list-item>

                                                    <h3 mat-subheader *ngIf="serverContractClientLocList.length">
                                                        Uploaded Locations</h3>

                                                    <mat-list-item
                                                        *ngFor="let locName of serverContractClientLocList; let i=index">

                                                        <h4 mat-line class="custom-h4">{{locName.location_name}}</h4>

                                                        <button type="button" class="btn btn-danger form-group"
                                                            (click)="StoreDeleteServerLocName(locName)"><i
                                                                class="fa fa-trash"
                                                                data-original-title="Delete Location"></i></button>

                                                        <mat-divider></mat-divider>

                                                    </mat-list-item>

                                                </mat-list>
                                            </div>

                                        </div>

                                        <div class="actionBar pe-4 ps-4">
                                            <div class="clearfix"> </div>
                                            <div>
                                                <button
                                                    class="btn btn-success {{contractClientLocList.length ? '' : 'disable-div' }}"
                                                    type="button" id="save-btn-3" style="display: inline-block"
                                                    (click)="locUpdate = false ;uploadLoc()">Add
                                                    Locations</button>
                                                <button
                                                    class="btn btn-success {{contractClientLocList.length ? '' : 'disable-div' }}"
                                                    type="button" id="update-btn-3" style="display: none"
                                                    (click)="locUpdate = true; updateLoc()">Update
                                                    Locations</button>
                                                <button
                                                    class="btn btn-danger ms-2 {{contractClientLocList.length ? '' : 'disable-div' }}"
                                                    type="button"
                                                    (click)="locUpdate = false; clearUploadLoc()">Clear</button>
                                            </div>
                                        </div>

                                    </div>
                                </section>
                                <div class="clearfix"> </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>

<div id="showAddOwners" class="modal" style="top: 40px;">
    <div class="modal-dialog" role="document">
        <div class="modal-content animate">
            <div class="modal-header">
                <h4 class="modal-title">Add More Owner</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
            </div>

            <div class="card-body row justify-content-between pt-0">
                <div class="col-md-10 col-sm-10 col-xs-12 pe-4 ps-4">

                    <div class="my-3">

                        <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dGroup"
                            name="uniqueemployeeid" placeholder="Select Employee"
                            [(ngModel)]="contractClientProDataForm.name"
                            (ngModelChange)="getProOwnerNames($event, contractClientProDataForm)">

                            <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                Select Employee</option>
                            <option value={{data.name}} *ngFor="let data of employeeList">
                                {{data.name}}
                            </option>

                        </select>

                        <!-- <input matInput type="text" class="form-control" [matAutocomplete]="autox" name="city"
                            placeholder="Enter Location Name" [(ngModel)]="contractClientData.project_owners_names"
                            (ngModelChange)="getProOwnerNames($event)" />
                        <mat-autocomplete #autox="matAutocomplete">
                            <mat-option *ngFor="let street of employeeList" [value]="street">
                                {{street}}
                            </mat-option>
                        </mat-autocomplete> -->

                    </div>
                </div>

                <div class="col-md-2 col-sm-2 col-xs-2 my-3">
                    <button type="button" class="btn btn-success w-100" (click)="addProOwnerName()">
                        Add
                    </button>
                </div>
            </div>

            <div style="padding: 15px;">
                <mat-list>

                    <h3 mat-subheader *ngIf="ownersList.length">Selected Name's</h3>

                    <mat-list>
                        <mat-list-item *ngFor="let locName of ownersList; let i=index">

                            <h4 mat-line class="custom-h4">{{locName.name}}</h4>

                            <button type="button" class="btn btn-danger my-3" (click)="deleteOwnerName(locName, i)"><i
                                    class="fa fa-trash" data-original-title="Delete Owner"></i></button>

                            <mat-divider></mat-divider>

                        </mat-list-item>
                    </mat-list>

                    <h3 mat-subheader *ngIf="serverOwnersList.length">Uploaded Name's</h3>

                    <mat-list-item *ngFor="let locName of serverOwnersList; let i=index">

                        <h4 mat-line class="custom-h4">{{locName.name}}</h4>

                        <button type="button" class="btn btn-danger form-group"
                            (click)="deleteServerOwnerName(locName)"><i class="fa fa-trash"
                                data-original-title="Delete Owner"></i></button>

                        <mat-divider></mat-divider>

                    </mat-list-item>

                    <p class="w-100 text-center" *ngIf="isUpdate">
                        <span *ngIf="ownersList.length">and</span>&nbsp;{{primaryOwnerName}}&nbsp;(You)
                    </p>

                </mat-list>
            </div>


            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" *ngIf="ownersList.length"
                    (click)="clearOwnerList()">Delete All</button> -->
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">OK</button>
            </div>

        </div>
    </div>
</div>

<div id="showAlertWarning" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-alert-circle fs-80 text-warning lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger mb-3">Are you sure you want to delete this data?</h4>
                <!-- <p class="mg-b-20 mg-x-20">This record has data attached to it! it can't be deleted.</p> -->

                <button aria-label="Close" class="btn btn-danger pd-x-25 mx-1" data-bs-dismiss="modal"
                    (click)="contractclientLocDeleteData(); clearProjectInfo(); collapseItems()">Delete</button>
                <button aria-label="Close" class="btn btn-primary pd-x-25 mx-1" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertDataAttachedToPro" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-link fs-80 text-danger lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: The project can't be deleted!</h4>
                <p class="mg-b-20 mg-x-20">This project has data attached to it! it can't be deleted.</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantDelete" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-x-circle fs-80 text-danger lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: The data can't be deleted!</h4>
                <p class="mg-b-20 mg-x-20">This record couldn't be deleted! Please try again.</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertWarningDeleteServerLoc" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-alert-circle fs-80 text-warning lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger mb-3">Are you sure you want to delete this location?</h4>
                <!-- <p class="mg-b-20 mg-x-20">This record has data attached to it! it can't be deleted.</p> -->

                <button aria-label="Close" class="btn btn-danger pd-x-25 mx-1" data-bs-dismiss="modal"
                    (click)="deleteServerLocName()">Delete</button>
                <button aria-label="Close" class="btn btn-primary pd-x-25 mx-1" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantDeleteFreeze" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-cloud-snow fs-80 text-primary lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: This project is freezed!</h4>
                <p class="mg-b-20 mg-x-20">The data can't be deleted</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCouldntDelete" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-x-circle fs-80 text-primary lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: Project not found!</h4>
                <p class="mg-b-20 mg-x-20">Couldn't delete data.</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertDataAttachedToLoc" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-link fs-80 text-danger lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: The location can't be deleted!</h4>
                <p class="mg-b-20 mg-x-20">This location has data attached to it! it can't be deleted.</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>

        </div>
    </div>
</div>