<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Pay Calculation Rules</div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-md-12">
                <div class="card-content">
                    <form #payCalcualteForm="ngForm">
                        <section>
                            <div class="card">
                                <div class="card-body">
                                    <div class=" row col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Enter Rule
                                                    Name</label>
                                                <div>
                                                    <input type="text" name="ruleName"
                                                        class="form-control col-md-12 col-xs-12" autocomplete="off"
                                                        placeholder="Enter Rule Name" autofocus="true"
                                                        [(ngModel)]="insertPayCalRules.ruleName" required
                                                        #ruleName_m="ngModel"
                                                        [ngClass]="(!ruleName_m.valid && ruleName_m.touched) ? 'is-invalid' : ''" />
                                                </div>
                                                <span class="help-text text-danger"
                                                    *ngIf="(!ruleName_m.valid && ruleName_m.touched)">Enter
                                                    Rule Name
                                                </span>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Select Salary Head Name</label>
                                                <div class="col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                    <div class="form-group label-floating">
                                                        <div class="w-100" [ngClass]="{'disable': addOrUpdate!=true}">
                                                            <select class="form-control col-md-12 col-xs-12"
                                                                style="height: 38px;"
                                                                data-style="btn btn-drpdwn btn-round"
                                                                [(ngModel)]="insertPayCalRules.salaryHeadId"
                                                                id="SalaryHead" name="SalaryHead"
                                                                (change)="headChange(insertPayCalRules.salaryHeadId)">
                                                                <option value="">&nbsp;
                                                                </option>
                                                                <ng-container *ngFor="let hd of salaryMaster">
                                                                    <option
                                                                        *ngIf="hd.salaryDetailMaster && hd.salaryDetailMaster.length > 0"
                                                                        value="{{hd.salaryHeadId}}">
                                                                        {{hd.salaryHeadName}}
                                                                    </option>
                                                                </ng-container>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div
                                            class="col-md-6 col-sm-6 col-xs-6 justify-content-between align-items-start">
                                            <div class="form-group label-floating">
                                                <label class="form-label">&nbsp;</label>
                                                <button *ngIf="addOrUpdate; else updateButton" type="button"
                                                    class="btn btn-primary w-40" id="navigatetarget"
                                                    style="margin-right: 3vw;" (click)="addPayCalculateRule()">
                                                    Add
                                                </button>
                                                <ng-template #updateButton>
                                                    <button type="button" id="navigatetarget"
                                                        class="btn btn-primary w-40" style="margin-right: 3vw;"
                                                        (click)="updatePayCalculateRule()">
                                                        Update
                                                    </button>
                                                </ng-template>
                                                <button type="button" class="btn btn-danger w-40"
                                                    (click)="viewPayCalculateRule()">
                                                    View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"> </div>
                                </div>
                            </div>
                            <div class="empty-div-one" id="navigatedest"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card" *ngIf="add_view_toggle == 'addPayCalculateRule' ">
                                        <div class="card-header border-bottom-0">
                                            <h3 class="card-title ">Pay Calculate Rule Configuration </h3>
                                        </div>
                                        <div class="card-body">

                                            <div class="panel-group1" id="accordion1">

                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed  "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseOne" aria-expanded="false">Pay
                                                                Calculation
                                                                Rule Detail</a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseOne" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body m-0 p-0">
                                                            <div class="card-body">
                                                                <div class=" row col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Enter Rule
                                                                                Name<span
                                                                                    class="red m-1">*</span></label>
                                                                            <div>
                                                                                <input type="text" name="ruleName"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Rule Name"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertPayCalRules.ruleName"
                                                                                    required #ruleName_m="ngModel"
                                                                                    [ngClass]="(!ruleName_m.valid && ruleName_m.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <span class="help-text text-danger"
                                                                                *ngIf="(!ruleName_m.valid && ruleName_m.touched)">Enter
                                                                                Rule Name
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Select Salary
                                                                                Head<span
                                                                                    class="red m-1">*</span></label>
                                                                            <!-- [ngClass]="{'disable': addOrUpdate!=true}" -->
                                                                            <div
                                                                                class="col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                                                <div class="form-group label-floating">
                                                                                    <div class="w-100">
                                                                                        <select
                                                                                            class="form-control col-md-12 col-xs-12"
                                                                                            style="height: 38px;"
                                                                                            data-style="btn btn-drpdwn btn-round"
                                                                                            [(ngModel)]="insertPayCalRules.salaryHeadId"
                                                                                            id="SalaryHead"
                                                                                            name="SalaryHead"
                                                                                            (change)="headChange(insertPayCalRules.salaryHeadId)">
                                                                                            <option value="">&nbsp;
                                                                                            </option>
                                                                                            <ng-container
                                                                                                *ngFor="let hd of salaryMaster">
                                                                                                <option
                                                                                                    *ngIf="hd.salaryDetailMaster && hd.salaryDetailMaster.length > 0"
                                                                                                    value="{{hd.salaryHeadId}}">
                                                                                                    {{hd.salaryHeadName}}
                                                                                                </option>
                                                                                            </ng-container>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Select Detail
                                                                                Name</label>
                                                                            <div class="w-100">
                                                                                <select
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertPayCalRules.salaryDetailId"
                                                                                    id="salaryDetailId"
                                                                                    name="salaryDetailId">
                                                                                    <option
                                                                                        *ngFor="let sd of detailMasterSection"
                                                                                        value="{{sd.salaryDetailId}}">
                                                                                        {{sd.salaryDetailName}}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div> -->
                                                                </div>
                                                                <div class="clearfix"> </div>
                                                                <div class=" row col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                                    <!-- <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Enter Rule
                                                                                Name</label>
                                                                            <div>
                                                                                <input type="text" name="ruleName"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Rule Name"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertPayCalRules.ruleName"
                                                                                    required #ruleName_m="ngModel"
                                                                                    [ngClass]="(!ruleName_m.valid && ruleName_m.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <span class="help-text text-danger"
                                                                                *ngIf="(!ruleName_m.valid && ruleName_m.touched)">Enter
                                                                                Detail Name
                                                                            </span>
                                                                        </div>
                                                                    </div> -->
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Select Detail
                                                                                Name<span
                                                                                    class="red m-1">*</span></label>
                                                                            <div class="w-100">
                                                                                <select
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertPayCalRules.salaryDetailId"
                                                                                    id="salaryDetailId"
                                                                                    name="salaryDetailId">
                                                                                    <option
                                                                                        *ngFor="let sd of detailMasterSection"
                                                                                        value="{{sd.salaryDetailId}}">
                                                                                        {{sd.salaryDetailName}}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">DA Arrears
                                                                                Included?</label>

                                                                            <div class="row">
                                                                                <div class="w-42p">
                                                                                    <label
                                                                                        class="form-label p-0 m-0">No</label>
                                                                                </div>
                                                                                <div class="w-50p">
                                                                                    <label class="custom-switch">
                                                                                        <input type="checkbox"
                                                                                            name="custom-switch-checkbox DAarrears"
                                                                                            class="custom-switch-input"
                                                                                            [(ngModel)]="insertPayCalRules.daArears">
                                                                                        <span
                                                                                            class="custom-switch-indicator"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="w-60p">
                                                                                    <label
                                                                                        class="form-label">Yes</label>
                                                                                </div>
                                                                            </div>

                                                                            <!-- <div class="col-md-2">
                                                                                <input type="checkbox" name="DAarrears"
                                                                                    class="form-control col-md-6 col-xs-6"
                                                                                    autocomplete="off" autofocus="true"
                                                                                    [(ngModel)]="insertPayCalRules.daArears" />
                                                                            </div> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="clearfix"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed  "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseTwo" aria-expanded="false">Pay
                                                                Calculation
                                                                Rule Configuration</a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseTwo" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body m-0 p-0">
                                                            <div class="card-body">
                                                                <div class=" row col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Calulate
                                                                                {{insertPayCalRules.ruleName}}
                                                                                by<span class="red m-1">*</span></label>
                                                                            <div class="w-100">
                                                                                <select
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertPayCalRules.percentTotalIndic"
                                                                                    id="percentTotalIndic"
                                                                                    name="percentTotalIndic">
                                                                                    <option value="P">Percentage
                                                                                    </option>
                                                                                    <option value="T">Total</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">..Of..<span
                                                                                    class="red m-1">*</span></label>
                                                                            <div class="w-100">
                                                                                <select (change)="onChangeOff($event)"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertPayCalRules.grossSelectIndic"
                                                                                    id="grossSelectIndic"
                                                                                    name="grossSelectIndic">
                                                                                    <option value="G">Gross</option>
                                                                                    <option value="S">Selected</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="clearfix"> </div>
                                                                <div class=" row col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                                    <div class="col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                                        <!-- // heading  -->
                                                                        <div class="w-100 d-flex">
                                                                            <div class="col-6">
                                                                                <label for="headname"
                                                                                    class="label_css form-label">Head
                                                                                    Name</label>
                                                                            </div>
                                                                            <div class="col-5 px-2">
                                                                                <label for="detailname"
                                                                                    class="label_css form-label">Detail
                                                                                    Name</label>
                                                                            </div>
                                                                            <div class="col-1"></div>
                                                                        </div>
                                                                        <!-- add   -->
                                                                        <section class="w-100 d-flex"
                                                                            id="addAdditionalComp">
                                                                            <div class="col-6 mb-1">
                                                                                <select
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertPayCalRules.ruleComp.selSalaryHeadId"
                                                                                    id="SelSalaryHeadId"
                                                                                    name="SelSalaryHeadId"
                                                                                    (change)="compChange(insertPayCalRules.ruleComp.selSalaryHeadId)">
                                                                                    <option value="">&nbsp;
                                                                                    </option>
                                                                                    <ng-container
                                                                                        *ngFor="let hd of salaryMaster">
                                                                                        <option
                                                                                            *ngIf="hd.salaryDetailMaster && hd.salaryDetailMaster.length > 0"
                                                                                            value="{{hd.salaryHeadId}}">
                                                                                            {{hd.salaryHeadName}}
                                                                                        </option>
                                                                                    </ng-container>
                                                                                </select>
                                                                            </div>
                                                                            <div class="col-5 mb-1 px-2">
                                                                                <select
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertPayCalRules.ruleComp.selSalaryDetailId"
                                                                                    id="SelSalaryDetailId"
                                                                                    name="SelSalaryDetailId">
                                                                                    <option
                                                                                        *ngFor="let sd of detailMasterCompSection"
                                                                                        value="{{sd.salaryDetailId}}">
                                                                                        {{sd.salaryDetailName}}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                            <div class="col-1 d-flex align-items-end">
                                                                                <button type="button"
                                                                                    (click)="addRulesComp(insertPayCalRules.ruleComp.selSalaryHeadId,insertPayCalRules.ruleComp.selSalaryDetailId)"
                                                                                    class="btn btn_fit mb-2"><span><svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16" height="16"
                                                                                            fill="currentColor"
                                                                                            class="bi bi-plus-lg"
                                                                                            viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd"
                                                                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </section>
                                                                        <!-- delete / iterate   -->
                                                                        <div class="w-100 d-flex"
                                                                            *ngFor="let sh of insertPayCalRules.ruleComp; index as i">
                                                                            <ng-container *ngIf="sh.userId!='Del'">
                                                                                <div class="col-6">
                                                                                    <select
                                                                                        class=" disable form-control col-md-12 col-xs-12"
                                                                                        style="height: 38px;"
                                                                                        data-style="btn btn-drpdwn btn-round"
                                                                                        id="SelSalaryHeadId{{i+1}}"
                                                                                        name="SelSalaryHeadId">
                                                                                        <option
                                                                                            value="{{sh.selSalaryHeadId}}">
                                                                                            {{sh.selSalaryHeadName}}
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                                <div class="col-5 px-2">
                                                                                    <select
                                                                                        class=" disable form-control col-md-12 col-xs-12"
                                                                                        style="height: 38px;"
                                                                                        data-style="btn btn-drpdwn btn-round"
                                                                                        id="SelSalaryDetailId{{i+1}}"
                                                                                        name="SelSalaryDetailId">
                                                                                        <option
                                                                                            value="{{sh.selSalaryDetailId}}">
                                                                                            {{sh.selSalaryDetailName}}
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                                <div
                                                                                    class="col-1 d-flex align-items-end">
                                                                                    <button type="button"
                                                                                        (click)="deleteRulesComp(i)"
                                                                                        class="btn btn_fit mb-2"><span><svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="16" height="16"
                                                                                                fill="red"
                                                                                                class="bi bi-trash"
                                                                                                viewBox="0 0 16 16">
                                                                                                <path
                                                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                                                <path
                                                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </button>
                                                                                </div>
                                                                            </ng-container>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="clearfix"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- // rules  -->

                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed  "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseThree" aria-expanded="false">Employee
                                                                Contribution</a>
                                                        </h4>
                                                    </div>

                                                    <div id="collapseThree" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body m-0 p-0">
                                                            <div class="card-body m-0 p-0">
                                                                <div class="row col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                                    <!-- w-100 d-flex -->
                                                                    <div class="showGrid mt-2 ms-2 mx-2">
                                                                        <div class="">
                                                                            <label for="Salary Lower Limit"
                                                                                class="label_css form-label">Salary
                                                                                Lower Limit</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Salary Upper Limit"
                                                                                class="label_css form-label">Salary
                                                                                Upper Limit</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Percentage / Amount"
                                                                                class="label_css form-label">Percentage
                                                                                / Amount</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Calculated Lower Limit"
                                                                                class="label_css form-label">Calculated
                                                                                Lower Limit</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Calculated Upper Limit"
                                                                                class="label_css form-label">Calculated
                                                                                Upper Limit</label>
                                                                        </div>
                                                                        <div class="d-flex align-items-end">
                                                                            <div class="col-md-12 col-xs-12">&nbsp;
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <form #employeeContributionForm="ngForm"
                                                                        class="m-0 p-0">
                                                                        <section class="showGrid m-2 p-0">
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Salary_Lower_Limit_ied"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Salary Lower Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertEmployeeRuleDetail.salaryLowerLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #salaryLowerLimit_ied="ngModel"
                                                                                    [ngClass]="(!salaryLowerLimit_ied.valid && salaryLowerLimit_ied?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Salary_Upper_Limit_ied"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Salary Upper Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertEmployeeRuleDetail.salaryUpperLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #salaryUpperLimit_ied="ngModel"
                                                                                    [ngClass]="(!salaryUpperLimit_ied.valid && salaryUpperLimit_ied?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Percentage_Amount_ied"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Percentage / Amount"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertEmployeeRuleDetail.percentAmount"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #percentAmount_ied="ngModel"
                                                                                    [ngClass]="(!percentAmount_ied.valid && percentAmount_ied?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Calculated_Lower_Limit_ied"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Calculated Lower Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertEmployeeRuleDetail.calcLowerLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #calcLowerLimit_ied="ngModel"
                                                                                    [ngClass]="(!calcLowerLimit_ied.valid && calcLowerLimit_ied?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Calculated_Upper_Limit_ied"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Calculated Upper Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertEmployeeRuleDetail.calcUpperLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #calcUpperLimit_ied="ngModel"
                                                                                    [ngClass]="(!calcUpperLimit_ied.valid && calcUpperLimit_ied?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="d-flex align-items-end">
                                                                                <button type="button"
                                                                                    (click)="addEmployeeRule()"
                                                                                    class="btn btn_fit mb-2"><span><svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16" height="16"
                                                                                            fill="currentColor"
                                                                                            class="bi bi-plus-lg"
                                                                                            viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd"
                                                                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </section>
                                                                    </form>

                                                                    <ng-container
                                                                        *ngFor="let ed of employeeRuleDetail;let i = index;">
                                                                        <ng-container *ngIf="ed.userId!='Del'">
                                                                            <div class="showGrid m-0 p-0">
                                                                                <div class="ms-2 disable w-100">
                                                                                    <input type="text"
                                                                                        name="Salary_Lower_Limit_erd{{i}}"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter Salary Lower Limit"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="ed.salaryLowerLimit" />
                                                                                </div>
                                                                                <div class="ms-2 disable w-100">
                                                                                    <input type="text"
                                                                                        name="Salary_Upper_Limit_erd{{i}}"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter Salary Upper Limit"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="ed.salaryUpperLimit"
                                                                                        required />
                                                                                </div>
                                                                                <div class="ms-2 disable w-100">
                                                                                    <input type="text"
                                                                                        name="Percentage_Amount_erd{{i}}"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter Percentage / Amount"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="ed.percentAmount"
                                                                                        required />
                                                                                </div>
                                                                                <div class="ms-2 disable w-100">
                                                                                    <input type="text"
                                                                                        name="Calculated_Lower_Limit_erd{{i}}"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter Calculated Lower Limit"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="ed.calcLowerLimit"
                                                                                        required />
                                                                                </div>
                                                                                <div class="ms-2 disable w-100">
                                                                                    <input type="text"
                                                                                        name="Calculated_Upper_Limit_erd{{i}}"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter Calculated Upper Limit"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="ed.calcUpperLimit"
                                                                                        required />
                                                                                </div>
                                                                                <div
                                                                                    class="ms-2 d-flex align-items-end">
                                                                                    <button type="button"
                                                                                        (click)="deleteEmployeeRule(i)"
                                                                                        class="btn btn_fit mb-2"><span><svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="16" height="16"
                                                                                                fill="red"
                                                                                                class="bi bi-trash"
                                                                                                viewBox="0 0 16 16">
                                                                                                <path
                                                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                                                <path
                                                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>

                                                                <div class="clearfix"> </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed  "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseFour" aria-expanded="false">Employer
                                                                Contribution</a>
                                                        </h4>
                                                    </div>

                                                    <div id="collapseFour" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body m-0 p-0">
                                                            <div class="card-body m-0 p-0">
                                                                <div class="row col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                                    <div class="showGrid mt-2 ms-2 mx-2">
                                                                        <div class="">
                                                                            <label for="Salary Lower Limit"
                                                                                class="label_css form-label">Salary
                                                                                Lower Limit</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Salary Upper Limit"
                                                                                class="label_css form-label">Salary
                                                                                Upper Limit</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Percentage / Amount"
                                                                                class="label_css form-label">Percentage
                                                                                / Amount</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Calculated Lower Limit"
                                                                                class="label_css form-label">Calculated
                                                                                Lower Limit</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Calculated Upper Limit"
                                                                                class="label_css form-label">Calculated
                                                                                Upper Limit</label>
                                                                        </div>
                                                                        <div class="">
                                                                        </div>
                                                                    </div>

                                                                    <form #employeerContributionForm="ngForm"
                                                                        class="m-0 p-0">
                                                                        <section class="showGrid m-2 p-0">
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Salary_Lower_Limit_ier"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Salary Lower Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertEmployeerRuleDetail.salaryLowerLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #salaryLowerLimit_ier="ngModel"
                                                                                    [ngClass]="(!salaryLowerLimit_ier.valid && salaryLowerLimit_ier?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Salary_Upper_Limit_ier"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Salary Upper Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertEmployeerRuleDetail.salaryUpperLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #salaryUpperLimit_ier="ngModel"
                                                                                    [ngClass]="(!salaryUpperLimit_ier.valid && salaryUpperLimit_ier?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Percentage_Amount_ier"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Percentage / Amount"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertEmployeerRuleDetail.percentAmount"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #percentAmount_ier="ngModel"
                                                                                    [ngClass]="(!percentAmount_ier.valid && percentAmount_ier?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Calculated_Lower_Limit_ier"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Calculated Lower Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertEmployeerRuleDetail.calcLowerLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #calcLowerLimit_ier="ngModel"
                                                                                    [ngClass]="(!calcLowerLimit_ier.valid && calcLowerLimit_ier?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Calculated_Upper_Limit_ier"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Calculated Upper Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertEmployeerRuleDetail.calcUpperLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #calcUpperLimit_ier="ngModel"
                                                                                    [ngClass]="(!calcUpperLimit_ier.valid && calcUpperLimit_r?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class=" d-flex align-items-end">
                                                                                <button type="button"
                                                                                    (click)="addEmployerRule()"
                                                                                    class="btn btn_fit mb-2"><span><svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16" height="16"
                                                                                            fill="currentColor"
                                                                                            class="bi bi-plus-lg"
                                                                                            viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd"
                                                                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </section>
                                                                    </form>

                                                                    <div class="showGrid m-0 p-0"
                                                                        *ngFor="let ed of employerRuleDetail;let i = index;">
                                                                        <ng-container *ngIf="ed.userId!='Del'">
                                                                            <div class="ms-2 disable w-100">
                                                                                <input type="text"
                                                                                    name="Salary_Lower_Limit_ecr{{i}}"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Salary Lower Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="ed.salaryLowerLimit" />
                                                                            </div>
                                                                            <div class="ms-2 disable w-100">
                                                                                <input type="text"
                                                                                    name="Salary_Upper_Limit_ecr{{i}}"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Salary Upper Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="ed.salaryUpperLimit"
                                                                                    required />
                                                                            </div>
                                                                            <div class="ms-2 disable w-100">
                                                                                <input type="text"
                                                                                    name="Percentage_Amount_ecr{{i}}"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Percentage / Amount"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="ed.percentAmount"
                                                                                    required />
                                                                            </div>
                                                                            <div class="ms-2 disable w-100">
                                                                                <input type="text"
                                                                                    name="Calculated_Lower_Limit_ecr{{i}}"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Calculated Lower Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="ed.calcLowerLimit"
                                                                                    required />
                                                                            </div>
                                                                            <div class="ms-2 disable w-100">
                                                                                <input type="text"
                                                                                    name="Calculated_Upper_Limit_ecr{{i}}"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Calculated Upper Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="ed.calcUpperLimit"
                                                                                    required />
                                                                            </div>
                                                                            <div class="ms-2 d-flex align-items-end">
                                                                                <button type="button"
                                                                                    (click)="deleteEmployerRule(i)"
                                                                                    class="btn btn_fit mb-2"><span><svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16" height="16"
                                                                                            fill="red"
                                                                                            class="bi bi-trash"
                                                                                            viewBox="0 0 16 16">
                                                                                            <path
                                                                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                                            <path
                                                                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                                <div class="clearfix"> </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed  "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseFive"
                                                                aria-expanded="false">Administrative
                                                                Charges</a>
                                                        </h4>
                                                    </div>

                                                    <div id="collapseFive" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body m-0 p-0">
                                                            <div class="card-body m-0 p-0">
                                                                <div class="row col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                                    <div class="showGrid mt-2 ms-2 mx-2">
                                                                        <!-- col-2  -->
                                                                        <div class="">
                                                                            <label for="Salary Lower Limit"
                                                                                class="label_css form-label">Salary
                                                                                Lower Limit</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Salary Upper Limit"
                                                                                class="label_css form-label">Salary
                                                                                Upper Limit</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Percentage / Amount"
                                                                                class="label_css form-label">Percentage
                                                                                / Amount</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Calculated Lower Limit"
                                                                                class="label_css form-label">Calculated
                                                                                Lower Limit</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Calculated Upper Limit"
                                                                                class="label_css form-label">Calculated
                                                                                Upper Limit</label>
                                                                        </div>
                                                                        <div class="">
                                                                        </div>
                                                                    </div>

                                                                    <form #administrativeChargeForm="ngForm"
                                                                        class="m-0 p-0">
                                                                        <section class="showGrid m-2 p-0 ">
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Salary_Lower_Limit_iac"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Salary Lower Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertAdministrativeCharge.salaryLowerLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #salaryLowerLimit_iac="ngModel"
                                                                                    [ngClass]="(!salaryLowerLimit_iac.valid && salaryLowerLimit_iac?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Salary_Upper_Limit_iac"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Salary Upper Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertAdministrativeCharge.salaryUpperLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #salaryUpperLimit_iac="ngModel"
                                                                                    [ngClass]="(!salaryUpperLimit_iac.valid && salaryUpperLimit_iac?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Percentage_Amount_iac"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Percentage / Amount"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertAdministrativeCharge.percentAmount"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #percentAmount_iac="ngModel"
                                                                                    [ngClass]="(!percentAmount_iac.valid && percentAmount_iac?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="calcLowerLimit_iac"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Calculated Lower Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertAdministrativeCharge.calcLowerLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #calcLowerLimit_iac="ngModel"
                                                                                    [ngClass]="(!calcLowerLimit_iac.valid && calcLowerLimit_iac?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="text"
                                                                                    name="Calculated_Upper_Limit_iac"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Calculated Upper Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertAdministrativeCharge.calcUpperLimit"
                                                                                    required pattern="[0-9]*\.?[0-9]*"
                                                                                    (keydown)="preventNegative($event)"
                                                                                    #calcUpperLimit_iac="ngModel"
                                                                                    [ngClass]="(!calcUpperLimit_iac.valid && calcUpperLimit_iac?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="d-flex align-items-end">
                                                                                <button type="button"
                                                                                    (click)="addAdministrativeRule()"
                                                                                    class="btn btn_fit mb-2"><span><svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16" height="16"
                                                                                            fill="currentColor"
                                                                                            class="bi bi-plus-lg"
                                                                                            viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd"
                                                                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </section>
                                                                    </form>

                                                                    <div class="showGrid m-0 p-0"
                                                                        *ngFor="let ed of administrativeCharge;let i = index;">
                                                                        <ng-container *ngIf="ed.userId!='Del'">
                                                                            <div class="ms-2 disable w-100">
                                                                                <input type="text"
                                                                                    name="Salary_Lower_Limit_acc{{i}}"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Salary Lower Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="ed.salaryLowerLimit" />
                                                                            </div>
                                                                            <div class="ms-2 disable w-100">
                                                                                <input type="text"
                                                                                    name="Salary_Upper_Limit_acc{{i}}"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Salary Upper Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="ed.salaryUpperLimit"
                                                                                    required />
                                                                            </div>
                                                                            <div class="ms-2 disable w-100">
                                                                                <input type="text"
                                                                                    name="Percentage_Amount_acc{{i}}"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Percentage / Amount"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="ed.percentAmount"
                                                                                    required />
                                                                            </div>
                                                                            <div class="ms-2 disable w-100">
                                                                                <input type="text"
                                                                                    name="Calculated_Lower_Limit_acc{{i}}"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Calculated Lower Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="ed.calcLowerLimit"
                                                                                    required />
                                                                            </div>
                                                                            <div class="ms-2 disable w-100">
                                                                                <input type="text"
                                                                                    name="Calculated_Upper_Limit_acc{{i}}"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Calculated Upper Limit"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="ed.calcUpperLimit"
                                                                                    required />
                                                                            </div>
                                                                            <div class="ms-2 d-flex align-items-end">
                                                                                <button type="button"
                                                                                    (click)="deleteAdministrativeRule(i)"
                                                                                    class="btn btn_fit mb-2"><span><svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16" height="16"
                                                                                            fill="red"
                                                                                            class="bi bi-trash"
                                                                                            viewBox="0 0 16 16">
                                                                                            <path
                                                                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                                            <path
                                                                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                                <div class="clearfix"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="addOrUpdate && add_view_toggle === 'addPayCalculateRule'"
                                class="actionBar pe-4 ps-4">
                                <div class="clearfix"> </div>
                                <button *ngIf="addOrUpdate && add_view_toggle === 'addPayCalculateRule'"
                                    class="btn btn-danger me-2" type="button" (click)="clear()">Clear</button>
                                <button *ngIf="addOrUpdate && add_view_toggle === 'addPayCalculateRule'"
                                    class="btn btn-success" type="submit" (click)="submit()">Save</button>
                            </div>
                            <div class="card col-md-12 col-sm-12 col-xs-12"
                                *ngIf="add_view_toggle == 'viewPayCalculateRule' ">
                                <div class="card-header border-bottom-0">
                                    <h3 class="card-title">List Of Pay Calculate Rules</h3>
                                </div>
                                <div class="card-body card1S p-0">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                            id="hr-table">
                                            <thead class="tablerow">
                                                <tr class="rowcolors stickyatTop">
                                                    <th class="border-bottom-0 w-5 fs-6 ">Sl.No</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Rule Name</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Salary Head</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Detail Name</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody style="max-height: 50vh; overflow: scroll;">
                                                <tr *ngFor="let rs of salaryMasterWithrules;let i = index">
                                                    <ng-container *ngIf="rs.ruleId!=0">
                                                        <td>{{i+1}}</td>
                                                        <td>{{rs.ruleName}}</td>
                                                        <td>{{rs.salaryHeadName}}</td>
                                                        <td>{{rs.salaryDetailName}}</td>
                                                        <td>
                                                            <button class="btn btn-primary btn-icon btn-sm me-2"
                                                                data-method="edit" title="Edit"
                                                                (click)="editPayCalculateRule(rs)">
                                                                <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                                    data-original-title="edit"></i>
                                                            </button>
                                                            <button class="btn btn-danger btn-icon btn-sm me-2"
                                                                data-method="delete" title="Delete"
                                                                data-bs-target="#showAlertWarning"
                                                                (click)="deletePayCalculateRule(rs.ruleId)">
                                                                <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                                    data-original-title="edit"></i>
                                                            </button>
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>