<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">
    
        </div>
                <!--Page header-->
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Payslip</div>
            </div>
        </div> -->
        <!--End Page header-->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body p-2">
                        <form class="form-horizontal form-label-left" >

                            <div class="x_content row p-4">
                                <div class="col-md-4 col-sm-6 col-xs-6 mb-0">
                                    <div class="form-group label-floating">
                                        <label class="w-100 fontSize-15 form-label" for="date"> Month </label>
                                        <div>
                                            <input class="form-control fa-hand-pointer mb-0" [value]="date" name="date" type="month"
                                                id="date" required="required" (change)="changeDate($event)"  />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6 mt-0">
                                    <div class="form-group label-floating mt-0">
                                        <label class="form-label w-100 fontSize-15 d-none d-md-block mt-0"> &nbsp; </label>
                                        <div>
                                            <button type="submit" class="btn btn-primary mt-0" (click)="generatePayslip()" >
                                                Submit
                                             </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal form-label-left" >

                            <div class="x_content row p-4">
                                <div class="col-md-4 col-sm-6 col-xs-6">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> Month </label>
                                        <div>
                                            <input class="form-control fa-hand-pointer" style="cursor: pointer;" [value]="date" name="date" type="month"
                                                id="date" required="required" (change)="changeDate($event)"  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6">
                                <div class="form-group label-floating">
                                    <label class="form-label"> &nbsp; </label>
                                    <div>
                                        <button type="submit" class="btn btn-primary" (click)="generatePayslip()" >
                                            Generate
                                         </button>
                                    </div>
                                    
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </div> -->


        <!-- <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal" id="fcForm" >
                            <section> 
                                <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                  <div class="form-group label-floating">
                                    <label class="form-label"> Month </label>
                                    <div>
                                        <input class="form-control col-md-12 col-xs-12" [value]="date" name="date" type="month"
                                          id="date" required="required" (change)="changeDate($event)"  />
                    
                                      </div>
                                  </div>
                                </div>
                                
                            </section>
                            <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                <div class="form-group label-floating">
                                  <label class="form-label">&nbsp;&nbsp;</label> 
                                  <div>
                                    <button type="submit" class="btn btn-primary w-100 col-md-3" (click)="generatePayslip()" >
                                       Generate
                                    </button>
                                  </div>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>
              
            </div>
        </div> -->
                <!-- Row -->
        <!-- <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">My Payslips Summary</h4>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table  table-vcenter text-nowrap table-bordered border-bottom" id="emp-attendance">
                                <thead>
                                    <tr>
                                        <th class="border-bottom-0 text-center">Employee ID</th>
                                        <th class="border-bottom-0">Month</th>
                                        <th class="border-bottom-0">Year</th>
                                        <th class="border-bottom-0">Net Salary</th>
                                        <th class="border-bottom-0">Generated Date</th>
                                        <th class="border-bottom-0">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center">10029</td>
                                        <td>January</td>
                                        <td>2021</td>
                                        <td class="font-weight-semibold">32,000</td>
                                        <td>01-02-2021</td>
                                        <td>
                                            <a class="btn btn-success btn-icon btn-sm" data-bs-toggle="tooltip" data-original-title="Download"><i class="feather feather-download"></i></a>
                                         </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Row-->

    </div><!-- end app-content-->
</div>

