import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonService } from './common.service';
import { share } from 'rxjs/operators';
import { EmployeelistingComponent } from '../employeelisting/employeelisting.component';
import { branches } from '../models/branches';

@Injectable({
  providedIn: 'root'
})
export class EmployeeMasterservice {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private httpclient: HttpClient, private commonService: CommonService, private common: CommonService) { }

  // public getSalaryDetails(empid :any):Observable<any>{
  //   this.url = '/v1/employeeSalaryData';
  //     const path = this.basePath + this.url;
  //     let queryParameters = new HttpParams();
  //     console.log(path)
  //     return this.httpclient.get(path,empid);
  //     // return this.commonService.getByQueryParamWithHttpInfo(empid,queryParameters, path );
  // } 

  public resetPasswordBulk(): Observable<any> {
    this.url = '/v1/resetPasswordbulk';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("reset password bulk endpoint called")
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getdesignations(): Observable<any> {
    this.url = '/v1/getdesignations';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.common.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public ResetPasswordList(empPasswordList: EmployeelistingComponent[]): Observable<any> {
    console.log("Selected Item", empPasswordList);
    this.url = '/v1/ResetPassword';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(empPasswordList, queryParameters, path);
  }

  public getSelectOptions(uid: any): Observable<any> {
    this.url = '/v1/selectOptions';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uid', uid);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getDepartment(): Observable<any> {
    this.url = '/v1/selectDepartment';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getDocType(): Observable<any> {
    this.url = '/v1/selectDocType';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public updateemployeData(empInfo, file: File) {
    this.url = '/v1/updateEmployeeMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(empInfo, queryParameters, path);
  }

  public saveEmployeeMaster(empInfo: any): Observable<any> {

    console.log("save :", empInfo)
    this.url = '/v1/saveEmployeeMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();

    return this.commonService.postWithHttpInfo(empInfo, queryParameters, path);
  }

  public uploadImage(file: File, empid) {
    this.url = '/v1/uploadProfilePhoto';
    const path = this.basePath + this.url;

    let formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('empid', empid)
    const headers = new HttpHeaders()
    headers.set('content-type', 'multipart/form-data')
    // let headers = new HttpHeaders();
    // headers.append('Content-Type', 'multipart/form-data');
    const httpOptions = { 'headers': headers };
    return this.httpclient.post(path, formData, httpOptions)
  }

  getEmployeesformTReport(employeeName: any, fromDate: any, toDate: any, limitPara: any, offsetPara: any, branch: number): Observable<any> {
    this.url = '/v1/employee-formT-Report';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (employeeName !== null) {
      queryParameters = queryParameters.set('employeeName', employeeName);
    }
    queryParameters = queryParameters.set('limitPara', limitPara);
    queryParameters = queryParameters.set('offsetPara', offsetPara);
    queryParameters = queryParameters.set('fromDate', fromDate);
    queryParameters = queryParameters.set('toDate', toDate);
    queryParameters = queryParameters.set('toDate', toDate);
    queryParameters = queryParameters.set('branch', branch);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getEmployeeMaster(employeeid: any, employeeName: any, limitPara: any, offsetPara: any): Observable<any> {
    this.url = '/v1/employeeMasterData';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (employeeid !== null) {
      queryParameters = queryParameters.set('employeeId', employeeid);
    }
    if (employeeName !== null) {
      queryParameters = queryParameters.set('employeeName', employeeName);
    }
    queryParameters = queryParameters.set('limitPara', limitPara);
    queryParameters = queryParameters.set('offsetPara', offsetPara);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);

  }

  public getEmployeeDesgignation(employeeid: any, employeeName: any, limitPara: any, offsetPara: any): Observable<any> {
    this.url = '/v1/getEmployeeDesgignation';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (employeeid !== null) {
      queryParameters = queryParameters.set('employeeId', employeeid);
    }
    if (employeeName !== null) {
      queryParameters = queryParameters.set('employeeName', employeeName);
    }
    queryParameters = queryParameters.set('limitPara', limitPara);
    queryParameters = queryParameters.set('offsetPara', offsetPara);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);

  }


  public getsalarydetailnameList(): Observable<any> {
    this.url = '/v1/getSalaryDetailNameList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getdeductsalarydetailnameList(): Observable<any> {
    this.url = '/v1/getdeductsalarydetailnameList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getEmployeeLeaveType(uid: any): Observable<any> {
    this.url = '/v1/getIndividualEmpLeaveType';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set("uid", uid);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getLeaveType(): Observable<any> {
    // this is existing api used to get leavetype .. dont modify db
    this.url = '/v1/getLeaveType';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getAttAuth(): Observable<any> {
    // this is existing api used to get leavetype .. dont modify db
    this.url = '/v1/getAttendaceAuthority';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getDocAuth(): Observable<any> {
    // this is existing api used to get leavetype .. dont modify db
    this.url = '/v1/getDocumentAuthority';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }



  public getJobType(): Observable<any> {
    // this is existing api used to get leavetype .. dont modify db
    this.url = '/v1/getJobType';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getBranchList(): Observable<any> {
    this.url = '/v1/getBranchList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }
  public getgroupList(): Observable<any> {
    this.url = '/v1/getGroupList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }
  public getLeaveList(): Observable<any> {
    this.url = '/v1/getLeaveList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public saveEmployeeDesignation(saveEmployeeDesignation: any): Observable<any> {
    console.log("value sent ", saveEmployeeDesignation)
    this.url = '/v1/saveEmployeeDesignation';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(saveEmployeeDesignation, queryParameters, path);
  }




  public getShiftName(): Observable<any> {
    // this is existing api used to get shiftMaster .. dont modify db
    this.url = '/v1/getshiftMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getLeaveAssignForEmployeeList(grouptype: any): Observable<any> {
    // this is get method to get list of employee based on group only db 
    console.log("group type is  ", grouptype)
    this.url = '/v1/getLeaveAssignForEmployeeList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('grouptype', grouptype);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getSearchEmployeeMasterDoccument(employeeid: any, employeeName: any, departmentDropDown: any, statusDropDown: any, documentDropDown: any, limitPara: any, offsetPara: any): Observable<any> {
    this.url = '/v1/getSearchEmployeeMasterDoccument';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (employeeid !== null) {
      queryParameters = queryParameters.set('employeeId', employeeid);
    }
    if (employeeName !== null) {
      queryParameters = queryParameters.set('employeeName', employeeName);
    }
    queryParameters = queryParameters.set('departmentDropDown', departmentDropDown);
    queryParameters = queryParameters.set('statusDropDown', statusDropDown);
    queryParameters = queryParameters.set('documentDropDown', documentDropDown);
    queryParameters = queryParameters.set('limitPara', limitPara);
    queryParameters = queryParameters.set('offsetPara', offsetPara);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getEmployeeMasterDoccument(employeeid: any, employeeName: any, departmentDropDown: any, statusDropDown: any, documentDropDown: any, limitPara: any, offsetPara: any): Observable<any> {
    this.url = '/v1/employeeDocData';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (employeeid !== null) {
      queryParameters = queryParameters.set('employeeId', employeeid);
    }
    if (employeeName !== null) {
      queryParameters = queryParameters.set('employeeName', employeeName);
    }
    queryParameters = queryParameters.set('departmentDropDown', departmentDropDown);
    queryParameters = queryParameters.set('statusDropDown', statusDropDown);
    queryParameters = queryParameters.set('documentDropDown', documentDropDown);
    queryParameters = queryParameters.set('limitPara', limitPara);
    queryParameters = queryParameters.set('offsetPara', offsetPara);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getempSecondary_department(id: number): Observable<any> {
    this.url = '/v1/employee-sec-department';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', id);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public uploadEmpExcelDocument(excelDocToUpload: any): Observable<any> {
    this.url = '/v1/employeeDataUpload';
    const path = this.basePath + this.url;
    var formData: any = new FormData();

    //const formData: FormData = new FormData();
    console.log("File data from service file", excelDocToUpload)
    console.log("URL to send file", path)
    formData.append('file', excelDocToUpload);

    // let queryParameters = new HttpParams();

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    //headers.append( 'Accept', 'multipart/form-data' );

    const httpOptions = { headers: headers };
    // return this.commonService.postWithHttpInfo(formData, queryParameters,path)
    return this.httpclient.post(path, formData, httpOptions);

  }

  public downloadEmployeesCSVFile(): Observable<any> {
    this.url = '/v1/downloadEmployeeExcel';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // if (employeeid !== null) {
    //     queryParameters = queryParameters.set('employeeId', employeeid);
    // }
    // if (employeeName !== null) {
    //     queryParameters = queryParameters.set('employeeName', employeeName);
    // }
    // queryParameters = queryParameters.set('limitPara', limitPara);
    // queryParameters = queryParameters.set('offsetPara', offsetPara);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }

  public update_EmpLeaveMaster(LeaveTypeListValue: any, uid: any): Observable<any> {
    console.log("value sent 'v1/update_EmployeeLeaveType' ", LeaveTypeListValue, " uid :", uid);
    this.url = '/v1/update_EmployeeLeaveType';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('UID', uid);
    return this.commonService.postWithHttpInfo(LeaveTypeListValue, queryParameters, path);
  }

  public update_insert_LeaveMasterBulk(LeaveTypeListValue: any, empSelectedListBulk: any): Observable<any> {
    console.log("value sent to db ", LeaveTypeListValue, " ", empSelectedListBulk)
    this.url = '/v1/update_insert_EmployeeLeaveType_bulk';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo({ leaveTypeListValue: LeaveTypeListValue, empSelectedListBulk: empSelectedListBulk },
      queryParameters, path)
    // return this.commonService.postWithHttpInfo(LeaveTypeListValue, empSelectedListBulk, queryParameters, path);
  }

  public getMandatoryFieldForEM(): Observable<any> {  // dont modify api use in setting screen also ... 
    this.url = '/v1/getMandatoryFieldsEM';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public insert_branch(info: String): Observable<any> {
    console.log("value sent ", info);
    this.url = '/v1/insert_new_branch';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }

  public insert_group(info: String): Observable<any> {
    console.log("value sent ", info);
    this.url = '/v1/insert_new_group';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }

  public insert_annual_holiday(Date: String, annualName: String, creditedDay: String, colorCode: String): Observable<any> {
    console.warn(creditedDay);
    this.url = '/v1/insert_new_annual_holiday';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo({ date: Date, annualName: annualName, creditedDay: creditedDay, colorCode: colorCode }, queryParameters, path);
  }

  public delete_annual_holiday(info: any): Observable<any> {
    //console.log("delete id  : ", info);
    this.url = '/v1/delete_annual_holiday';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', info);
    return this.commonService.postWithHttpInfo({}, queryParameters, path);
  }

  public editUpdate_annual_holiday(annualName: String, annualHolidayId: number, Date: String, creditedDay: String, colorCode: String): Observable<any> {
    console.log("value sent edit update annual holiday is : ", annualHolidayId);
    this.url = '/v1/editUpdate_annual_holiday';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo({ annualName: annualName, annualHolidayId: annualHolidayId, date: Date, creditedDay: creditedDay, colorCode: colorCode }, queryParameters, path);
  }

  public editUpdate_branch(info: any): Observable<any> {
    console.log("value sent edit update branch is : ", info);
    this.url = '/v1/editUpdate_branch';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }
  public editUpdate_group(info: any): Observable<any> {
    console.log("value sent edit update group is : ", info);
    this.url = '/v1/editUpdate_group';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }

  public deleteBranch(info: any): Observable<any> {
    console.log("delete id  : ", info);
    this.url = '/v1/delete_branch';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }

  public deleteGroup(info: any): Observable<any> {
    console.log("delete id  : ", info);
    this.url = '/v1/delete_group';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }

  public deleteLeaveType(info: any): Observable<any> {
    console.log("delete leave type check here   : ", info);
    this.url = '/v1/delete_LeaveType';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }


  public UpdateLeaveType(info: any): Observable<any> {
    console.log("upate leave type ", info, " info ");
    this.url = '/v1/Update_LeaveType';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('info', info);
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }



  public uploadImageFace(empid: any): Observable<any> {
    console.log("Enetred upload method");
    this.url = '/v1/uploadProfilePhoto';
    const path = this.basePath + this.url;
    console.log("Enetred upload method1");
    let queryParameters = new HttpParams();
    if (empid !== null) {
      queryParameters = queryParameters.set('employeeId', empid);
    }
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public savefacePath(info: any, empid: any, image1: any, image2: any, image3: any): Observable<any> {

    //console.log("value sent to db ", faceregisterdata, " ", faceregisterdata)
    this.url = '/v1/saveProfileImage';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('image1', image1);
    queryParameters = queryParameters.set('image2', image2);
    queryParameters = queryParameters.set('image3', image3);
    queryParameters = queryParameters.set('employeeid', empid);
    console.log("Image1 name:----" + image1);
    console.log("Image2 name:----" + image2);
    console.log("Employeee id name:----" + empid);

    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)

  }
  public getEmployeeInfoBasedOnJoiningDate(fromdate: string, todate: string, limit: number,
    offset: number
  ): Observable<any> {
    this.url = '/v1/employeeinfosbasedonjoiningdate';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('fromdate', fromdate);
    queryParameters = queryParameters.set('todate', todate);
    queryParameters = queryParameters.set('limit', limit);
    queryParameters = queryParameters.set('offset', offset);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getEmployeeInfoBasedOnExitDate(fromdate: string, todate: string, limit: number,
    offset: number
  ): Observable<any> {
    this.url = '/v1/employeeinfosbasedonexitdate';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('fromdate', fromdate);
    queryParameters = queryParameters.set('todate', todate);
    queryParameters = queryParameters.set('limit', limit);
    queryParameters = queryParameters.set('offset', offset);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public downloadEmployeeJoineeReport(fromdate: string, todate: string): Observable<any> {
    this.url = '/v1/downloadJoineeReport';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.append("fromdate", fromdate);
    queryParameters = queryParameters.append("todate", todate);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }

  public downloadEmployeeExitReport(fromdate: string, todate: string): Observable<any> {
    this.url = '/v1/downloadExitReport';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.append("fromdate", fromdate);
    queryParameters = queryParameters.append("todate", todate);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }

  public getEditEmployeeDesignation(editId: any): Observable<any> {
    console.log("editEmployeeDesignation", editId);
    this.url = '/v1/editEmployeeDesignation';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('editId', editId);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getupdateEmployeeDesignation(insertdesignationDetails: any): Observable<any> {
    console.log("Update id  : ", insertdesignationDetails);
    this.url = '/v1/updateEmployeeDesignation';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // queryParameters = queryParameters.set('updateId', insertITSlabDetails);
    return this.commonService.postWithHttpInfo(insertdesignationDetails, queryParameters, path);

  }

  public deleteEmployeeDesgnation(deleteId: any): Observable<any> {
    console.log("delete id  : ", deleteId);
    this.url = '/v1/deleteEmployeeDesgnation';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('deleteId', deleteId);
    return this.commonService.postWithHttpInfo(deleteId, queryParameters, path);

  }
}