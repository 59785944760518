<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Settings</div>
            </div>

        </div> -->
        <div class="row">
            <div class="col-md-12 col-xl-3">
                <div class="card">
                    <div class="nav flex-column admisetting-tabs" id="settings-tab" role="tablist"
                        aria-orientation="vertical">
                        <a class="nav-link active" data-bs-toggle="pill" href="#tab-1" role="tab">
                            <i class="nav-icon las fa fa-gears"></i> Login Settings
                        </a>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab-2" role="tab">
                            <i class="nav-icon feather feather-bell"></i> Notification Settings
                        </a>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab-3" role="tab">
                            <i class="nav-icon ion ion-clock"></i> Attendance Settings
                        </a>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab-5" role="tab">
                            <i class="nav-icon fa fa-address-card-o"></i> Employee Master Config
                        </a>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab-8" role="tab">
                            <i class="nav-icon fa fa-group"></i> Attendance Authority
                        </a>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab-6" role="tab">
                            <i class="nav-icon fa fa-group"></i> Expense Authority
                        </a>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab-7" role="tab">
                            <i class="nav-icon fa fa-group"></i> Policy Authority
                        </a>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab-9" role="tab">
                            <i class="nav-icon fa fa-group"></i> Document Authority
                        </a>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab-10" role="tab">
                            <i class="nav-icon fa fa-group"></i> Leave Encashment
                        </a>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab-11" role="tab">
                            <i class="nav-icon fa fa-group"></i> Salary Settings
                        </a>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab-12" role="tab">
                            <i class="nav-icon fa fa-group"></i> Salary Loan Settings
                        </a>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab-13" role="tab">
                            <i class="nav-icon fa fa-group"></i> Salary Advance Settings
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-xl-9">
              
                <div class="tab-content adminsetting-content" id="setting-tabContent">

                    <!-- login setting  -->

                    <div class="tab-pane fade show active" id="tab-1" role="tabpanel">
                        <div class="card">
                            <div class="card-header  border-0">
                                <h4 class="card-title">Login Settings</h4>
                            </div>
                            <div class="card-body ptb">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="custom-controls-stacked d-sm-flex ">
                                                <label class="custom-control custom-radio me-4">
                                                    <input type="radio" class="custom-control-input"
                                                        name="example-radios1" value="userID"
                                                        (change)="getselectedfield($event)" id="userid">
                                                    <span class="custom-control-label">User ID</span>
                                                </label>
                                                <label class="custom-control custom-radio me-4">
                                                    <input type="radio" class="custom-control-input"
                                                        name="example-radios1" value="Employeeid"
                                                        (change)="getselectedfield($event)" id="emailid">
                                                    <span class="custom-control-label">Email id</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" id="userID" *ngIf="isuserid">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label class="form-label mb-0 mt-2">User ID based Email </label>
                                        </div>
                                        <div class="col-md-9">
                                            <input type="email" class="form-control" placeholder="example@gmail.com"
                                                (input)="getemailid_fromclient($event)"
                                                [value]="adminconfig_response.defaultemail || null">
                                            <p id="requiredemail" *ngIf="ispresent" class=" text-danger"
                                                style="font-size: 14px;"> ⓘ Please enter your email </p>
                                            <p id="emailnotvalid " *ngIf="isemailvalid" class="  text-danger">ⓘ Invalid
                                                email address </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="btn btn-success me-2" (click)="savechanges1()">Save Changes</a>
                                <a class="btn btn-danger">Cancel</a>
                            </div>
                        </div>
                    </div>

                    <!-- Notification Settings -->

                    <div class="tab-pane fade" id="tab-2" role="tabpanel">
                        <div class="card">
                            <div class="card-header  border-0">
                                <h4 class="card-title">Notification Settings</h4>
                            </div>
                            <div class="card-body ptb">
                                <div class="form-group">
                                    <div class="row">

                                        <div class="col-md-9">
                                            <div class="custom-controls-stacked d-sm-flex">
                                                <label class="custom-control custom-radio me-4">
                                                    <input [(ngModel)]="notificationSetting" type="radio"
                                                        class="custom-control-input" name="example-radios2"
                                                        value="notification_notice" id="notification_notice">
                                                    <span class="custom-control-label">Notification Only</span>
                                                </label>
                                                <label class="custom-control custom-radio me-4">
                                                    <input [(ngModel)]="notificationSetting" type="radio"
                                                        class="custom-control-input" name="example-radios2"
                                                        value="email_notification_notice"
                                                        id="email_notification_notice">
                                                    <span class="custom-control-label">Both Email & Notification </span>
                                                </label>
                                                <label class="custom-control custom-radio me-4">
                                                    <input [(ngModel)]="notificationSetting" type="radio"
                                                        class="custom-control-input" name="example-radios2" value="none"
                                                        id="none">
                                                    <span class="custom-control-label">None </span>
                                                </label>
                                                <label class="custom-control custom-radio me-4">Work Anniversary
                                                    Notification</label><span>
                                                    <input type="text" class="textfieldstyle" id="worknotification"
                                                        name="worknotification" [(ngModel)]="worknotification"
                                                        Placeholder="Enter Days" /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="btn btn-success me-2" (click)="savechanges2()">Save Changes</a>
                                <a class="btn btn-danger">Cancel</a>
                            </div>
                        </div>
                    </div>

                    <!-- Attendance Settings -->

                    <div class="tab-pane fade" id="tab-3" role="tabpanel">
                        <div class="card">
                            <div class="card-header  border-0">
                                <h4 class="card-title">Attendance Settings</h4>
                            </div>
                            <div class="card-body ptb">
                                <div class="form-group">
                                    <div class="row">
                                        <!-- remove this < [checked]="true" , [checked]="false >-->
                                        <div class="col-md-12">
                                            <div class="custom-controls-stacked d-sm-flex">

                                                <label class="custom-control custom-radio me-4">
                                                    <input [(ngModel)]="swipeSetting" type="radio"
                                                        class="custom-control-input" name="example-radios3"
                                                        value="first_in_lastout" id="first_in_lastout" [checked]="true">
                                                    <span class="custom-control-label">First In Last Out</span>
                                                </label>


                                                <label class="custom-control custom-radio me-4">
                                                    <input [(ngModel)]="swipeSetting" type="radio"
                                                        class="custom-control-input" name="example-radios3"
                                                        value="effective_time" id="effective_time" [checked]="false">
                                                    <span class="custom-control-label">Effective Time</span>
                                                </label>

                                                <label class="custom-control custom-radio me-4">Shift Timing</label><span>
                                                    <input type="number" [min]="2" [max]="3" [step]="1" class="textfieldstyle"
                                                     id="shifttime"  name="shifttime" [(ngModel)]="shifttime" >
                                                    </span>
                                                


                                                <!-- <label class="form-label">Device's Id</label>
                                                <span>
                                                    <input type="text" class="devicetextfieldstyle" id="deviceId"
                                                        name="deviceId" [(ngModel)]="deviceId" />
                                                    <label class="form-label">
                                                        *Multiple Devices Should be Seperated by Comma
                                                    </label>
                                                </span> -->

                                            </div>
                                            <br>
                                        </div>

                                        <div class="col-xl-12 col-md-12 col-lg-12">
                                            <form class="form-horizontal">
                                                <div class="form-group row">
                                                    <label
                                                        class="w-auto max-content fontSize-15 form-label my-auto mb-0 ps-0">Device's
                                                        Id
                                                        <i class="fa fa-info-circle" data-bs-toggle="tooltip-primary"
                                                            title="*Multiple Devices Should be Seperated by Comma"></i>
                                                    </label>
                                                    <div class="col-xs-1 col-md-1 px-0">
                                                        <input type="text" class="form-control " name="deviceId"
                                                            [(ngModel)]="deviceId">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <!-- <div class="col-xl-12 col-md-12 col-lg-12">
                                            <form class="form-horizontal">
                                                <div class="form-group row">
                                                    <label
                                                        class="w-auto max-content fontSize-15 form-label my-auto mb-0 ps-0">
                                                        Leave Authority based on job level
                                                      
                                                    </label>
                                                    <div class="col-xs-4 col-md-4 px-0">
                                                        <select class="form-control col-md-12 col-xs-12"
                                                            style="height: 38px;" data-style="btn btn-drpdwn btn-round"
                                                            id="joblevel" name="joblevel" [(ngModel)]="joblevel">
                                                            <option value="GTE">Greater than equal to Job Level</option>
                                                            <option value="EEE">Same Job Level</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </form>
                                        </div> -->

                                        <div class="col-xl-12 col-md-12 col-lg-12">
                                            <form class="form-horizontal">
                                                <div class="form-group row">
                                                    <label
                                                        class="w-auto max-content fontSize-15 form-label my-auto mb-0 ps-0">Allow
                                                        applying leave for last
                                                    </label>
                                                    <div class="col-xs-1 col-md-1 px-0">
                                                        <input type="text" class="form-control "
                                                            name="previousDayAccess" [(ngModel)]="swipeSettingLeave"
                                                            placeholder="number">
                                                    </div>
                                                    <label
                                                        class="w-auto max-content fontSize-15 form-label my-auto mb-0 ps-2">
                                                        Days
                                                    </label>
                                                </div>
                                            </form>

                                        </div>

                                        <div class="col-xl-12 col-md-12 col-lg-12">
                                            <form class="form-horizontal">
                                                <div class="form-group row">
                                                    <label
                                                        class="w-auto max-content fontSize-15 form-label my-auto mb-0 ps-0">
                                                        Maximun extra time out per month
                                                    </label>
                                                    <div class="col-xs-1 col-md-1 px-0">
                                                        <input type="text" class="form-control "
                                                            name="extraTimeOut" [(ngModel)]="extraTimeOut"
                                                            placeholder="minute">
                                                    </div>
                                                    <label
                                                        class="w-auto max-content fontSize-15 form-label my-auto mb-0 ps-2">
                                                        Minutes
                                                    </label>
                                                </div>
                                            </form>

                                        </div>


                                        <div class=" row col-md-12 col-sm-12 col-xs-12">
                                            <div class=" col-md-6 col-sm-6 col-xs-6">
                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <label class="form-label">Leave Calender Month</label>
                                                </div>
                                                <div>
                                                    <!-- <input class="form-control" type="date" name="calenderDate"
                                                        [disabled]="false" [(ngModel)]="calenderDate"
                                                        #leavedate="ngModel"> -->
                                                    <select class="form-control col-md-12 col-xs-12"
                                                        style="height: 38px;" data-style="btn btn-drpdwn btn-round"
                                                        id="calenderDate" name="calenderDate" [(ngModel)]="calenderDate"
                                                        #leavedate="ngModel">
                                                        <option value="1">Jan</option>
                                                        <option value="2">Feb</option>
                                                        <option value="3">Mar</option>
                                                        <option value="4">Apr</option>
                                                        <option value="5">May</option>
                                                        <option value="6">Jun</option>
                                                        <option value="7">Jul</option>
                                                        <option value="8">Aug</option>
                                                        <option value="9">Sept</option>
                                                        <option value="10">Oct</option>
                                                        <option value="11">Nov</option>
                                                        <option value="12">Dec</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="  col-md-6 col-sm-6 col-xs-6">
                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <label class="form-label">Installation Date</label>
                                                </div>
                                                <div>
                                                    <input class="form-control" type="date" name="installationDate"
                                                        [disabled]="false" [(ngModel)]="installationDate"
                                                        #date="ngModel">

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="btn btn-success me-2" (click)="savechanges3()">Save Changes</a>
                                <a class="btn btn-danger">Cancel</a>
                            </div>
                        </div>
                    </div>

                    <!-- Employee Master Config -->

                    <div class="tab-pane fade" id="tab-5" role="tabpanel">
                        <div class="card">
                            <div class="card-header  border-0">
                                <h4 class="card-title">Employee Master Config</h4>
                            </div>
                            <div class="card-body ptb">

                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-table">
                                    <thead class="tablerow">
                                        <tr class="stickyatTop rowcolors">
                                            <th>Fields </th>
                                            <th>Value </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let fieldObj of mandatoryFields; let i = index">
                                            <td data-th="Fields">{{ fieldObj.configkey }}</td>
                                            <td data-th="Value">
                                                <select class="form-control col-md-12 col-xs-12"
                                                    [(ngModel)]="fieldObj.configvalue"
                                                    (change)="onInputChange($event, i)">
                                                    <option [ngValue]="'1'">Mandatory</option>
                                                    <option [ngValue]="'0'">Optional</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>


                            </div>
                            <div class="card-footer">
                                <a class="btn btn-success me-2" (click)="savechanges5()">Save Changes</a>
                                <a class="btn btn-danger">Cancel</a>
                            </div>
                        </div>
                    </div>

                    <!-- attendance and leave authorithy -->

                    <div class="tab-pane fade" id="tab-8" role="tabpanel">
                        <div class="card">
                            <div class="card-header  border-0">
                                <h4 class="card-title">Attendance - Comp Off - Extra Time Out And OT Authority</h4>
                            </div>
                            <div class="card-body ptb">
                                <!-- approve leave and approve limit  -->
                                <div class=" row col-md-12 col-sm-12 col-xs-12 mb-2">
                                    <div *ngIf="errorMessages?.length > 0" class="text-danger">
                                        <ul>
                                            <li *ngFor="let message of errorMessages">{{
                                                message }}</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Authority
                                                Levels</label>
                                            <div>
                                                <input type="number" class="form-control col-md-12 col-xs-12"
                                                    autocomplete="off" placeholder="Enter Number Of Level"
                                                    [(ngModel)]="attendnaceApproveLevel" min="1" max="3"
                                                    name="attendnaceApproveLevel"
                                                    (ngModelChange)="checkApprovalLevel();" autofocus="true" required
                                                    #LattendnaceApproveLevel="ngModel"
                                                    [ngClass]="(!LattendnaceApproveLevel.valid && LattendnaceApproveLevel.touched) ? 'is-invalid' : ''" />
                                                <div *ngIf="(!LattendnaceApproveLevel.valid && LattendnaceApproveLevel.touched)"
                                                    class="text-danger">
                                                    Please Enter a valid Leave Approval
                                                    Levels
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngFor="let obj of attendanceAuthorities;let i = index">
                                    <!-- select level one two three four five six ... -->
                                    <div class="row col-md-12 col-sm-12 col-xs-12 borderTop"
                                        *ngIf="attendnaceApproveLevel >= 1 && attendnaceApproveLevel <= 3">
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Approval level
                                                    {{attendanceAuthorities.length-i}}</label>
                                                <div class="w-100">
                                                    <!-- (change)="onApprovelLevelDesChange()" -->
                                                    <select class="form-control col-md-12 col-xs-12"
                                                        style="height: 38px;" data-style="btn btn-drpdwn btn-round"
                                                        id="selectedJobLevel{{i}}" name="selectedJobLevel{{i}}"
                                                        [(ngModel)]="obj.selectedJobLevel">
                                                        <option value="GTE">Greater than
                                                            equal to Job Level</option>
                                                        <option value="EEE">Same Job Level
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6"
                                            *ngIf="obj.selectedJobLevel !=undefined">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Job Level<span
                                                        style="color: rgb(248, 111, 111);">
                                                        &nbsp;*</span></label>
                                                <div class="w-100">
                                                    <select class="form-control col-md-12 col-xs-12"
                                                        style="height: 38px;" data-style="btn btn-drpdwn btn-round"
                                                        id="JobRole{{i}}" name="JobRole{{i}}" [(ngModel)]="obj.jobRole"
                                                        (ngModelChange)="attendanceAuthorityValidation()" required>
                                                        <option *ngFor="let des of JobList; let i = index"
                                                            [value]="des.joblevelid">{{
                                                            des.joblevel }}
                                                        </option>
                                                    </select>
                                                    <label class="form-label" id="JobRole{{i}}-label"
                                                        style="color: rgb(248, 111, 111);"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row col-md-12 col-sm-12 col-xs-12">
                                        <div class="col-md-6 col-sm-6 col-xs-6"
                                            *ngIf="attendnaceApproveLevel >= 1  && attendnaceApproveLevel <= 3 &&  (obj.selectedJobLevel !=undefined )">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Authority Belongs
                                                    to</label>
                                                <div class="w-100">
                                                    <select class="form-control col-md-12 col-xs-12"
                                                        style="height: 38px;" data-style="btn btn-drpdwn btn-round"
                                                        id="Department{{i}}" name="Department{{i}}"
                                                        [(ngModel)]="obj.selectedDepartment" required>
                                                        <option value="DEP">Same Department
                                                        </option>
                                                        <option value="OTH">Other Department
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6"
                                            *ngIf="attendnaceApproveLevel >= 1  && attendnaceApproveLevel <= 3 && obj.selectedDepartment === 'OTH'">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Other<span
                                                        style="color: rgb(248, 111, 111);">&nbsp;*</span></label>
                                                <div class="w-100">
                                                    <select class="form-control col-md-12 col-xs-12"
                                                        style="height: 38px;" data-style="btn btn-drpdwn btn-round"
                                                        id="otherDepartment{{i}}" name="otherDepartment{{i}}"
                                                        [(ngModel)]="obj.otherDepartment">
                                                        <option *ngFor="let dep of dpartmentList; let i = index"
                                                            [value]=dep.departmentId>{{
                                                            dep.name
                                                            }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="btn btn-success me-2" (click)="saveLeaveAndAttenadanceAuthority()">Save
                                    Changes</a>
                                <a class="btn btn-danger">Cancel</a>
                            </div>
                        </div>
                    </div>

                    <!-- Document authorithy -->

                    <div class="tab-pane fade" id="tab-9" role="tabpanel">
                        <div class="card">
                            <div class="card-header  border-0">
                                <h4 class="card-title">Document Authority</h4>
                            </div>
                            <div class="card-body ptb">
                                <!-- approve document and approve limit  -->
                                <div class=" row col-md-12 col-sm-12 col-xs-12 mb-2">
                                    <div *ngIf="errorMessages?.length > 0" class="text-danger">
                                        <ul>
                                            <li *ngFor="let message of errorMessages">{{
                                                message }}</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Authority
                                                Levels</label>
                                            <div>
                                                <input type="number" class="form-control col-md-12 col-xs-12"
                                                    autocomplete="off" placeholder="Enter Number Of Level"
                                                    [(ngModel)]="documentApproveLevel" min="1" max="3" name="documentApproveLevel"
                                                    (ngModelChange)="checkDocumentApprovalLevel();" autofocus="true" required
                                                    #DdocumentApproveLevel="ngModel"
                                                    [ngClass]="(!DdocumentApproveLevel.valid && DdocumentApproveLevel.touched) ? 'is-invalid' : ''" />
                                                <div *ngIf="(!DdocumentApproveLevel.valid && DdocumentApproveLevel.touched)"
                                                    class="text-danger">
                                                    Please Enter a valid Leave Approval
                                                    Levels
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngFor="let obj of documentAuthorities;let i = index">
                                    <!-- select level one two three four five six ... -->
                                    <div class="row col-md-12 col-sm-12 col-xs-12 borderTop"
                                        *ngIf="documentApproveLevel >= 1 && documentApproveLevel <= 3">
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Approval level
                                                    {{documentAuthorities.length-i}}</label>
                                                <div class="w-100">
                                                    <!-- (change)="onApprovelLevelDesChange()" -->
                                                    <select class="form-control col-md-12 col-xs-12"
                                                        style="height: 38px;" data-style="btn btn-drpdwn btn-round"
                                                        id="selectedJobLevel{{i}}" name="selectedJobLevel{{i}}"
                                                        [(ngModel)]="obj.selectedJobLevel">
                                                        <option value="GTE">Greater than
                                                            equal to Job Level</option>
                                                        <option value="EEE">Same Job Level
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6"
                                            *ngIf="obj.selectedJobLevel !=undefined">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Job Level<span
                                                        style="color: rgb(248, 111, 111);">
                                                        &nbsp;*</span></label>
                                                <div class="w-100">
                                                    <select class="form-control col-md-12 col-xs-12"
                                                        style="height: 38px;" data-style="btn btn-drpdwn btn-round"
                                                        id="JobRole{{i}}" name="JobRole{{i}}" [(ngModel)]="obj.jobRole"
                                                        (ngModelChange)="documentAuthorityValidation()" required>
                                                        <option *ngFor="let des of JobList; let i = index"
                                                            [value]="des.joblevelid">{{
                                                            des.joblevel }}
                                                        </option>
                                                    </select>
                                                    <label class="form-label" id="JobRole{{i}}-label"
                                                        style="color: rgb(248, 111, 111);"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row col-md-12 col-sm-12 col-xs-12">
                                        <div class="col-md-6 col-sm-6 col-xs-6"
                                            *ngIf="documentApproveLevel >= 1  && documentApproveLevel <= 3 &&  (obj.selectedJobLevel !=undefined )">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Authority Belongs
                                                    to</label>
                                                <div class="w-100">
                                                    <select class="form-control col-md-12 col-xs-12"
                                                        style="height: 38px;" data-style="btn btn-drpdwn btn-round"
                                                        id="Department{{i}}" name="Department{{i}}"
                                                        [(ngModel)]="obj.selectedDepartment" required>
                                                        <option value="DEP">Same Department
                                                        </option>
                                                        <option value="OTH">Other Department
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6"
                                            *ngIf="documentApproveLevel >= 1  && documentApproveLevel <= 3 && obj.selectedDepartment === 'OTH'">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Other<span
                                                        style="color: rgb(248, 111, 111);">&nbsp;*</span></label>
                                                <div class="w-100">
                                                    <select class="form-control col-md-12 col-xs-12"
                                                        style="height: 38px;" data-style="btn btn-drpdwn btn-round"
                                                        id="otherDepartment{{i}}" name="otherDepartment{{i}}"
                                                        [(ngModel)]="obj.otherDepartment">
                                                        <option *ngFor="let dep of dpartmentList; let i = index"
                                                            [value]=dep.departmentId>{{
                                                            dep.name
                                                            }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="btn btn-success me-2" (click)="saveDocumentAuthority()">Save
                                    Changes</a>
                                <a class="btn btn-danger">Cancel</a>
                            </div>
                        </div>
                    </div>


<!-- Leave Encashment -->

<div class="tab-pane fade" id="tab-10" role="tabpanel">
    <div class="card">
        <div class="card-header  border-0">
            <h4 class="card-title">Leave Encashment</h4>
        </div>
        <div class="card-body ptb">
            
            <!-- <div *ngFor="let obj of documentAuthorities;let i = index"> -->
                
                <div class="row col-md-12 col-sm-12 col-xs-12 borderTop"> 
                    <!-- *ngIf="documentApproveLevel >= 1 && documentApproveLevel <= 3"> -->
                    <div class="col-md-6 col-sm-6 col-xs-6">
                        <div class="form-group label-floating">
                            <label class="form-label">Encashment From</label>
                            <div class="w-100">
 
                                <input class="form-control" type="date" name="EncashmentFromDate" autocomplete="off"
                                                autofocus="true" [disabled]="false" [(ngModel)]="EncashmentFromDate"
                                                (ngModelChange)="checkFromAndToDate()" required>
                                        </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6"> 
                        <!-- *ngIf="obj.selectedJobLevel !=undefined"> -->
                        <div class="form-group label-floating">
                            <label class="form-label">Encashment To<span
                                    style="color: rgb(248, 111, 111);">
                                    </span></label>
                            <div class="w-100">
                                <input class="form-control" type="date" name="EncashmentToDate" autocomplete="off"
                                autofocus="true" [disabled]="false" [(ngModel)]="EncashmentToDate"
                                (ngModelChange)="checkFromAndToDate()" required>
                            <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                ⓘ End date should be greater than start date
                            </p>          
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </div>
        <div class="card-footer">
            <a class="btn btn-success me-2" (click)="saveLeaveEncashment()">Save</a>
            <a class="btn btn-danger" (click)="onClear()">Cancel</a>
        </div>
    </div>
</div>

 
                    <!-- Expense Authority -->

                    <div class="tab-pane fade" id="tab-6" role="tabpanel">
                        <div class="card">
                            <div class="card-header  border-0">
                                <h4 class="card-title">Expense Authority</h4>
                            </div>
                            <div class="card-body ptb">
                                <div class=" row col-md-12 col-sm-12 col-xs-12">
                                    <div *ngIf="errorMessages1.length > 0" class="text-danger">
                                        <ul>
                                            <li *ngFor="let message of errorMessages1">{{ message }}</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Expense Approval Levels</label>
                                            <div>
                                                <input type="number" class="form-control col-md-12 col-xs-12"
                                                    autocomplete="off" placeholder="Enter Number Of Level"
                                                    [(ngModel)]="approveLevel" min="1" max="3" name="ApproveLevel"
                                                    (ngModelChange)="checkApprovalLevelDesignationValidation();"
                                                    autofocus="true" required #EApprove_Level="ngModel"
                                                    [ngClass]="(!EApprove_Level.valid && EApprove_Level.touched) ? 'is-invalid' : ''" />
                                                <div *ngIf="(!EApprove_Level.valid && EApprove_Level.touched)"
                                                    class="text-danger">
                                                    Please Enter a valid Expense Approval Levels
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Approval Limit</label>
                                            <div>
                                                <input type="number" class="form-control col-md-12 col-xs-12"
                                                    autocomplete="off" placeholder="Enter Approval Limit"
                                                    [(ngModel)]="approvalLimit" min="1" max="3" name="ApprovalLimit"
                                                    autofocus="true" required #EApprove_Limit="ngModel"
                                                    [ngClass]="(!EApprove_Limit.valid && EApprove_Limit.touched) ? 'is-invalid' : ''" />
                                                <div *ngIf="(!EApprove_Limit.valid && EApprove_Limit.touched)"
                                                    class="text-danger">
                                                    Please Enter a valid Expense Approval Limit
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12"
                                    *ngIf="approveLevel >= 1 && approveLevel <= 3">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Approval level 1</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="level1" name="level1"
                                                    [(ngModel)]="selectedLevel1" (change)="onApprovelLevelDesChange()">
                                                    <option value="D">Designation</option>
                                                    <option value="R">Role</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="selectedLevel1 === 'D'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Designation</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="designation"
                                                    name="designation" [(ngModel)]="designation1"
                                                    (change)="checkDesignationValidation1()">
                                                    <option *ngFor="let des of designation; let i = index"
                                                        [value]=des.joblevel>{{ des.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="selectedLevel1 === 'R'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Role</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="role" name="role"
                                                    [(ngModel)]="role1">
                                                    <option *ngFor="let rol of role; let i = index" [value]=rol.id>{{
                                                        rol.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="approveLevel >= 1  && approveLevel <= 3 &&  (selectedLevel1 === 'D')">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Department Belongs to</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="Department"
                                                    name="Department" [(ngModel)]="selectedDepartment1"
                                                    (change)="onDepartmentChange()">
                                                    <option value="DEP">Same</option>
                                                    <option value="OTH">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="approveLevel >= 1  && approveLevel <= 3 && selectedDepartment1 === 'OTH'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Other</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="department"
                                                    name="department" [(ngModel)]="department1">
                                                    <option *ngFor="let dep of department; let i = index"
                                                        [value]=dep.departmentId>{{ dep.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12"
                                    *ngIf="approveLevel >= 2 && approveLevel <= 3">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Approval level 2</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="level1" name="level1"
                                                    [(ngModel)]="selectedLevel2" (change)="onApprovelLevelDesChange()">
                                                    <option value="D">Designation</option>
                                                    <option value="R">Role</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="selectedLevel2 === 'D'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Designation</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="designation"
                                                    name="designation" [(ngModel)]="designation2"
                                                    (change)="checkDesignationValidation1()" required
                                                    [ngClass]="(!isDesignationValid1 && designation2Touched1) ? 'is-invalid' : ''">
                                                    <option *ngFor="let des of designation; let i = index"
                                                        [value]=des.joblevel>{{des.name}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="!isDesignationValid1 && designation2Touched1"
                                                class="text-danger">
                                                Designation should be lesser than approval level 1.
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="selectedLevel2 === 'R'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Role</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="role" name="role"
                                                    [(ngModel)]="role2">
                                                    <option *ngFor="let rol of role; let i = index" [value]=rol.id>{{
                                                        rol.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="approveLevel >= 2 && approveLevel <= 3 && selectedLevel2 === 'D'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Department Belongs to</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="Department"
                                                    name="Department" [(ngModel)]="selectedDepartment2"
                                                    (change)="onDepartmentChange()">
                                                    <option value="DEP">Same</option>
                                                    <option value="OTH">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="approveLevel >= 2 && approveLevel <= 3 && selectedDepartment2 === 'OTH'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Other</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="department"
                                                    name="department" [(ngModel)]="department2">
                                                    <option *ngFor="let dep of department; let i = index"
                                                        [value]=dep.departmentId>{{ dep.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12"
                                    *ngIf="approveLevel >= 3 && approveLevel <= 3">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Approval level 3</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="level" name="level1"
                                                    [(ngModel)]="selectedLevel3" (change)="onApprovelLevelDesChange()">
                                                    <option value="D">Designation</option>
                                                    <option value="R">Role</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="selectedLevel3 === 'D'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Designation</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="designation"
                                                    name="designation" [(ngModel)]="designation3"
                                                    (change)="checkDesignationValidation2()" required
                                                    [ngClass]="(!isDesignationValid2 && designation2Touched2) ? 'is-invalid' : ''">
                                                    <option *ngFor="let des of designation; let i = index"
                                                        [value]=des.joblevel>{{des.name}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="!isDesignationValid2 && designation2Touched2"
                                                class="text-danger">
                                                Designation should be lesser than Approval level 2.
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="selectedLevel3 === 'R'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Role</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="role" name="role"
                                                    [(ngModel)]="role3">
                                                    <option *ngFor="let rol of role; let i = index" [value]=rol.id>{{
                                                        rol.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="approveLevel >= 3 && approveLevel <= 3 && selectedLevel3 === 'D'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Department Belongs to</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="Department"
                                                    name="Department" [(ngModel)]="selectedDepartment3"
                                                    (change)="onDepartmentChange()">
                                                    <option value="DEP">Same</option>
                                                    <option value="OTH">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="approveLevel >= 3 && approveLevel <= 3 && selectedDepartment3 === 'OTH'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Other</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="department"
                                                    name="department" [(ngModel)]="department3">
                                                    <option *ngFor="let dep of department; let i = index"
                                                        [value]=dep.departmentId>{{ dep.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="btn btn-success me-2" (click)="saveExpenseAuthority()">Save Changes</a>
                                <a class="btn btn-danger">Cancel</a>
                            </div>
                        </div>
                    </div>

                    <!-- Policy Authority -->

                    <div class="tab-pane fade" id="tab-7" role="tabpanel">
                        <div class="card">
                            <div class="card-header  border-0">
                                <h4 class="card-title">Policy Authority</h4>
                            </div>
                            <div class="card-body ptb">
                                <div class=" row col-md-12 col-sm-12 col-xs-12">
                                    <div *ngIf="errorMessages.length > 0" class="text-danger">
                                        <ul>
                                            <li *ngFor="let message of errorMessages">{{ message }}</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Policy Approval Levels</label>
                                            <div>
                                                <input type="number" class="form-control col-md-12 col-xs-12"
                                                    autocomplete="off" placeholder="Enter Number Of Level"
                                                    [(ngModel)]="policyapproveLevel" min="1" max="3" name="ApproveLevel"
                                                    (ngModelChange)="checkPolicyApprovalLevelDesignationValidation();"
                                                    autofocus="true" required #PApprove_Level="ngModel"
                                                    [ngClass]="(!PApprove_Level.valid && PApprove_Level.touched) ? 'is-invalid' : ''" />
                                                <div *ngIf="(!PApprove_Level.valid && PApprove_Level.touched)"
                                                    class="text-danger">
                                                    Please Enter a valid Policy Approval Levels
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Approval limit</label>
                                            <div>
                                                <input type="number" class="form-control col-md-12 col-xs-12"
                                                    autocomplete="off" placeholder="Enter Number Of Approval Limit"
                                                    [(ngModel)]="policyapprovallimit" min="1" max="3"
                                                    name="Approve_Limit" autofocus="true" required
                                                    #PApprove_Limit="ngModel"
                                                    [ngClass]="(!PApprove_Limit.valid && PApprove_Limit.touched) ? 'is-invalid' : ''" />

                                                <div *ngIf="(!PApprove_Limit.valid && PApprove_Limit.touched)"
                                                    class="text-danger">
                                                    Please Enter a valid Policy Approval Limit.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12"
                                    *ngIf="policyapproveLevel >= 1 && policyapproveLevel <= 3">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Approval level 1</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="level1" name="level1"
                                                    [(ngModel)]="policyselectedLevel1"
                                                    (change)="onApprovelLevelDesChange1()">
                                                    <option value="D">Designation</option>
                                                    <option value="R">Role</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="policyselectedLevel1 === 'D'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Designation</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="designation"
                                                    name="designation" [(ngModel)]="policydesignation1"
                                                    (change)="checkPolicyDesignationValidation1()">
                                                    <option *ngFor="let des of designation; let i = index"
                                                        [value]=des.joblevel>{{ des.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="policyselectedLevel1 === 'R'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Role</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="role" name="role"
                                                    [(ngModel)]="policyrole1">
                                                    <option *ngFor="let rol of role; let i = index" [value]=rol.id>{{
                                                        rol.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="policyapproveLevel >= 1  && policyapproveLevel <= 3 &&  (policyselectedLevel1 === 'D')">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Department Belongs to</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="Department"
                                                    name="Department" [(ngModel)]="policyselectedDepartment1"
                                                    (change)="onDepartmentChange1()">
                                                    <option value="DEP">Same</option>
                                                    <option value="OTH">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="policyapproveLevel >= 1  && policyapproveLevel <= 3 && policyselectedDepartment1 === 'OTH'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Other</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="department"
                                                    name="department" [(ngModel)]="policydepartment1">
                                                    <option *ngFor="let dep of department; let i = index"
                                                        [value]=dep.departmentId>{{ dep.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12"
                                    *ngIf="policyapproveLevel >= 2 && policyapproveLevel <= 3">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Approval level 2</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="level1" name="level1"
                                                    [(ngModel)]="policyselectedLevel2"
                                                    (change)="onApprovelLevelDesChange1()">
                                                    <option value="D">Designation</option>
                                                    <option value="R">Role</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="policyselectedLevel2 === 'D'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Designation</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="designation"
                                                    name="designation" [(ngModel)]="policydesignation2"
                                                    (change)="checkPolicyDesignationValidation1()" required
                                                    [ngClass]="(!isPolicyDesignationValid1 && policydesignation2Touched1) ? 'is-invalid' : ''">
                                                    <option *ngFor="let des of designation; let i = index"
                                                        [value]=des.joblevel>{{des.name}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="!isPolicyDesignationValid1 && policydesignation2Touched1"
                                                class="text-danger">
                                                Designation should be lesser than Approval level 1.
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="policyselectedLevel2 === 'R'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Role</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="role" name="role"
                                                    [(ngModel)]="policyrole2">
                                                    <option *ngFor="let rol of role; let i = index" [value]=rol.id>{{
                                                        rol.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="policyapproveLevel >= 2 && policyapproveLevel <= 3 && policyselectedLevel2 === 'D'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Department Belongs to</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="Department"
                                                    name="Department" [(ngModel)]="policyselectedDepartment2"
                                                    (change)="onDepartmentChange1()">
                                                    <option value="DEP">Same</option>
                                                    <option value="OTH">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="policyapproveLevel >= 2 && policyapproveLevel <= 3 && policyselectedDepartment2 === 'OTH'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Other</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="department"
                                                    name="department" [(ngModel)]="policydepartment2">
                                                    <option *ngFor="let dep of department; let i = index"
                                                        [value]=dep.departmentId>{{ dep.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12"
                                    *ngIf="policyapproveLevel >= 3 && policyapproveLevel <= 3">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Approval level 3</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="level" name="level1"
                                                    [(ngModel)]="policyselectedLevel3"
                                                    (change)="onApprovelLevelDesChange1()">
                                                    <option value="D">Designation</option>
                                                    <option value="R">Role</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="policyselectedLevel3 === 'D'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Designation</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="designation"
                                                    name="designation" [(ngModel)]="policydesignation3"
                                                    (change)="checkPolicyDesignationValidation2()" required
                                                    [ngClass]="(!isPolicyDesignationValid2 && policydesignation2Touched2) ? 'is-invalid' : ''">
                                                    <option *ngFor="let des of designation; let i = index"
                                                        [value]=des.joblevel>{{des.name}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="!isPolicyDesignationValid2 && policydesignation2Touched2"
                                                class="text-danger">
                                                Designation should be lesser than Approval level 2.
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="policyselectedLevel3 === 'R'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Role</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="role" name="role"
                                                    [(ngModel)]="policyrole3">
                                                    <option *ngFor="let rol of role; let i = index" [value]=rol.id>{{
                                                        rol.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="policyapproveLevel >= 3 && policyapproveLevel <= 3 && policyselectedLevel3 === 'D'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Department Belongs to</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="Department"
                                                    name="Department" [(ngModel)]="policyselectedDepartment3"
                                                    (change)="onDepartmentChange1()">
                                                    <option value="DEP">Same</option>
                                                    <option value="OTH">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="policyapproveLevel >= 3 && policyapproveLevel <= 3 && policyselectedDepartment3 === 'OTH'">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Other</label>
                                            <div class="w-100">
                                                <select class="form-control col-md-12 col-xs-12" style="height: 38px;"
                                                    data-style="btn btn-drpdwn btn-round" id="department"
                                                    name="department" [(ngModel)]="policydepartment3">
                                                    <option *ngFor="let dep of department; let i = index"
                                                        [value]=dep.departmentId>{{ dep.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-footer">
                                    <a class="btn btn-success me-2" (click)="saveAuthority()">Save Changes</a>
                                    <a class="btn btn-danger">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Salary Settings -->
                    <div class="tab-pane fade" id="tab-11" role="tabpanel">
                        <div class="card">
                            <div class="card-header  border-0">
                                <h4 class="card-title">Salary Settings</h4>
                            </div>
                            <div class="card-body ptb">
                                <div class="form-group">
                                    <div class="row">
                                        <!-- remove this < [checked]="true" , [checked]="false >-->
                                        
                                        <div class="col-xl-12 col-md-12 col-lg-12">
                                            <form class="form-horizontal">
                                                <div class="form-group row">
                                                    <label
                                                        class="w-auto max-content fontSize-15 form-label my-auto mb-0 ps-0">Debit
                                                        Account Number
                                                    </label>
                                                    <div class="col-xs-4 col-md-4 px-0">
                                                        <input type="text" class="form-control "
                                                            name="previousDayAccess" [(ngModel)]="debitAccNum"
                                                            placeholder="number">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="btn btn-success me-2" (click)="savechanges11()">Save Changes</a>
                                <a class="btn btn-danger">Cancel</a>
                            </div>
                        </div>
                    </div>
 
                    <!-- Salary Settings -->
                    <div class="tab-pane fade" id="tab-12" role="tabpanel">
                        <div class="card">
                            <div class="card-header  border-0">
                                <h4 class="card-title">Salary Loan Settings</h4>
                            </div>
                            <div class="card-body ptb">
                                <div class="form-group">
                                    <div class="row">
                                        <!-- remove this < [checked]="true" , [checked]="false >-->
                                        
                                        <div class="col-xl-12 col-md-12 col-lg-12">
                                            <form id="salaryLoanSetform" class="form-horizontal">
                                            <div class="form-group row">
                                                <div class="col-md-4 col-sm-4 col-xs-4">
                                                    <label class="form-label">Interest Rate(%)</label>
                                                    <div class="row">
                                                        <input type="text" class="form-control col-md-8 col-xs-8"  
                                                        [value]=" interestRateForLoan + '%' " 
                                                        (change)="updateInterestRate($event,'L')" (blur)="validateInterestRate()">
                                                            <!-- <span class=" col-md-4 col-xs-4" style="font-size: 25px">%</span> -->
                                                    </div>
                                                </div>
                                                    <!-- <div class="form-group row"> -->
                                                    <div class="col-md-4 col-sm-4 col-xs-4">
                                                        <label class="form-label">Salary Header</label>
                                                        <select class="form-control col-md-12 col-xs-12" [(ngModel)]="salaryHeadIdForloan" id="SalaryHeadLon"
                                                            name="SalaryHeadLon" (change)="headChange(salaryHeadIdForloan,'Loan')" #enteredsalaryHeadLon="ngModel">
                                                            <option value="0">&nbsp; </option>
                                                            <ng-container *ngFor="let hd of salaryMasterLoan">
                                                                <option *ngIf="hd.salaryDetailMaster && hd.salaryDetailMaster.length > 0"
                                                                    value="{{hd.salaryHeadId}}">
                                                                    {{hd.salaryHeadName}}
                                                                </option>
                                                            </ng-container>
                                                        </select>
                                       
                                                    </div>
                                                    <div class="col-md-4 col-sm-4 col-xs-4">
                                                        <label class="form-label">Salary Detail</label>
                                            
                                                        <select class="form-control col-md-12 col-xs-12" [(ngModel)]="salaryDetailIdForloan" id="SalaryDetailForloan"
                                                            name="SalaryDetailForloan" #enteredsalarydetail="ngModel">
                                                            <option value="0">&nbsp; </option>
                                                            <ng-container *ngFor="let sd of detailMasterSection">
                                                                <option value="{{sd.salaryDetailId}}">
                                                                    {{sd.salaryDetailName}}  
                                                                </option>
                                                            </ng-container>
                                                        </select>
                                            
                                                    </div>
                                                </div>
                                            
                                            </form>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- remove this < [checked]="true" , [checked]="false >-->
                                        
                                        <div class="col-xl-12 col-md-12 col-lg-12">
                                           
                                                <div class="form-group row">
                                                    <label
                                                        class="w-auto max-content form-label my-auto mb-0">Maximun Loan Amount
                                                    </label>
                                                    <div class="col-xs-1 col-md-1 px-0">
                                                        <input type="text" class="form-control "
                                                             [(ngModel)]="maxNoOfTimesForLoan"
                                                            placeholder="number"> 
                                                    </div>
                                                    <label
                                                        class="w-auto max-content  form-label my-auto mb-0 ps-2">
                                                        x Gross Salary
                                                    </label>
                                                </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="btn btn-success me-2" (click)="savechangesForLoanSettings()">Save Changes</a>
                                <a class="btn btn-danger">Cancel</a>
                            </div>
                        </div>
                    </div>



                    <div class="tab-pane fade" id="tab-13" role="tabpanel">
                        <div class="card">
                            <div class="card-header  border-0">
                                <h4 class="card-title">Salary Advance Settings</h4>
                            </div>
                            <div class="card-body ptb">
                                <div class="form-group">
                                    <div class="row">
                                        <!-- remove this < [checked]="true" , [checked]="false >-->
                                        <div class="col-xl-12 col-md-12 col-lg-12">
                                            <form id="salaryadvSetform" class="form-horizontal">
                                            <div class="form-group row">
                                                <div class="col-md-4 col-sm-4 col-xs-4">
                                                    <label class="form-label">Interest Rate</label>
                                                    <div class="row">
                                                        <input type="text" class="form-control col-md-8 col-xs-8" min="0" 
                                                        [value]=" interestRateForAdvance + '%' " 
                                                        (change)="updateInterestRate($event,'A')" (blur)="validateInterestRate()">
                                                        <!-- <span class="help-text text-danger"
                                                        *ngIf="(!enteredIntForAdv.valid && enteredIntForAdv.touched) || (!enteredIntForAdv.valid && Salsubmitted)">Please
                                                        Enter Interest Rate</span> -->
                                                    </div>
                                                </div>
                                                    <!-- <div class="form-group row"> -->
                                                    <div class="col-md-4 col-sm-4 col-xs-4">
                                                        <label class="form-label">Salary Header</label>
                                                        <select class="form-control col-md-12 col-xs-12" [(ngModel)]="salaryHeadIdForAdvance" id="SalaryHeadAdv"
                                                            name="SalaryHeadAdv" (change)="headChange(salaryHeadIdForAdvance,'Advance')" #enteredsalHeadAdv="ngModel">
                                                            <option value="0">&nbsp; </option>
                                                            <ng-container *ngFor="let hd of salaryMasterAdv">
                                                                <option *ngIf="hd.salaryDetailMaster && hd.salaryDetailMaster.length > 0"
                                                                    value="{{hd.salaryHeadId}}">
                                                                    {{hd.salaryHeadName}}
                                                                </option>
                                                            </ng-container>
                                                        </select>
                                                       
                                                    </div>
                                                    <div class="col-md-4 col-sm-4 col-xs-4">
                                                        <label class="form-label">Salary Detail</label>
                                            
                                                        <select class="form-control col-md-12 col-xs-12" [(ngModel)]="salaryDetailIdForAdvance" id="SalaryDetailAdv"
                                                            name="SalaryDetailForAdvance" #enteredsaldetailAdv="ngModel">
                                                            <option value="0">&nbsp; </option>
                                                            <ng-container *ngFor="let sd of detailMasterSectionForAdvance">
                                                                <option value="{{sd.salaryDetailId}}">
                                                                    {{sd.salaryDetailName}}  
                                                                </option>
                                                            </ng-container>
                                                        </select>
                                            
                                                    </div>
                                                </div>
                                            
                                            </form>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- remove this < [checked]="true" , [checked]="false >-->
                                        
                                        <div class="col-xl-12 col-md-12 col-lg-12">
                                           
                                                <div class="form-group row">
                                                    <label
                                                        class="w-auto max-content form-label my-auto mb-0">Maximun Advance Amount
                                                    </label>
                                                    <div class="col-xs-1 col-md-1 px-0">
                                                        <input type="text" class="form-control "
                                                             [(ngModel)]="maxNoOfTimesForAdvance"
                                                            placeholder="number">
                                                           
                                                    </div>
                                                    <label
                                                        class="w-auto max-content  form-label my-auto mb-0 ps-2">
                                                        x Gross Salary
                                                    </label>
                                                </div>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="btn btn-success me-2" (click)="savechangesofAdvanceSet()">Save Changes</a>
                                <a class="btn btn-danger">Cancel</a>
                            </div>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>
    </div>
</div>