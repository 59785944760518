<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp" id="alertcomp">

    </div>
    <!--Page header-->
    <!-- <div class="page-header d-xl-flex d-block">
      <div class="page-leftheader">
        <div class="page-title">Approve Salary Advance/Loan </div>
      </div>
    </div> -->

    <!--End Page header-->

    <div *ngIf="!isEdit" class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">

            <form id="fcForm" class="form-horizontal form-label-left">
              <form class="form-horizontal card-body row label-floating px-4" id="fcForm" >
                <div class="col-md-4 col-sm-12 col-xs-12 mb-4">
                  <div class="form-group m-0">
                    <label class="form-label col-xl-12 col-md-12 col-lg-12">Employee Name  </label>
                    <div>
                      <input type="text" class="form-control " name="employeename" id="employeename" [(ngModel)]="searchAdv" autocomplete="off"
                      (input)="chkInput($event)" autofocus="autofocus" placeholder="Search Employee Name">

                    </div>

                  </div>
                </div>

                <div class="col-md-3 col-sm-4 col-xs-4">
                  <div class="form-group">
                    <label class="form-label">Type </label>
                
                    <select id="TypeFilters" class="form-control" [(ngModel)]="typeFilter"
                       name="TypeFilters">
                      <option value="A">Advance</option>
                      <option value="L">Loan</option>
                    </select>
                
                  </div>
                </div>

                <div class="col-md-3 col-sm-4 col-xs-4">
                  <div class="form-group">
                    <label class="form-label">Select Status </label>
                
                    <select id="leaveStatusFilters" class="form-control" [(ngModel)]="statusFilter"
                      (change)="onStatusChange($event.target.value)" name="dateFilter">
                      <option value="PENDING">Pending</option>
                      <option value="APPROVED">Approved</option>
                      <option value="REJECTED">Rejected</option>
                    </select>
                
                  </div>
                </div>

                <div class="col-md-2 col-sm-12 col-xs-12 ">
                  <button type="button" id="navigatetarget" class="btn btn-primary wd w-100 " style="margin-top: 24px;" (click)="search()">
                    <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                  </button>
                </div>
              </form>
            </form>
          </div>
        </div>
      </div>
    </div>


    <!-- Row 2 -->
    <div *ngIf="isEdit" class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <form id="salaryadvanceform" class="form-horizontal form-label-left row" #salaryadvanceform="ngForm">
              <div class="row col-md-12 col-sm-12 col-xs-12">

                <div class="col-md-3 col-sm-4 col-xs-4">
                  <div class="form-group label-floating">
                    <label class="form-label">Amount</label>
                    <div>
                      <input type="text" class="form-control col-md-12 col-xs-12" autocomplete="off"
                        placeholder="Enter amount" name="enteredamount1" [(ngModel)]="totalAmount" required
                        #enteredamount="ngModel"   (change)="onfieldChange()" disabled/> 
                        <!-- [ngClass]="(!enteredamount.valid && enteredamount.touched) || (!enteredamount.valid && submitted) ? 'is-invalid' : ''"--> 
                    </div>
                    <!-- <span class="help-text text-danger"
                      *ngIf="(!enteredamount.valid && enteredamount.touched) || (!enteredamount.valid && submitted)">Please
                      Enter
                      Amount</span> -->
                  </div>
                </div>
                
                <div class="col-md-3 col-sm-4 col-xs-4">
                  <div class="form-group label-floating">
                    <label class="form-label">Number of Installment</label>
                    <div>
                      <input type="text" class="form-control col-md-12 col-xs-12" autocomplete="off"
                        placeholder="Enter Number of Installment" name="enterednoOfInstall"   (change)="onfieldChange()" 
                        [(ngModel)]="noOfInstallment" required #enterednoOfInstall="ngModel"
                        disabled/>
                         <!-- [ngClass]="(!enterednoOfInstall.valid && enterednoOfInstall.touched) || (!enterednoOfInstall.valid && submitted) ? 'is-invalid' : ''" -->

                    </div>
                    <!-- <span class="help-text text-danger"
                      *ngIf="(!enterednoOfInstall.valid && enterednoOfInstall.touched) || (!enterednoOfInstall.valid && submitted)">Please
                      Enter
                      Number of Installment</span> -->
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 col-xs-6">
                  <div class="form-group label-floating">
                    <label class="form-label"> Payment required on </label>
                    <div>
                      <input type="date" class="form-control" name="date_of_loan" [(ngModel)]="date_of_loan" required
                        #dateofloan="ngModel"
                         disabled/>
                    </div>
                    <!-- <span class="help-text text-danger"
                      *ngIf="(!dateofloan.valid && dateofloan.touched) || (!dateofloan.valid && submitted)">Please
                      select valid month</span> -->
                  </div>
                </div>

                <div *ngIf="currentType === 'L'" class="col-md-3 col-sm-6 col-xs-6">
                  <div class="form-group label-floating">
                    <label class="form-label"> Installment Deduction From </label>
                    <div>
                      <input type="date" class="form-control" name="Ins_StartDate" [(ngModel)]="Ins_StartDate" required
                        #InsStartDate="ngModel" disabled/>
                    </div>
                    <!-- <span class="help-text text-danger"
                      *ngIf="(!InsStartDate.valid && InsStartDate.touched) || (!InsStartDate.valid && submitted)">Please
                      select valid month</span>
                      <span class="help-text text-danger"
                      *ngIf=" InsStartDate.valid && dateError">The installment start date must be later than the loan date.</span> -->
                  </div>
                </div>

                <div class="col-md-3 col-sm-4 col-xs-4">
                  <div class="form-group label-floating">
                    <label class="form-label">Reason</label>
                    <div>
                      <input type="text" class="form-control col-md-12 col-xs-12" autocomplete="off"  
                         placeholder="Enter reason" name="reasonForApply" [(ngModel)]="reason"  #reasonForApply="ngModel" required 
                          disabled/>
                    </div>
                    <!-- <span class="help-text text-danger"
                      *ngIf="(!reasonForApply.valid && reasonForApply.touched) || (!reasonForApply.valid && submitted)">Please
                      Enter Reason</span> -->
                  </div>
                </div>

                <!-- <div class="col-md-3 col-sm-4 col-xs-4">
                  <div class="form-group label-floating">
                    <label class="form-label">Salary Header</label>
                    <div>
                      <select class="form-control col-md-12 col-xs-12" [(ngModel)]="salaryHeadId" id="SalaryHead"
                        name="SalaryHead" (change)="headChange(salaryHeadId)" #enteredsalaryHead="ngModel" disabled>
                        <option value="">&nbsp; </option>
                        <ng-container *ngFor="let hd of salaryMaster">
                          <option *ngIf="hd.salaryDetailMaster && hd.salaryDetailMaster.length > 0"
                            value="{{hd.salaryHeadId}}">
                            {{hd.salaryHeadName}}
                          </option>
                        </ng-container>
                      </select>
                      
                    </div>
                  </div>
                </div> -->


                <!-- <div class="col-md-3 col-sm-4 col-xs-4">
                  <div class="form-group label-floating">
                    <label class="form-label">Salary Detail</label>
                    <div>
                     
                      <select class="form-control col-md-12 col-xs-12" [(ngModel)]="salaryDetailId" id="SalaryDetail"
                        name="SalaryDetail" #enteredsalarydetail="ngModel" disabled>
                        <option value="">&nbsp; </option>
                        <ng-container *ngFor="let sd of detailMasterSection">
                          <option value="{{sd.salaryDetailId}}">
                            {{sd.salaryDetailName}}
                          </option>
                        </ng-container>
                      </select>
                    
                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-md-3 col-sm-4 col-xs-4">
                  <div class="form-group label-floating">
                    <label class="form-label">Interest Rate</label>
                    <div>
                      <input type="text" class="form-control col-md-12 col-xs-12" autocomplete="off"
                        placeholder="Enter Interest Rate" name="enteredinterestRate" [(ngModel)]="interestRate" 
                        #enteredinterestRate="ngModel" [disabled]="isOnlyView"
                        [ngClass]="(!enteredinterestRate.valid && enteredinterestRate.touched) || (!enteredinterestRate.valid && submitted) ? 'is-invalid' : ''" />
                    </div>
                    <span class="help-text text-danger" *ngIf="(!enteredinterestRate.valid && enteredinterestRate.touched) || (!enteredinterestRate.valid && submitted)">Please Enter
                                Amount</span>
                  </div>
                </div> -->


                <div class="actionBar">
                  <!-- Left side button -->
                  <!-- <button *ngIf="!isEdit" class="btn btn-primary" type="button" style="float: left;" (click)="calcInstallment()"
                             data-bs-toggle="modal" data-bs-target="#calInstallmentModel">
                              Installment Calculation
                            </button> -->

                  <!-- <button *ngIf="isEdit" class="btn btn-primary" type="button" style="float: left;"
                    (click)="editcalcInstallment()" data-bs-toggle="modal" data-bs-target="#calInstallmentModel">
                    Verify instalment
                  </button> -->

                  <!-- Right side buttons -->
                  <div style="float: right;">
                    <!-- <button *ngIf="!isEdit" class="btn btn-success" type="button" (click)="submit()">
                                Save
                              </button> -->
                    <button *ngIf="isEdit && isVerify" class="btn btn-primary" type="button"  style="margin-right: 8px;"  (click)="editcalcInstallment()"
                      data-bs-toggle="modal" data-bs-target="#calInstallmentModel">
                      Verify instalment
                    </button>
                    <button *ngIf="isEdit && isVerify" class="btn btn-success" type="button" (click)="update()">
                      Update
                    </button>
                    <button class="btn btn-danger ms-2" type="button" (click)="clear()">
                      Clear
                    </button>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>


    <!-- Row -->
    <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
    
          <div class="card-body">
            <!-- <form id="salaryadvanceListform" class="form-horizontal form-label-left row" #salaryadvanceListform="ngForm"> -->
              <div class="table-responsive">
                <div class="scrollsearchresults" infiniteScroll  (scrolled)="onScroll()" id="scrolllength" >
                  <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom">
                    <thead class="tablerow stickyatTop">
                      <tr class="rowcolors stickyatTop">
                        <th><input (change)="checkallbox($event)" type="checkbox" style="cursor: pointer;" /></th>
                        <th class="border-bottom-0 fs-7">Employee ID</th>
                        <th class="border-bottom-0 fs-7">Employee Name</th>
                        <!-- <th class="border-bottom-0 fs-7">Advance ID</th> -->
                        <th class="border-bottom-0 fs-7">
                           <span *ngIf="typeFilter === 'L'">Loan</span> 
                           <span *ngIf="typeFilter === 'A'">Advance</span>  Disbursement Date</th>
                        <th class="border-bottom-0 fs-7">Applied Date</th>
                        <th class="border-bottom-0 fs-7">Approved Date</th>
                        <th class="border-bottom-0 fs-7">Amount</th>
                        <th class="border-bottom-0 fs-7">Status</th>
                        <!-- <th class="border-bottom-0 fs-7">Salary Header</th>
                        <th class="border-bottom-0 fs-7">Salary Detail</th> -->
                        <th class="border-bottom-0">Remark</th>
                        <!-- <th class="border-bottom-0 fs-7">Interest Rate</th> -->
    
                        <th class="border-bottom-0 fs-7">Action</th>
                      </tr>
                    </thead>
                    <tbody>
    
                      <tr *ngFor="let sal of salaryAdvanceList ; let i = index">
                        <td>
                          <input class="unitaskbox" [disabled]="sal.status != 'PENDING' " [checked]="sal.checked"
                            (change)="check_info(sal ,$event)" type="checkbox" style="cursor: pointer;" />
                        </td>
                        <td>{{ sal.employeeid }}</td>
                        <td>{{ sal.employeeName }}</td>
                        <td>{{ sal.dateOfLoan | date}}</td>
                        <td>{{ sal.appliedDate | date}}</td>
                        <td>{{ sal.approvedDate | date}}</td>
                        <td>{{ sal.totalAmount | currency : "INR" : "symbol" : "1.2-2"}}</td>
    
                        <!-- <td>
                                                        <span *ngIf="task.status === 'Pending'"
                                                            class="statusPending">{{task.status}}</span>
                                                        <span *ngIf="task.status === 'Approved'"
                                                            class="statusApproved">{{task.status}}</span>
                                                        <span *ngIf="task.status === 'Partial Approve 1'"
                                                            class="statusP1">{{task.status}}</span>
                                                        <span *ngIf="task.status === 'Partial Approve 2'"
                                                            class="statusP2">{{task.status}}</span>
                                                        <span *ngIf="task.status === 'Rejected'"
                                                            class="statusReject">{{task.status}}</span>
                                                    </td> -->
    
                        <td>
                          <span *ngIf="sal.status === 'PENDING'" class="badge badge-warning">{{sal.status}}</span>
                          <span *ngIf="sal.status === 'APPROVED'" class="badge badge-success">{{sal.status}}</span>
                          <span *ngIf="sal.status === 'REJECTED'" class="badge badge-danger">{{sal.status}}</span>
                        </td>
                        <!-- <td>
                          <select *ngIf="sal.status === 'PENDING'" class="form-control col-md-12 col-xs-12" [(ngModel)]="sal.salaryHeadId" id="SalaryHead2"
                            name="SalaryHead2" (change)="ListheadChange(sal.salaryHeadId,sal)"
                            #enteredsalaryHead2="ngModel">
                            <option value="0">&nbsp; </option>
                            <ng-container *ngFor="let hd of salaryMaster">
                              <option value="{{hd.salaryHeadId}}">
                                {{hd.salaryHeadName}}
                              </option>
                            </ng-container>
                          </select>
                          <span *ngIf="sal.status === 'APPROVED'" >{{sal.salaryHeadName}}</span>
                        </td>
                        <td>
                          <select *ngIf="sal.status === 'PENDING'" class="form-control col-md-12 col-xs-12" [(ngModel)]="sal.salaryDetailId"
                            id="SalaryDetail2" name="SalaryDetail2" #enteredsalarydetail2="ngModel">
                            <option value="0">&nbsp; </option>
                            <ng-container *ngFor="let sd of sal.detailMasterSection">
                              <option value="{{sd.salaryDetailId}}">
                                {{sd.salaryDetailName}}
                              </option>
                            </ng-container>
                          </select>
                          <span *ngIf="sal.status === 'APPROVED'" >{{sal.salaryDetailName}}</span>
                        </td> -->
                        <!-- <td>
                          <div>
                            <input *ngIf="sal.status === 'PENDING'" type="text" class="form-control col-md-12 col-xs-12" autocomplete="off"
                              placeholder="Enter Interest Rate" name="enteredinterestRate" [(ngModel)]="sal.interestRate"
                              required #enteredinterestRate="ngModel" />
                              
                          </div>
                          <span *ngIf="sal.status === 'APPROVED'" >{{sal.interestRate | number: '1.2-2' }}%</span>
                        </td> -->
                        <td>
                          <input autocomplete="off" type="text" style="min-width: 9.7rem;"
                          name="remark" class="form-control"
                          [(ngModel)]="sal.remarks"
                          placeholder="Comments for Rejection">
                        </td>
                        <td>
                          <button *ngIf="sal.status === 'PENDING' || sal.status === 'APPROVED'"  class="btn btn-primary btn-icon btn-sm me-2"
                            data-method="edit" title="Edit" (click)="editSalaryAdvance(sal)">
                            <i class="fa fa-edit" data-bs-toggle="tooltip" data-original-title="edit"></i>
                          </button>
                          <!-- <button class="btn btn-danger btn-icon btn-sm me-2">
                                                            <i class="fa fa-trash"></i>
                                                        </button> -->
                        </td>
        
    
    
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="pro-fab2 btn-float-right" >
                <button type="button" *ngIf="isSearch" class="btn btn-success" (click)="approvalhandler()">Approve</button>
                <button type="button" *ngIf="isSearch" class="btn btn-danger ms-2" (click)="rejecthandler()">Reject</button>
              </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
    <!-- End Row-->
    <div class="modal  fade" id="calInstallmentModel" tabindex="-1" aria-labelledby="calInstallmentModel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="calInstallmentModellLabel" style="font-size: 20px;">Installment Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
          </div>
          <div class="modal-body">
            <div class="alertmodel"></div>
            <div class=" container-fluid mb-6">

              <!-- <div>Salary Detail Additional Component</div> -->

              <!-- <h5 class="card-title mb-3 "> Salary Detail Additional Component </h5> -->

              <div class="mobile">
                <div style="display: flex; align-items: center; gap: 20px; padding-bottom: 10px;">
                  <div class="col-md-6 col-sm-6 col-xs-6">
                      <strong>Loan Amount: </strong>
                      <span>{{ totalAmount  | currency : "INR" : "symbol" : "1.2-2"}}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-6">
                    <strong>Total Amount Payable: </strong>
                    <span>{{ totalAmountToPay  | currency : "INR" : "symbol" : "1.2-2"}}</span>
                    <strong> (including Interest)</strong>
                </div>
               </div>
                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table1">
                  <!-- <table class="table  table-vcenter text-nowrap table-bordered border-bottom"> -->
                  <thead class="tablerow">
                    <tr class="rowcolors">
                      <th class="border-bottom-0 fs-6 ">Month</th>
                      <th class="border-bottom-0 fs-6 ">Installment Amount (with {{interestRate}}% Interest)</th>
                      <th class="border-bottom-0 fs-6 ps-3">Paid Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of installmentComp ; let i = index">
                      <td data-th="Date">{{item.instDate | date : 'MMM yyyy'}}</td>
                      <td data-th="Installment Amount">
                        <input type="number" min="0" size="10" class="form-control" [value]="item?.instAmount "
                        [(ngModel)]="item.instAmount" placeholder="Enter amount" [disabled]="item.isSalaryGenerated"  (change)="updateInstallmentCompBasedOnInstallmentAmount(i,item.instAmount);">
                      </td>
                        <!-- (change)="updateInstallmentCompBasedOnInstallmentAmount(i,item.instAmount);" -->
                      <td data-th="Paid Amount">
                        <!-- <input type="number" min="0" class="form-control "
                                                (input)="readamount($event,item , true)" placeholder="Enter amount"
                                                data-name="{{item?.salaryDetailName}}"
                                                [value]="item?.salaryComponentAmount === -1 ? 0.0 : item?.salaryComponentAmount"> -->
                        <input type="number" min="0" class="form-control " [value]="item?.paidAmount " [disabled]="item.isSalaryGenerated || currentStatus === 'PENDING'"
                        [(ngModel)]="item.paidAmount" placeholder="Enter amount" (blur)="updateInstallmentCompBasedOnPaidAmount(i,item.instAmount,item.paidAmount)">

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>



          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Reset</button> -->
            <button type="button" class="btn btn-primary"
              (click)="ok()">Save</button>
          </div>
        </div>
      </div>
    </div>

  </div>