import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Menu {
    private menuNameSource: BehaviorSubject<string>;
    currentMenuName:any;

    constructor() {
    const savedMenuName = localStorage.getItem('currentMenuName') || 'Dashboard';
    this.menuNameSource = new BehaviorSubject<string>(savedMenuName);
    this.currentMenuName = this.menuNameSource.asObservable();
    }

    setMenuName(name: string) {
    this.menuNameSource.next(name);
    localStorage.setItem('currentMenuName', name); // Store menu name in localStorage
    }
}
