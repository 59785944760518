import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Component } from "@angular/core";
import { BaseRequestOptions } from "@angular/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { FormsModule } from '@angular/forms';
import { HttpModule, Http } from "@angular/http";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule, FormControl } from "@angular/forms";
import { FormTReportComponent } from "./form-t-report/form-t-report.component";



import {TDSSTATEMENTCOMPONENT} from  "./tds-report/tds-statement-report.component";
import { CommonModule, TitleCasePipe } from '@angular/common';

import { routing, appRoutingProviders } from "./app.routing";
import { LoggedInGuard } from "./logged-in.guard";
// import { ChartsModule } from 'ng2-charts';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

import { NgForm } from '@angular/forms';


import { AppComponent } from "./app.component";
import { CompanySelectionComponent } from "./companyselection/companyselection.component";
import { LoginComponent, SignUpComponent } from "./auth/index";


import { CommonComponentsModule } from "./commonComponents/commonComponents.module";
import { OutgoingInterceptor } from "./httpInterceptor/outgoingInterceptor";

import {
  AlertService,
  HelperService,
  SessionService,
  ConstantService,
} from "./services";

// import { ProductionplanDynamicComponent } from './production-plan/productionplan-dynamic/productionplan-dynamic.component';
// import { ProductiondynamicComponent } from './production/productiondynamic/productiondynamic.component';
// import{customer-component}from'./customers/cus
import { TempComponent } from "./temp/temp.component";

// import { MprodDynamicComponent } from './mproduction/mprod-dynamic/mprod-dynamic.component';
import { CurrencyPipe, DatePipe } from "@angular/common";
import { AlertsComponent } from "./alerts/alerts.component";
import { PeopleComponent } from "./people/people.component";
import { ChangepwdComponent } from "./changepwd/changepwd.component";
// import { CustomersComponent } from './customers/customers.component';

import { ReportComponent } from './report/report.component';

//Adding
import { DocumentComponent } from "./document/document.component";
import { SaveDocComponent } from "./document/savedoc/savedoc.component";
import { UploadPolicyComponent } from "./uploadrulesandpolicy/uploadpolicy.component";
import { MyDocComponent } from "./document/myDocuments/mydocuments.component";
import { ApproveworkflowComponent } from "./approveworkflow/approveworkflow.component";
import { ApprovalinboxComponent } from "./approvalinbox/approvalinbox.component";
import { ListMydocComponent } from "./document/listMydocuments/listmydoc.component";
// import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CalenderComponent } from "./dashboard/calender/calender.component";
import { DailyTasksComponent } from "./dashboard/daily-tasks/daily-tasks.component";
import { RulesAndPolicyComponent } from './dashboard/rulesAndPolicy/rulesAndPolicy.component';
import { FrequentlyUsedAppsComponent } from "./dashboard/frequently-used-apps/frequentlyUsedApps.component";
import { HighlightsComponent } from "./dashboard/highlights/highlights.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { EmployeeMasterModule } from "./employeemaster/employeemaster.module";
import { ReviewpayslipsComponent } from "./reviewpayslips/reviewpayslips.component";
import { EmployeesearchComponent } from './employeesearch/employeesearch.component';
import { EmployeeDocSearchComponent } from "./empdocsearch/employeedocsearch.component";
import { EmployeeLeaveComponent } from './employee-leave/employee-leave.component';
import { EmployeeLeaveApprovalComponent } from './employee-leave-approval/employee-leave-approval.component';
import { MatTabsModule } from '@angular/material/tabs';

import { NgApexchartsModule } from "ng-apexcharts";
import { LeaveStatusReportComponent } from './leave-status-report/leave-status-report.component';
import { ApproveLeavePipe } from './pipes/approve-leave.pipe';

import { EmployeeLeaveProxyComponent } from './employee-leave-proxy/employee-leave-proxy.component';
import { AnnualholidayComponent } from "./dashboard/annualholiday/annualholiday.component";
import { EmployeeAttendenceComponent } from "./employee-attendence/employee-attendence.component";
import { EmployeeonleaveComponent } from "./dashboard/employeeonleave/employeeonleave.component";
import { BulkUploadDocComponent } from "./bulkuploaddocuments/bulkuploaddocuments.component";
import { FileDragNDropDirective } from "./bulkuploaddocuments/file-drag-n-drop.directive";
import { PayslipComponent } from './payslip/payslip.component';
import { EmployeeAttendenceSummaryComponent } from './employee-attendence-summary/employee-attendence-summary.component';
import { EmployeeLeaveEncashmentComponent } from './employee-leave-encash/employee-leave-encash.component';
import { EmployeeLeaveEncashReportComponent } from './employee-leave-encash-report/emp-leave-encash-report.component';
import { EmployeehrComponent } from './employeehr/employeehr.component';
import { UserdetailsComponent } from './userdetails/userdetails.component';
import { EmployeedocdetailsComponent } from './employeedocdetails/employeedocdetails.component';

import { AttendanceAprrovalComponent } from './attendance-aprroval/attendance-aprroval.component';
import { DepartmentComponent } from './department/department.component';
import { DesignationComponent } from './designation/designation.component';
import { ApproveAttendancePipePipe } from './pipes/approve-attendance-pipe.pipe';
import { NoticeBoardComponent } from './notice-board/notice-board.component';
import { CustCalenderComponent } from './cust-calender/cust-calender.component';
import { SettingComponent } from './setting/setting.component';
import { EmployeegroupComponent } from './employeegroup/employeegroup.component';
import { ShiftallocationComponent } from './shiftallocation/shiftallocation.component';
import { GetleavebalanceofatypePipe } from './pipes/getleavebalanceofatype.pipe';
import { ShiftallocatedComponent } from './empshiftallocated/shiftallocated.component';
import { ContractClientComponent } from './contract-client/contract-client.component';
import { ContractClientLocationComponent } from './contract-client/contract-client-location/contract-client-location.component';
import { ContractClientProjectComponent } from './contract-client-project/contract-client-project.component';
import { ContractProjectDesEstComponent } from './contract-project-des-est/contract-project-des-est.component';
import { ContractProjectDesActComponent } from './contract-project-des-act/contract-project-des-act.component';
import { AppRoutingModule } from "./app-routing.module";
import { ContractClientProjectFreezeComponent } from './contract-client-project-freeze/contract-client-project-freeze.component';
import { ContractProjectAllocationComponent } from './contract-project-allocation/contract-project-allocation.component';
import { ShowAllLeaveForApprovalComponent } from "./dashboard/show-all-leave-for-approval/show-all-leave-for-approval.component";
import { AllocationAmountPipePipe } from './pipes/allocation-amount-pipe.pipe';
import { FromToCountDaysPipe } from './pipes/from-to-count-days.pipe';
import { EmployeeSearchActPipe } from './pipes/employee-search-act.pipe';
import { TeamMemberOnLeaveComponent } from './dashboard/team-member-on-leave/team-member-on-leave.component';
import { EmployeelistingComponent } from './employeelisting/employeelisting.component';
import { AttendanceReportComponent } from './attendance-report/attendance-report.component';
import { DetailedReportComponent } from './attendance-report/detailed-report/detailed-report.component';
import { EmployeeAttendenceProxyComponent } from './employee-attendence-proxy/employee-attendence-proxy.component';
import { BranchComponent } from './branch/branch.component';
import { ConsolidatedReportComponent } from './attendance-report/consolidated-report/consolidated-report.component';
import { StringDate24To12Pipe } from './pipes/string-date-24-to-12.pipe';
import { EmployeeAttendenceV2Component } from './employee-attendence-v2/employee-attendence-v2.component';
import { AttendanceMatrixComponent } from './attendance-report/attendance-matrix/attendance-matrix.component';
import { StringDate24To12RoundFigureTimePipe } from './pipes/string-date-24-to-12-round-figure-time.pipe';
import { LeaveMasterComponent } from './leave-master/leave-master.component';
import { LeaveAssignComponent } from "./leave-assign/leave-assign.component";
import { BranchMasterComponent } from './branch-master/branch-master.component';
import { ExpenseMasterComponent } from './expense-master/expense-master.component';
import { FaceRecognitionComponent } from './face-recognition/face-recognition.component';
import { ExpenseApprovalComponent } from './expense-approval/expense-approval.component';
import { ExpenseTypeComponent } from './expense-type/expense-type.component';
import { PolicySubmissionComponent } from './policy-submission/policy-submission.component';
import { PolicyApprovalComponent } from './policy-approval/policy-approval.component';
import { GroupMasterComponent } from './group-master/group-master.component';
import { AnnualHolidayMasterComponent } from './annual-holiday-master/annual-holiday-master.component';

import { ExpenseReportComponent } from './expense-report/expense-report.component';
import { JobmasterComponent } from './jobmaster/jobmaster.component';
import { CompOffComponent } from "./apply-comp-off/comp-off.component";
import { EmployeereportComponent } from './employeereport/employeereport.component';

import { ApproveCompOffComponent } from './approve-comp-off/approve-comp-off.component';
import { SalaryHeadComponent } from './salary-master/salary-head/salary-head.component';
import { SalaryDetailComponent } from './salary-master/salary-detail/salary-detail.component';
import { PayCalculateRulesComponent } from './pay-calculate-rules/pay-calculate-rules.component';
import { SalaryFedComponent } from './salary-master/salary-fed/salary-fed.component';
import { ApplyExtraTimeoutComponent } from './apply-extra-timeout/apply-extra-timeout.component';
import { ApproveExtraTimeoutComponent } from './approve-extra-timeout/approve-extra-timeout.component';
import { ExtraTimeoutReportComponent } from './extra-timeout-report/extra-timeout-report.component';
import { ApproveExtraTimeOutPipe } from './pipes/approve-extra-time-out.pipe';
import { GenerateSalaryComponent } from './salary-master/generate-salary/generate-salary.component';
import { SalaryReportBankComponent } from './salary-master/salary-report-bank/salary-report-bank.component';
import { PfDownloadReportComponent } from './pf-download-report/pf-download-report.component';
import { EsiStatementDownloadComponent } from './esi-statement-download/esi-statement-download.component';
import { PfStatementReportComponent } from "./pf-statement-report/pf-statement-report.component";
import { SalaryAdvanceComponent } from "./salary-advance/salary-advance.component";
import { OverTimeReportComponent } from './overtimereport/overtime-report.component';

import { EmployeeOvertimeComponent } from "./employee-overtime/employee-overtime.component";
import { ApproveEmployeeOvertimeComponent } from "./approve-employee-overtime/approve-employee-overtime.component";
import { FormTDownloadReportComponent } from "./form-t-download-report/form-t-download-report.component";
import { SavingsMasterComponent } from "./savings-master/savings-master.component";
import { SalaryAdvanceApproveComponent } from "./salary-advance-approve/salary-advance-approve.component";
import { StatutoryReportDownloadComponent } from "./statutory-report-download/statutory-report-download.component";
import { IncomeTaxCalculatorComponent } from "./income-tax-calculator/income-tax-calculator.component";
import { ITSlabMasterComponent } from "./it-slab-master/it-slab-master.component";
import { EpfAbstractReportComponent } from "./epf-abstract-report/epf-abstract-report.component";
import { ShiftMasterComponent } from './shift-master/shift-master.component';
import { ReportModule } from "./report/report.module";
import { SalaryLoanApplyComponent } from './salary-loan-apply/salary-loan-apply.component';
import { EmployeeDesignationComponent } from "./employee-designation/employee-designation.component";


@NgModule({
  declarations: [
    AppComponent,
    CompanySelectionComponent,
    LoginComponent,
    SignUpComponent,
    TempComponent,
    AlertsComponent,
    PeopleComponent,
    ChangepwdComponent,
    //Added
    DocumentComponent,
    SaveDocComponent,
    MyDocComponent,
    FormTReportComponent,
    TDSSTATEMENTCOMPONENT,
    FormTDownloadReportComponent,
    PfStatementReportComponent,
    ApproveworkflowComponent,
    ApprovalinboxComponent,
    

    ListMydocComponent,
    ReviewpayslipsComponent,
    DashboardComponent,
    CalenderComponent,
    DailyTasksComponent,
    FrequentlyUsedAppsComponent,
    HighlightsComponent,
    UploadPolicyComponent,
    RulesAndPolicyComponent,
    EmployeesearchComponent,
    EmployeeDocSearchComponent,

    EmployeeLeaveComponent,
    EmployeeLeaveApprovalComponent,
    LeaveStatusReportComponent,
    ApproveLeavePipe,
    EmployeeLeaveProxyComponent,
    AnnualholidayComponent,
    EmployeeAttendenceComponent,
    EmployeeonleaveComponent,
    BulkUploadDocComponent,
    FileDragNDropDirective,
    PayslipComponent,
    EmployeeAttendenceSummaryComponent,
    EmployeeLeaveEncashmentComponent,
    EmployeeLeaveEncashReportComponent,
    EmployeehrComponent,
    UserdetailsComponent,
    EmployeedocdetailsComponent,

    AttendanceAprrovalComponent,
    DepartmentComponent,
    DesignationComponent,
    ApproveAttendancePipePipe,
    NoticeBoardComponent,
    CustCalenderComponent,
    SettingComponent,
    EmployeegroupComponent,
    ShiftallocationComponent,
    GetleavebalanceofatypePipe,
    ShiftallocatedComponent,
    ContractClientComponent,
    ContractClientLocationComponent,
    ContractClientProjectComponent,
    ContractProjectDesEstComponent,
    ContractProjectDesActComponent,
    ContractClientProjectFreezeComponent,
    ContractProjectAllocationComponent,
    ShowAllLeaveForApprovalComponent,
    AllocationAmountPipePipe,
    FromToCountDaysPipe,
    EmployeeSearchActPipe,
    TeamMemberOnLeaveComponent,
    EmployeelistingComponent,
    AttendanceReportComponent,
    DetailedReportComponent,
    EmployeeAttendenceProxyComponent,
    BranchComponent,
    ConsolidatedReportComponent,
    StringDate24To12Pipe,
    EmployeeAttendenceV2Component,
    AttendanceMatrixComponent,
    StringDate24To12RoundFigureTimePipe,
    LeaveMasterComponent,
    LeaveAssignComponent,
    BranchMasterComponent,
    ExpenseMasterComponent,
    FaceRecognitionComponent,
    ExpenseApprovalComponent,
    ExpenseTypeComponent,
    PolicySubmissionComponent,
    PolicyApprovalComponent,
    GroupMasterComponent,
    AnnualHolidayMasterComponent,
    ExpenseReportComponent,
    JobmasterComponent,
    CompOffComponent,
    EmployeereportComponent,
    ApproveCompOffComponent,
    SalaryHeadComponent,
    SalaryDetailComponent,
    PayCalculateRulesComponent,
    SalaryFedComponent,
    ApplyExtraTimeoutComponent,
    ApproveExtraTimeoutComponent,
    ExtraTimeoutReportComponent,
    ApproveExtraTimeOutPipe,
    SalaryAdvanceComponent,
    SalaryAdvanceApproveComponent,
    GenerateSalaryComponent,
    SalaryReportBankComponent,
    PfDownloadReportComponent,
    EsiStatementDownloadComponent,
    OverTimeReportComponent,
    EmployeeOvertimeComponent,
    ApproveEmployeeOvertimeComponent,
    SavingsMasterComponent,
    EpfAbstractReportComponent,
    StatutoryReportDownloadComponent,
    IncomeTaxCalculatorComponent,
    ITSlabMasterComponent,
    ShiftMasterComponent,
    SalaryLoanApplyComponent,
    EmployeeDesignationComponent
  ],
  entryComponents: [],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    // FormControl,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    routing,
    InfiniteScrollModule,
    EmployeeMasterModule,
    MatTabsModule,
    NgApexchartsModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatAutocompleteModule,
    MatButtonModule, MatGridListModule, MatSnackBarModule, BrowserAnimationsModule, MatDialogModule,
    CommonComponentsModule
  ],

  providers: [
    CurrencyPipe,
    appRoutingProviders,
    AlertService,
    HelperService,
    LoggedInGuard,
    BaseRequestOptions,
    DatePipe,
    TitleCasePipe,
    SessionService,
    ConstantService,
    { provide: Http, useClass: OutgoingInterceptor },
    { provide: HTTP_INTERCEPTORS, useClass: OutgoingInterceptor, multi: true },
  ],

  bootstrap: [AppComponent],

})
export class AppModule { }
