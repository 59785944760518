import { error } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { reviewPaySlips } from 'src/app/models/reviewpayslips';
import { SalaryDetailMaster } from 'src/app/models/SalaryMaster';
import { EmployeeAttendenceProxyService } from 'src/app/services/employee-attendence-proxy.service';
import { EmpsalaryslipService } from 'src/app/services/empsalaryslip.service';
import { reviewpayslipService } from 'src/app/services/reviewpayslips.service';

@Component({
  selector: 'app-generate-salary',
  templateUrl: './generate-salary.component.html',
  styleUrls: ['./generate-salary.component.css']
})
export class GenerateSalaryComponent implements OnInit {

  constructor(private employeeService: EmployeeAttendenceProxyService, private reviewPay: reviewpayslipService,
    private empsalaryservice: EmpsalaryslipService
  ) { }

  addOrUpdate: any;
  branchInfo: any;
  activeEmployeeList: any;
  months: any;
  employeeid: any = 'all';
  branch: any = 'all';
  voidSalaryreason: any;
  fetchSalaryData: any;
  salaryDetail = new SalaryDetailMaster();

  ngOnInit(): void {
    this.getAllBranches();
    this.getAllActiveEmployee();
    this.getSalaryDetail();
  }

  getSalaryDetail() {
    this.empsalaryservice.getSalaryDetailsD().subscribe(
      (data) => {
        this.salaryDetail = data.salaryDetailMaster;
      }, (error) => {
        console.log(error)
      }
    )
  }

  getAllBranches() {
    console.log("Called all branches >> >> >>")
    this.employeeService.getAllBranchs().subscribe(
      data => {
        console.log("check this")
        this.branchInfo = data.branches;
      },
      error => {
        console.log("error in branch ", error);

      }
    );
  }

  getAllActiveEmployee() {
    console.log("Called all active employee >> >> >>")
    this.employeeService.getAllActiveEmployeeList().subscribe(
      data => {
        this.activeEmployeeList = data.employeeMasterData;
        console.log("activeEmployeeList ", this.activeEmployeeList);
      },
      error => {
        console.log("error in active employee ", error);

      }
    );
  }

  onGenerateChange(info) {
    this.fetchSalaryData = [];
  }

  generateSalarySlip() {

    const obj = {
      empId: this.employeeid,
      monthSelected: this.months,
      branchId: this.branch,
      void_reason: this.voidSalaryreason,
      generate: false
    }

    if (obj.monthSelected == undefined) {
      this.alertmessages("Please select month to generate salary", "danger");
      return;
    }

    console.log("generate Salary function ", obj);
    this.reviewPay.generatePayslip(obj).subscribe(
      data => {
        this.alertmessages("Salary Generation Started Please Wait For a While", "success");
        console.log("generate Salary data", data);
      }, error => {
        console.log("error occured in generate Salary", error);
      }
    )
  }

  fetchSalary() {

    const obj = {
      empId: this.employeeid,
      monthSelected: this.months,
      branchId: this.branch,
      void_reason: this.voidSalaryreason,
    }

    if (obj.monthSelected == undefined) {
      this.alertmessages("Please select month to fetch salary", "danger");
      return;
    }

    console.log("fetch Salary function ");
    this.reviewPay.fetchPaySlip(obj).subscribe(
      data => {
        this.fetchSalaryData = data.fetchSalaryDetails;
        console.log(this.fetchSalaryData);

      }, error => {
        this.alertmessages("Please Try again something went wrong", "Danger");
      }
    )
  }

  voidSalary() {

    const obj = {
      empId: this.employeeid,
      monthSelected: this.months,
      branchId: this.branch,
      void_reason: this.voidSalaryreason,
    }

    if (obj.monthSelected == undefined) {
      this.alertmessages("Please select month to void salary", "danger");
    }

    console.log("void Salary function ");
    this.reviewPay.voidPaySlip(obj).subscribe(
      data => {
        console.log("void Salary function data ", data);
      }, error => {
        console.log(" error void Salary function ", error);
      }
    )
  }

  generateSalaryDownload() {

    const obj = {
      empId: this.employeeid,
      monthSelected: this.months,
      branchId: this.branch,
      void_reason: this.voidSalaryreason,
    }

    if (obj.monthSelected == undefined) {
      this.alertmessages("Please select month to download salary slip ", "danger");
    }

    console.log("download Salary function ");
    this.reviewPay.generatePaySlipDownload(obj).subscribe(
      data => {
        console.log("data download Salary function ", data);
      }, error => {
        console.log("download Salary function error ", error);
      }
    )
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

}
