import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ExpenseType } from '../models/expenseType';
import { SessionService } from '../services';
import { SalaryAdvanceService } from '../services/salary-advance.service';
import { salaryAdvance } from '../models/salaryAdvance';
import { EmployeeMaster } from '../models/employeeMasterts';
declare var $: any;

@Component({
  selector: 'app-salary-advance',
  templateUrl: './salary-advance.component.html',
  styleUrls: ['./salary-advance.component.css']
})
export class SalaryAdvanceComponent implements AfterViewInit {
  constructor(private salaryAdvanceService: SalaryAdvanceService, private sessionservice: SessionService) { }

  @ViewChild('salaryadvanceform') salaryAdvanceForm: NgForm;

  ngOnInit(): void {
    // get the permisson from application session 
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');

    this.checkAccesForUser();

    this.getSalaryAdvanceSettings();
    this.model.uniqueEmpId = Number(this.sessionservice.getempid());
    this.userId = Number(this.sessionservice.getempid());
    this.uniqueEmpId = this.sessionservice.getempid();
    // this.getGrossSalary();
    this.submitted = false;
    this.clear();
    // this.getAdvApplyHistory();

  }

  ngAfterViewInit() {
    this.clearForm();
  }

  interMasterId: number;
  amount: number;
  totalAmount: number;
  noOfInstallment: number;
  maxAmtForAdv: number;
  selectedList = new ExpenseType();
  expenseType: any[] = [];
  salaryAdvanceList: any[] = [];
  permissionList: any[] = [];
  showBasedOnApproverScreen = true;
  // salaryAdvance = salaryAdvance;

  date_of_loan: any;
  // Ins_StartDate: any;
  model: any = {};
  installmentComp = [];
  isEdit: boolean = false;
  submitted: boolean = false;
  isOnlyView: boolean = false;
  isButtonClick: boolean = false;
  salaryAdvanceData = new salaryAdvance();
  dateError: boolean = false;
  fieldChanged: boolean = false;
  reason: String;
  interestRate: number;
  NoTimesForAdvance: number;
  salaryHeadId: any;
  salaryDetailId: any;
  grossSalary: number;
  totalAmountToPay: number;
  teamMemberList: any[];
  employeeMasterLucene: EmployeeMaster[];
  selectedType: string = 'select';
  uniqueEmpId: any;
  query: any;
  userId:any;


  onDateChange(): void {
    // this.setInsStartDate();
    console.log("date_of_loan", this.date_of_loan);
    const currentDate = new Date(); // Current date
    const normalizedDateOfLoan = new Date(this.date_of_loan);
    console.log("currentDate", currentDate);
    currentDate.setHours(0, 0, 0, 0);
    normalizedDateOfLoan.setHours(0, 0, 0, 0);

    // Check if the date_of_loan is before the current date
    if (normalizedDateOfLoan >= currentDate) {
      this.dateError = false;
      console.log('The loan date is today or in the future.');
    } else {
      console.log('The loan date is in the past.');
      this.dateError = true;
    }
  }
  onfieldChange() {

    if (this.isEdit && this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan) {
      // this.fieldChanged = true;
      this.setSalaryAdvanceModel();
      // this.setInstallmentComp();
      console.log("onfieldChange")
    }

  }

  checkAccesForUser() {
    // check permission list has the permission "access link"

    console.log(this.permissionList);
    this.showBasedOnApproverScreen = this.permissionList.includes('ng.screen.salary.advanceAprove');
    console.log("apply for others", this.showBasedOnApproverScreen);

  }

  initialize() {
    this.totalAmount = null;
    this.noOfInstallment = null;
    this.date_of_loan = null;
    // this.Ins_StartDate = null;
    this.model.installmentComp = [];
    this.installmentComp = [];
    this.isEdit = false;
  }

  calcInstallment() {
    this.submitted = true;
    this.clearDiv();
    console.log("Step  Success +++++++++++++++++++++++++++");
    console.log("uniqueEmpid", this.sessionservice.getempid())
    if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan) {
      console.log("Step 1 Success +++++++++++++++++++++++++++");
      this.setSalaryAdvanceModel();
      this.calcTotalAmountPay();
      // this.setInstallmentComp();
    }

  }

  editcalcInstallment() {

    this.submitted = true;
    this.isButtonClick = true;
    this.clearDiv();
    console.log("uniqueEmpid", this.sessionservice.getempid())
    if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan) {
      this.setSalaryAdvanceModel();
      this.calcTotalAmountPay();
    }
    if (this.isOnlyView) {
      this.submitted = false;
    }
  }

  calcTotalAmountPay() {
    let totalInstallments = this.noOfInstallment;
    // Calculate installment amount
    // let installAmt = (this.totalAmount / totalInstallments).toFixed(2);
    console.log("this.totalAmount =>", this.totalAmount);
    console.log("totalInstallments =>", totalInstallments);
    console.log("this.interestRate =>", this.interestRate);
    let interest: number = (this.totalAmount * totalInstallments * this.interestRate) / (100 * 12);
    console.log("this.interest =>", interest);
    this.totalAmountToPay = Number(this.totalAmount) + Number(interest);
    console.log("this.totalAmountToPay =>", this.totalAmountToPay);
  }

  getAdvApplyHistory() {

    let uniqueEmpId = this.sessionservice.getempid();
    this.salaryAdvanceService.getAllAdvAppliesByUid(uniqueEmpId, this.salaryHeadId, this.salaryDetailId).subscribe(
      data => {
        console.log("Saved data : ", data)
        this.salaryAdvanceList = data.salaryAdvanceList;
      }, error => {

      });
  }

  getSalaryAdvanceSettings() {
    console.warn("getJobList ");
    this.salaryAdvanceService.getSalaryAdvanceSettings().subscribe(
      data => {
        console.log(data);
        this.interestRate = data.interestRateForAdvance;
        this.salaryHeadId = data.salaryHeadIdForAdvance;
        this.salaryDetailId = data.salaryDetailIdForAdvance;
        this.NoTimesForAdvance = data.noTimesForAdvance;
        this.getGrossSalary();
        this.getAdvApplyHistory();
        if (this.interestRate == undefined || this.interestRate == null || this.salaryHeadId == undefined || this.salaryHeadId == 0
          || this.salaryDetailId == undefined || this.salaryDetailId == 0 || this.NoTimesForAdvance == undefined
          || this.NoTimesForAdvance == null || this.NoTimesForAdvance == 0) {
          this.alertmessages("Salary advance setting is not updated.", "danger", '.alertcomp');

        } // called here because it needs resp of getSalaryAdvanceSettings method
      }, error => {
        console.log(error);
      }
    );
  }

  getGrossSalary() {
    this.salaryAdvanceService.getGrossSalaryByUId(Number(this.uniqueEmpId)).subscribe(
      data => {
        // console.log(data);
        this.grossSalary = data.grossSalary;
        this.maxAmtForAdv = Number(this.NoTimesForAdvance * this.grossSalary);
        console.log("grossSalary", this.grossSalary);
        console.log("NoTimesForAdvance", this.NoTimesForAdvance);
        console.log("maxAmtForAdv", this.maxAmtForAdv);
        console.log("interestRate", this.interestRate);
        console.log("salaryHeadId", this.salaryHeadId);
        console.log("salaryDetailId", this.salaryDetailId);
        // called here because it needs resp of getSalaryAdvanceSettings method
      }, error => {
        console.log(error);
      }
    );
  }

  validate(): any {
    if (this.interestRate == undefined || this.interestRate == null || this.salaryHeadId == undefined || this.salaryHeadId == 0
      || this.salaryDetailId == undefined || this.salaryDetailId == 0 || this.NoTimesForAdvance == undefined
      || this.NoTimesForAdvance == null || this.NoTimesForAdvance == 0) {
      // this.alertmessages("Salary advance setting is not updated.", "danger", '.alertcomp');
      return false;
    }
    if (this.totalAmount == null || this.totalAmount == undefined) {
      return false;
    }

    if (this.date_of_loan == null || this.date_of_loan == undefined) {
      return false;
    }

    if (this.reason == null || this.reason == undefined) {
      return false;
    }
    return true;
  }

  submit() {

    // console.log(this.salaryAdvanceForm);
    this.submitted = true;

    // if (this.model.installmentComp && this.model.installmentComp.length === 0) {
    //   this.alertmessages("Please verify the installments.", "danger", '.alertcomp');
    //   return false;
    // }
    if (this.totalAmount > 0 && this.date_of_loan) {
      this.setSalaryAdvanceModel();
    }

    if (this.validate()) {

      this.salaryAdvanceService.saveSalaryAdvanceAppy(this.model).subscribe(
        data => {
          console.log("Saved data : ", data);
          this.getAdvApplyHistory();
          // this.initialize();
          this.clear();
          this.clearForm();
          this.submitted = false;
          this.alertmessages("Applied successfully.", "success", '.alertcomp');
        }, error => {

        });
    }
  }

  update() {
    // if (this.fieldChanged) {
    //   this.alertmessages("Please verify the installments.", "danger", '.alertcomp');
    //   return false;
    // }

    // console.log(this.salaryAdvanceForm);
    this.submitted = true;
    if (this.validate()) {

      if (!this.isButtonClick) {
        if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan) {
          this.setSalaryAdvanceModel();
        }
      }

      this.salaryAdvanceService.updateSalaryAdvanceAppy(this.model).subscribe(
        data => {

          this.getAdvApplyHistory();
          this.clear();
          this.clearForm();
          this.submitted = false;
          this.alertmessages("Updated successfully.", "success", '.alertcomp');

        }, error => {

        });
    }
  }

  editSalaryAdvance(salAdvobj: any) {
    this.isEdit = true;
    this.isOnlyView = false;
    this.totalAmount = salAdvobj.totalAmount;
    this.noOfInstallment = salAdvobj.noOfInstallment;
    // this.Ins_StartDate = salAdvobj.installStartDate
    this.reason = salAdvobj.reason;

    this.date_of_loan = salAdvobj.dateOfLoan;
    this.interMasterId = salAdvobj.interMasterId;

    // this.salaryAdvanceService.getapplyAdvInfo(salAdvobj.interMasterId).subscribe(
    //   data => {

    //     this.salaryAdvanceData = data.salaryAdvance;
    //     this.model.interMasterId = data.salaryAdvance.interMasterId;
    //     this.model.installmentComp = this.salaryAdvanceData.installmentComp;
    //     this.installmentComp = this.model.installmentComp;
    //   },
    //   error => {
    //     const response = error.error;
    //     console.log(error);
    //   }
    // );
    $('html,body').animate({
      scrollTop: $(`#alertcomp`).offset().top - 10
    },
      'slow');
  }

  viewSalaryAdvance() {
    this.isOnlyView = true;
    this.submitted = false;
  }

  setSalaryAdvanceModel() {

    this.model.totalAmount = this.totalAmount;
    // this.model.noOfInstallment = this.noOfInstallment;

    this.model.dateOfLoan = this.date_of_loan;
    this.model.uniqueEmpId = this.uniqueEmpId;
    console.log("current Emp Id = ", this.uniqueEmpId);
    this.model.reason = this.reason;
    this.model.salaryHeadId = this.salaryHeadId;
    this.model.salaryDetailId = this.salaryDetailId;
    this.model.interMasterId = this.interMasterId;
    this.model.createdUser = Number(this.sessionservice.getempid());
    this.model.userId = this.sessionservice.getempid();
    this.model.advanceType = "Advance";
  }

  delete(sal) {
    console.log("sal", sal);
    this.salaryAdvanceService.deleteAdvApplieByMid(sal.interMasterId).subscribe(
      data => {

        this.getAdvApplyHistory();
        this.clearForm();
        this.alertmessages("Deleted successfully.", "success", '.alertcomp');
      },
      error => {
        const response = error.error;
        console.log(error);
      }
    );
  }



  // setInstallmentComp() {
  //   console.log("Step 4 Success +++++++++++++++++++++++++++");
  //   let currentDate = new Date(this.Ins_StartDate);
  //   let year = currentDate.getFullYear();
  //   let totalInstallments = this.noOfInstallment;

  //   // Calculate interest amount
  //   let interest: number = (this.totalAmount * totalInstallments * this.interestRate) / (100 * 12);

  //   this.totalAmountToPay = Number(this.totalAmount) + Number(interest);

  //   // Calculate installment amount
  //   let installAmt = (this.totalAmountToPay / totalInstallments).toFixed(2);

  //   // Clear previous installments if needed
  //   this.model.installmentComp = [];
  //   this.installmentComp = [];

  //   for (let i = 0; i < totalInstallments; i++) {
  //     // Calculate new month and year
  //     let installmentDate = new Date(currentDate);
  //     installmentDate.setMonth(currentDate.getMonth() + i); // Increment month for each installment

  //     // Extract year, month, and day from the updated date
  //     year = installmentDate.getFullYear();
  //     let month = (installmentDate.getMonth() + 1).toString().padStart(2, '0');
  //     let day = installmentDate.getDate().toString().padStart(2, '0');

  //     // Format month name if needed (assuming `getMonthName` is defined elsewhere)
  //     let monthName = this.getMonthName(installmentDate.getMonth());

  //     // Add new installment to the list
  //     this.installmentComp.push({
  //       monthName: monthName, // or `${year}-${month}` if you need 'YYYY-MM'
  //       instAmount: installAmt,
  //       paidAmount: '',
  //       // isSalaryGenerated: false,
  //       instDate: `${year}-${month}-${day}` // Format: 'YYYY-MM-DD'
  //     });

  //   }
  // }

  updateInstallmentCompBasedOnInstallmentAmount(index: number, instAmt: number) {
    let totalInstallments = this.noOfInstallment;
    this.installmentComp[index].isUpdated = true;
    // Calculate installment amount
    let totalPaid: number = 0;
    let pendingAmount: number = 0;
    let pendingInstallments: number = 0;
    let salGeneratedCount: number = 0;
    let skipCount: number = 0;

    for (let i = 0; i < this.installmentComp.length; i++) {
      const installment = this.installmentComp[i];

      if (installment.paidAmount != null && installment.paidAmount > 0) {
        totalPaid = totalPaid + Number(installment.paidAmount);
        console.log("installment.paidAmount 1", installment.paidAmount);

      }
      if (installment.isSalaryGenerated || installment.isUpdated) {
        if (installment.isSalaryGenerated) {
          salGeneratedCount++;
        }
        skipCount++;
        totalPaid = totalPaid + Number(installment.instAmount);
        console.log("installment.instAmount 1", installment.instAmount);
      }

      console.log("totalPaid", totalPaid);
    }

    pendingAmount = this.totalAmountToPay - totalPaid; // remaining amount to be paid
    pendingInstallments = totalInstallments - skipCount;// remaining installments to be paid

    console.log("pendingAmount", pendingAmount);
    console.log("salGeneratedCount", skipCount);
    console.log("totalInstallments", totalInstallments);
    console.log("pendingInstallments", pendingInstallments);
    console.log("totalPaid", totalPaid);

    let installAmt = 0.00;
    if (pendingAmount > 0 && pendingInstallments > 0) {
      installAmt = Number((pendingAmount / pendingInstallments).toFixed(2));
    } else {
      return;
    }

    for (let j = salGeneratedCount; j < this.installmentComp.length; j++) {
      if (j != index && !this.installmentComp[j].isUpdated) {
        this.installmentComp[j].instAmount = installAmt;
      }
    }
  }

  // setInsStartDate() {
  //   if (this.date_of_loan) {
  //     // Convert 'yyyy-MM' string to Date object
  //     const date = new Date(this.date_of_loan);// Adding '-01' to form a valid Date

  //     // Add one month to the Date object
  //     date.setMonth(date.getMonth() + 1);

  //     // Format the updated date back to 'yyyy-MM' for the Ins_StartDate input
  //     const day = date.getDate().toString().padStart(2, '0');
  //     const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month
  //     const year = date.getFullYear();

  //     // Set Ins_StartDate in 'yyyy-MM' format
  //     this.Ins_StartDate = `${year}-${month}-${day}`;
  //   }
  // }


  clear() {
    this.totalAmount = null;
    this.noOfInstallment = null;
    this.date_of_loan = null;
    // this.Ins_StartDate = null;
    this.model.installmentComp = [];
    this.installmentComp = [];
    this.isEdit = false;
    this.submitted = false;
    this.isButtonClick = false;
    this.isOnlyView = false;
    this.dateError = false;
    this.fieldChanged = false;
    this.selectedType = 'select';
    this.clearForm();
    // this.salaryAdvanceForm.reset();
  }

  clearForm() {
    if (this.salaryAdvanceForm) {
      console.log("Resetting form", this.salaryAdvanceForm);
      this.salaryAdvanceForm.reset(); // Reset form only after view initialization
    } else {
      console.log("Form is not initialized yet.");
    }
  }

  getMonthName(monthNumber: number): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    return monthNames[monthNumber]; // Subtract 1 because array is 0-based
  }
  readamount(e, item) {
    this.amount = e.target.value;
    console.log(this.amount, "amount and bool")
    if (this.amount >= 0) {
      item.InstAmount = Number(this.amount);
    }
  }

  changeAmount(e, item, i) {
    let amount = e.target.value;

    let sub = this.totalAmount - amount;
    let noi = this.noOfInstallment - 1;
    let rem = (sub / noi).toFixed(2);

  }

	getEmployeeByNameOrId() {
		this.query = this.query.trim();
		console.log("Len ", this.query);
		if (this.query.length <= 2) {
			this.employeeMasterLucene = []
		}
		if (this.query.length >= 3) {

			this.salaryAdvanceService.getEmployeeByNameOrUniqueId(this.query).subscribe(
				data => {
					console.log("Emp Data ", data);
					this.employeeMasterLucene = data.employeeMasterData
					let reducedobj =this.employeeMasterLucene.reduce((prev,curr,i)=>{
						console.log(prev?.name === this.query , prev, curr)
						if(prev?.name===this.query){
							return prev;
						}
					},)
					// console.log("check data and pass id ... " , reducedobj.uniqueemployeeid)
	
				},
				error => {
					console.log("Error ", error);

				}
			)
		}
	}

  modelOk() {
    console.log("this.installmentComp", this.installmentComp);
    const tolerance = 0.10; // Allow a small margin for rounding errors
    const totalInstallmentAmount: number = this.installmentComp.reduce((sum: number, comp) => {
      return sum + parseFloat(comp.instAmount); // Ensuring instAmount is a number
    }, 0);
    console.log("totalAmount", this.totalAmount);
    console.log("totalInstallmentAmount", totalInstallmentAmount);
    if (Math.abs(this.totalAmountToPay - totalInstallmentAmount) < tolerance) {
      console.log("The sum of installment amounts is approximately equal to the total installments.");
      this.model.installmentComp = this.installmentComp;
      $('#calInstallmentModel').modal('hide');
    } else {
      this.clearDiv();
      this.alertmessages("The sum of installment amounts does not match the total amount.", "danger", '.alertmodel');
      console.log("The sum of installment amounts does NOT match the total installments.");
    }

    this.fieldChanged = false;
  }

  alertmessages(message: string, sign: string, alertcomponent: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector(alertcomponent);
    alertcomp.innerHTML = '';
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  clearDiv() {
    const div = document.getElementById('alertmodel');
    if (div) {
      div.innerHTML = ''; // Clears the div content
    }
  }

  validateDates() {
    // if (this.date_of_loan && this.Ins_StartDate) {
    //   const loanDate = this.date_of_loan;
    //   const startDate = this.Ins_StartDate;

    //   if (loanDate >= startDate) {
    //     this.dateError = true; // Flag for error handling
    //   } else {
    //     this.dateError = false;
    //   }
    // }
    // if (this.dateError) {
    //   return false;
    // }
    return true;
  }

  onChange(event) {
    this.selectedType = event.target.value;
    if (this.selectedType === 'Team_Member') {
      // this.viewTeam();

    } else {
      // this.sendId = this.uniqueEmpId;
      // this.teamMemberList = [];
      this.uniqueEmpId = this.sessionservice.getempid();
      this.getGrossSalary();
    }
  }

  onChange1(event) {
    console.log("inside onChange1");
    console.log(event);

    let empdetailobject = this.employeeMasterLucene.map((emp, i) => {
      if (emp.name.trim() === event.trim()) {
        console.log("emp.employeeid",emp.employeeid);
        return emp;
      }
    })

    empdetailobject.forEach(e => {
			if (e && e !== undefined) {
				this.uniqueEmpId = e.uniqueemployeeid;
			}
		})
    console.log("uniqueEmpId = ",this.uniqueEmpId);
    this.getGrossSalary();
  }

  // viewTeam() {

  //   console.log("clicked team member ")
  //   this.salaryAdvanceService.getTeamMemberList(this.uniqueEmpId).subscribe(
  //     data => {

  //       this.teamMemberList = data.teamListSummary;
  //       console.log("team member list ", this.teamMemberList)
  //       // this.onClear()
  //     },
  //     error => {
  //       // this.onClear()
  //       console.log("Error team member list ", error)
  //     }
  //   )
  // }



}
