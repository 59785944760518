<!-- page content -->
<!-- <div class="main-panel">
  <div class="content"> -->
<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp">

    </div>
    <!-- <div class="page-header d-xl-flex d-block"> -->
      <!-- <div class="page-leftheader">
        <div class="page-title">Bulk Upload Documents</div>
      </div> -->
    <!-- </div> -->

    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <form enctype="multipart/form-data" id="fcForm" class="form-horizontal form-label-left" #f="ngForm"
              (ngSubmit)="f.form.valid" novalidate>

              <div class="x_content row p-4">
                <div message="pattern"><span class=" textColors">
                    Step 1: Upload docments of employees in jpeg,png,doc,pdf
                    with file size less than 10 MB
                  </span>
                </div>
                <!--<span>Step 1: Upload docments of employees in jpeg,png,doc,pdf with file size less than 10 MB</span>-->
                <!-- Added Starts -->
                <div style="padding: 15px;">
                  <div>
                    <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
                      <div class="text-wrapper bgcolors">
                        <div class="centered">
                          <input type="file" name="file" id="file" class="dropzone-file"
                            (change)="onFileChange($event.target.files)" multiple />
                          <label for="file" style="cursor: pointer;"><span class="textLink">Select your file</span> or
                            Drop it
                            here!</label>

                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <mat-list>
                      <h3 mat-subheader>Files</h3>
                      <mat-list-item *ngFor="let file of files">
                        <mat-icon mat-list-icon>folder</mat-icon>
                        <h4 mat-line>{{file.name}}</h4>
                        <button mat-raised-button color="warn" (click)="deleteFile(file)">Delete</button>
                        <mat-divider></mat-divider>
                      </mat-list-item>
                    </mat-list>

                  </div>
                  <button class="btn" (click)="uploadImage()"> Upload Documents </button>
                  <!--<button mat-raised-button style="width: 150px" type="button" (click)="uploadImage()">Upload Documents</button>-->
                  <p *ngIf="!fileslength" class="help-block" style="color: red;">
                    No Documents to Upload</p>
                </div>

                <!-- Started Ends -->
              </div>
              <div class="actionBar">
                <div class="clearfix"> </div>
                <div class="pro-fab">
                </div>
              </div>

            </form>
          </div>

        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body p-5">
            <div class="col-xs-12 mobile">
              <div message="pattern"><span class=" textColors">
                  Step 2: Upload excel with uploaded document details.
                  The columns should be as follows. empId, branchId, docGroup, docType, expiryDate, filePath
                </span>
              </div>

              <div>
                <label for="form-label" class="form-label">Select File</label>
                <input type="file" id="file" class="form-control" name="myFilename" style="box-sizing: border-box;"
                  [(ngModel)]="excelDoc.file" #file="ngModel" required="required" enctype="multipart/form-data"
                  (change)="handleFileInput($event)" />

                <p *ngIf="submitted && !file.valid " class="help-block" style="color: red;">
                  Please Choose File</p>
              </div>
              <button type="button" class="btn w-100 w-md-20 mt-2" (click)="uploadImage()" (click)="uploadFile()">Upload
                File</button>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="showAlert" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">
          <div class="modal-header">
            <h4 class="modal-title">Uploaded Document Details</h4>
          </div>

          <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
            <!-- auto scroll -->
            <ul>
              <li *ngFor="let docDetails of uploadedDocDetails">
                <a> {{docDetails.comments}}</a>
              </li>
            </ul>
            <!--<span><b> {{strMessage}}</b></span>-->
            <button (click)="downloadCSV()">Download CSV</button>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-danger medium" (click)="closeModal()">OK</button>

          </div>

        </div>
      </div>
    </div>

