<div class="app-content main-content">
    <div class="side-app main-container" style="margin-top:-15px">

        <div class="alertcomp"></div>

        <div class="col-xl-12 col-md-12 col-lg-12" style="padding: 0;">
            <!-- <div class="card"> -->

            <div class="card-body row justify-content-between">
                <div class="col-md-9 col-sm-9 col-xs-12" style="padding: 0;">

                    <div class="my-3">

                        <!-- <div class="page-leftheader">
                            <div class="page-title">Client Locations</div>
                        </div> -->

                    </div>
                </div>

                <div class="col-md-3 col-sm-3 col-xs-3 my-3" style="padding: 0;">
                    <button class="btn btn-primary w-100 disable-div" id="navigateclientLoc" (click)="clearTwo()">
                        Add New Location
                    </button>
                </div>


            </div>
            <!-- </div> -->
        </div>

        <div class="col-xl-12 col-md-12 col-lg-12" style="padding: 0; margin-bottom: 30px;">
            <div class="card">

                <div class="card-body row justify-content-between">
                    <div class="col-xs-11 col-md-11 col-sm-10 col-10">

                        <div class="my-3">

                            <select id="employeeMasterLucene" class="form-control" name="Employee Name"
                                id="employeename" placeholder="Search for Client"
                                [(ngModel)]="contractClientDataForm.business_name" autocomplete="off"
                                list="employeeMasterLucene" autofocus="autofocus"
                                (ngModelChange)="getclientDetail($event,contractClientDataForm)">
                                <option data-value={{emp.business_name}} *ngFor="let emp of contractClientData">
                                    {{emp.business_name}}
                                </option>
                            </select>

                        </div>
                    </div>

                    <div class="col-xs-1 col-md-1 col-sm-2 col-2 my-3">
                        <button type="button" class="btn btn-primary w-100" (click)="getclientDetailCall()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>


                </div>
            </div>
        </div>

        <div class="row" *ngIf="contractclientlocationlist.length">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">{{selectedBusiness}} location details</h4>
                    </div>
                    <div class="card-body">

                        <form id="contractclientsList" name="contractclientsList" type="multipart/form-data">
                            <div class="col-xs-12 mobile">

                                <div class="scrollsearchresults">
                                    <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class="rowcolors">
                                                <th class="border-bottom-0 w-5 fs-6">Location Name</th>
                                                <th class="border-bottom-0 w-5 fs-6">City</th>
                                                <th class="border-bottom-0 w-5 fs-6">State</th>
                                                <th class="border-bottom-0 w-5 fs-6">Contact Person</th>
                                                <th class="border-bottom-0 w-5 fs-6">Contact Number</th>
                                                <th class="border-bottom-0 w-5 fs-6">type</th>
                                                <th class="border-bottom-0 w-5 fs-6">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let contractclient of contractclientlocationlist">
                                                <td data-th="contractclientName">{{contractclient.location_name}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.city}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.state}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.contact_person}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.phone}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.location_type}}
                                                </td>

                                                <td>
                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="contractclientLocEdit(contractclient)"
                                                        data-method="edit" title="Edit" id="navigateclientLocTwo">
                                                        <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                            data-original-title="edit"></i>
                                                    </button>

                                                    <button class="btn btn-danger btn-icon btn-sm me-2"
                                                        (click)="contractclientLocDeleteDataStore(contractclient)"
                                                        data-method="delete" title="Delete">
                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                            data-original-title="edit"></i>
                                                    </button>
                                                    <!-- <input type='button' class="btn btn-warning mt-2" value='Warning alert' id='click1'> -->
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="empty-div-one" style="height: 10px;" id="add-update-client-location"></div>

        <div class="page-leftheader" style="margin-bottom: 0.75rem">
            <div class="page-title"><span id="alter-text">Add</span> {{selectedBusiness}} location details</div>
        </div>

        <div class="row disable-div" id="mainInputConCliLoc">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content">
                        <form id="conCliLocForm" (ngSubmit)="contractClientLocForm.form.valid"
                            name="addContractClientLocForm" #contractClientLocForm="ngForm" novalidate>

                            <section>
                                <div class="card-body">

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Contact Person Name</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Person Name"
                                                        autofocus="true" name="contact_person" #contact_person="ngModel"
                                                        [(ngModel)]="contractClientDataForm.contact_person"
                                                        id="contact_person" required />

                                                    <p *ngIf="submitted && !contact_person.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Contact Person name is required
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Contact Number</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Contact Number"
                                                        maxlength="10" autofocus="true" name="phone" #phone="ngModel"
                                                        [(ngModel)]="contractClientDataForm.phone" required />

                                                    <p *ngIf="submitted && !phone.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Contact number is required
                                                    </p>
                                                    <p *ngIf="contactNumberlength" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Enter 10 digit number
                                                    </p>
                                                    <p *ngIf="contactNumberOnly" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Enter only number
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">GST Number</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter GST Number"
                                                        autofocus="true" name="gstin" #gstin="ngModel"
                                                        [(ngModel)]="contractClientDataForm.gstin" />

                                                    <!-- <p *ngIf="submitted && !gstin.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ GSTIN number is required
                                                    </p> -->

                                                    <p *ngIf="gstinNumber" class="help-block " style="color: #ff5757;">
                                                        ⓘ Enter correct GSTIN number
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Location Name</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Location Name"
                                                        autofocus="true" name="location_name" #location_name="ngModel"
                                                        [(ngModel)]="contractClientDataForm.location_name" required />

                                                    <p *ngIf="submitted && !location_name.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Location name is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Address 1</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Address Line 1"
                                                        autofocus="true" name="address1" #address1="ngModel"
                                                        [(ngModel)]="contractClientDataForm.address1" required />

                                                    <p *ngIf="submitted && !address1.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Address line one is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Address 2</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Address Line 2"
                                                        autofocus="true" name="address2" #address2="ngModel"
                                                        [(ngModel)]="contractClientDataForm.address2" required />

                                                    <p *ngIf="submitted && !address2.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Address line two is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Business City</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter City" autofocus="true"
                                                        name="city" #city="ngModel"
                                                        [(ngModel)]="contractClientDataForm.city" required />

                                                    <p *ngIf="submitted && !city.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ City name is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">State</label>
                                                <div>
                                                    <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                        id="dGroup" name="state" #state="ngModel"
                                                        [(ngModel)]="contractClientDataForm.state" required>

                                                        <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                            Select State</option>
                                                        <option [ngValue]="data" *ngFor="let data of indian_states">
                                                            {{data}}
                                                        </option>

                                                    </select>

                                                    <p *ngIf="submitted && !state.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ State is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="col-md-12 col-sm-12 col-xs-12 pe-4 ps-4">
                                        <div class="form-group label-floating">
                                            <label class="form-label">PIN Code</label>
                                            <div>
                                                <input type="text" class="form-control col-md-12 col-xs-12"
                                                    autocomplete="off" placeholder="Enter PIN Code" autofocus="true"
                                                    maxlength="6" name="pincode" #pincode="ngModel"
                                                    [(ngModel)]="contractClientDataForm.pincode" required />

                                                <p *ngIf="submitted && !pincode.valid" class="help-block "
                                                    style="color: #ff5757;">
                                                    ⓘ PIN code is required
                                                </p>
                                                <p *ngIf="pinCodelength" class="help-block " style="color: #ff5757;">
                                                    ⓘ Enter 6 digit PIN code
                                                </p>
                                                <p *ngIf="pinCodeOnly" class="help-block " style="color: #ff5757;">
                                                    ⓘ Enter only number
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="actionBar pe-4 ps-4">
                                        <div class="clearfix"> </div>
                                        <div>
                                            <button class="btn btn-success" type="button" id="save-btn" style="display:
                                            inline-block"
                                                (click)="saveContractClientData(contractClientLocForm.form.valid)">Save</button>
                                            <button class="btn btn-success" type="button" id="update-btn"
                                                style="display: none"
                                                (click)="updateContractClientData(contractClientLocForm.form.valid)">Update</button>
                                            <button class="btn btn-danger ms-2" type="button"
                                                (click)="clearTwo()">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="clearfix"> </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="actionBar">

            <div class="clearfix"> </div>
            <div class="pro-fab">
                <button class="btn btn-success" type="button" id="save-btn" style="display: inline-block"
                    (click)="saveContractClientData()">Save</button>
                <button class="btn btn-success" type="button" id="update-btn" style="display: none"
                    (click)="updateContractClientData()">Update</button>
                <button class="btn btn-danger ms-2" type="button" (click)="clear()">Clear</button>
            </div>
        </div> -->
    </div>
</div>

<div id="showAlertPrimary" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-x-circle fs-80 text-danger lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">The data can't be deleted!</h4>
                <p class="mg-b-20 mg-x-20">This record contains praimary location details!</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertWarning" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-alert-circle fs-80 text-warning lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger mb-3">Are you sure you want to delete this data?</h4>
                <!-- <p class="mg-b-20 mg-x-20">This record has data attached to it! it can't be deleted.</p> -->

                <button aria-label="Close" class="btn btn-danger pd-x-25 mx-1" data-bs-dismiss="modal"
                    (click)="contractclientLocDeleteData()">Delete</button>
                <button aria-label="Close" class="btn btn-primary pd-x-25 mx-1" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertDataAttachedToLoc" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-link fs-80 text-danger lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">The location can't be deleted!</h4>
                <p class="mg-b-20 mg-x-20">This location has data attached to it! it can't be deleted.</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantDelete" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-x-circle fs-80 text-danger lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">The data can't be deleted!</h4>
                <p class="mg-b-20 mg-x-20">This record couldn't be deleted! Please try again.</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>