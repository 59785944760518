<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>

        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Generate Salary</div>
            </div>
        </div> -->


        <div class="row">
            <div class="col-md-12">
                <div class="card-content">
                    <form #generateSalary="ngForm">
                        <section>
                            <div class="card">
                                <div class="card-body">

                                    <div class=" row col-md-12 col-sm-12 col-xs-12">

                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Select Month</label>
                                                <div>
                                                    <input class="form-control col-md-12 col-sm-12 col-xs-12"
                                                        (change)="onGenerateChange($event.target.value)" type="month"
                                                        name="month" autocomplete="off" autofocus="true" required
                                                        [(ngModel)]="months">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Select Employee</label>

                                                <select id="employee" class="form-control" name="employee"
                                                    placeholder="Select Employee" autocomplete="off"
                                                    autofocus="autofocus" [(ngModel)]="employeeid"
                                                    (change)="onGenerateChange($event.target.value)">

                                                    <option value="all" selected="selected">All</option>
                                                    <option value={{data.uniqueemployeeid}}
                                                        *ngFor="let data of activeEmployeeList">
                                                        {{data.name}}
                                                    </option>
                                                </select>

                                            </div>
                                        </div>

                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Select Branch</label>


                                                <select id="branch" class="form-control" name="branch"
                                                    placeholder="Select Branch" autocomplete="off" autofocus="autofocus"
                                                    [(ngModel)]="branch"
                                                    (change)="onGenerateChange($event.target.value)">

                                                    <option value="all" selected="selected">All</option>
                                                    <option value={{data.branchId}} *ngFor="let data of branchInfo">
                                                        {{data.branchname}}
                                                    </option>
                                                </select>


                                            </div>
                                        </div>

                                    </div>

                                    <div class="row col-md-12 col-sm-12 col-xs-12">
                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                            <button type="button" class="btn btn-primary w-100" id="generateSalary"
                                                (click)="generateSalarySlip()">
                                                Generate Salary
                                            </button>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                            <button type="button" class="btn btn-primary w-100" id="fetchSalary"
                                                (click)="fetchSalary()">
                                                Fetch Salary Details
                                            </button>
                                        </div>
                                        <!-- <div class="col-md-3 col-sm-3 col-xs-3">
                                            <button type="button" class="btn btn-primary w-100" id="voidSalary"
                                                (click)="voidSalary()">
                                                Void Salary
                                            </button>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                            <button type="button" class="btn btn-primary w-100" id="downloadSalary"
                                                (click)="generateSalaryDownload()">
                                                Generate Salary Slip
                                            </button>
                                        </div> -->
                                    </div>
                                    <div class="clearfix"> </div>
                                </div>
                            </div>

                        </section>

                        <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>
                        <div class="card">
                            <div class="card-body">
                                <div class="col-xs-12  mobile view">
                                    <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                        [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                        [scrollWindow]="false" id="scrolllength">
                                        <table
                                            class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                            id="hr-table">
                                            <thead class="tablerow">
                                                <tr class=" stickyatTop rowcolors">
                                                    <th>Employee ID</th>
                                                    <th>Employee Name</th>
                                                    <th>Date Of Joining</th>
                                                    <th>Account Number</th>
                                                    <!-- <th>Staff Categories</th> -->
                                                    <th>Payroll Type</th>
                                                    <th class="text-center" *ngFor="let sdd of salaryDetail">
                                                        <span>{{sdd.salaryDetailName}}</span>
                                                    </th>
                                                    <th>Pay Days</th>
                                                    <th>No Of Days</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let empInfo of fetchSalaryData ">
                                                    <tr *ngIf="empInfo.salaryDetail && empInfo.salaryDetail.length > 0">
                                                        <td>{{empInfo.employee_id}}</td>
                                                        <td>{{empInfo.employee_name}}</td>
                                                        <td>{{ empInfo.doj | date: 'shortDate' }}</td>
                                                        <td>{{empInfo.account_number}}</td>
                                                        <td>{{empInfo.payroll_type}}</td>
                                                        <td class="text-center" *ngFor="let sd of empInfo.salaryDetail">
                                                            <span>{{ sd.salaryamount == '-1' ? '0' : (sd.salaryamount |
                                                                currency: 'INR':'symbol':'1.0-0') }}</span>
                                                        </td>
                                                        <td>{{empInfo.pay_days}}</td>
                                                        <td>{{empInfo.noofdays}}</td>
                                                        <td>{{empInfo.total |
                                                            currency: 'INR':'symbol':'1.0-0' }}</td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>