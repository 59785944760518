<div class="app-content main-content">
  <div class="side-app main-container">    
    <div class="alertcomp">
    
    </div>
    <!-- <div class="page-header d-xl-flex d-block">
      <div class="page-leftheader">
          <div class="page-title">Policy Rules</div>
      </div>
     </div> -->

     <!-- <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <form id="fcForm" (ngSubmit)="f.form.valid"  enctype = "multipart/form-data" class="form-horizontal form-label-left" #f="ngForm"
              novalidate>
              <div class="x_content row p-1">
                  
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <div class="form-group label-floating">
                      <label class="form-label"> Policy Document Name </label>
                      <div>
                        <input type="text" class="form-control col-md-12 col-xs-12" [(ngModel)]="orgFiles.fileType" autocomplete="off"
                          name="fileType" #fileType="ngModel" value="" 
                          placeholder="Enter Policy Document Name" required="required" autofocus="true" id="documentType" />
                        <span *ngIf="submitted && !fileType.valid " class="help-block" style="color: red;">
                          Please Enter Policy Document Name</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-7 col-xs-12">
                    <div class="form-group">
                      <label for="form-label" class="form-label">Choose File</label>
                      <input type="file" class="form-control filein"    required="required" accept="application/pdf,application/vnd.ms-excel,image/x-png,image/gif,image/jpeg"  
                         enctype="multipart/form-data" (change)="handleFileInput($event)" [(ngModel)]="uploadRulePolicy.file"
                         #file="ngModel"  />
                    </div>
                  </div> -->

                  <!-- have removed ng modal and #file  -->
                  <!-- <div class="col-md-2 col-sm-4 col-xs-6">
                    <div class="custom-file">
                      <label class="form-group label-floating"> Choose File </label>
                      <div style="padding-top: 10px;">
                        <i style="cursor: pointer;" class = "pull-right" (click)="clearSelection()">x</i>
                        <input type="file" id="file" class="custom-file-input" name="myFilename"
                          style="box-sizing: border-box; width: 175px; border: 0;" [(ngModel)]="uploadRulePolicy.file"
                          #file="ngModel" required="required" accept="application/pdf,application/vnd.ms-excel,image/x-png,image/gif,image/jpeg" enctype="multipart/form-data"
                          (change)="handleFileInput($event)" />
                        <p *ngIf="submitted && !file.valid " class="help-block" style="color: red;">
                          Please Choose File</p>
                      </div>
                    </div>
                  </div> -->

                <!-- </div>

                <div class="pro-fab">
                  <button class="btn btn-success"  type="button" (click)="save(f.form.valid)">Save</button>
                  <button class="btn btn-danger ms-2" type="button" (click)="reFresh()">Clear</button>
                </div>
                

              </form>
             </div> 
          </div>   
      </div> 
     </div> -->
     <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
          <div class="card">
              <div class="card-header  border-0">
                  <h4 class="card-title">policyList</h4>
              </div>
              <div class="card-body">
                  <div class="col-xs-12 mobile">
                      <div *ngIf="showPolicyList !== null ">
                      <div class="scrollsearchresults" >
                      <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                          <thead class="tablerow">
                              <tr class="rowcolors stickyatTop">
                                  <!--<th class="border-bottom-0 w-5 fs-6">File Group</th>-->
                                  <th class="border-bottom-0 fs-7">File Type</th>
                                  <th class="border-bottom-0 fs-7">File Name</th>
                                  <th class="border-bottom-0 w-10 fs-7">Uploaded Date</th>
                                  <th class="border-bottom-0 fs-7">Uploaded By</th>
                                  <!--<th class="border-bottom-0 fs-7">Comments</th>-->
                                  <th class="border-bottom-0 fs-7">Status</th>
                                  <th class="">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let j=index; let t of listOfRuleAndPolicy ">
                                <!--<td data-th="Doc Group">{{t.docGroup}}</td>
                                -->
                                <td data-th="File Type">{{t.fileType}}</td>
                                <td data-th="FileName">{{t.fileName}}</td>
                                <td data-th="Upoaded Date">{{t.uploadedOn | date: 'dd/MM/yyyy'}}</td>
                                <td data-th="Uploaded By">{{t.uploadedBy}}</td>
                                <!--<td data-th="Comments">{{t.comments}}
                                </td>-->
                                <td data-th="Status">{{t.overAllStatus}}</td>
                                <td>
                                    <ng-container ngSwitch="{{t.overAllStatus}}">
                                      <button  *ngSwitchCase="'Submitted'"  class="btn btn-danger btn-icon btn-sm" data-bs-toggle="tooltip" data-method="delete" (click)="policyDelete(j, t.id)" data-original-title="Delete"><i class="feather feather-trash-2"></i></button>

                                      <button  *ngSwitchCase="'Rejected'"  class="btn btn-danger btn-icon btn-sm" data-method="delete" data-bs-toggle="tooltip" (click)="policyDelete(j, t.id)" data-original-title="Delete"><i class="feather feather-trash-2"></i></button>


                                    </ng-container>
                                </td>



                                <!-- <td  ngSwitch="{{t.overAllStatus}}">
                                  <button  *ngSwitchCase="'Submitted'" type="button" class="btn btn-danger btn-icon btn-sm" data-method="delete" 
                                    title="Delete" (click)="myDocumentDelete(j, t.id)">
                                    <span class="docs-tooltip" data-toggle="tooltip">
                                      <span class="feather feather-trash-2"></span>
                                    </span>
                                  </button>
                                  <button  *ngSwitchCase="'Rejected'" type="button" class="btn btn-danger btn-icon btn-sm" data-method="delete" 
                                  title="Delete" (click)="myDocumentDelete(j, t.id)">
                                  <span class="docs-tooltip" data-toggle="tooltip">
                                    <span class="feather feather-trash-2"></span>
                                  </span>
                                </button>
                                </td> -->
                              </tr>
                           </tbody>
                      </table>
                      </div>
                    </div>  
                  </div>
              </div>
          </div>
      </div>
  </div>

     <div id="showAlert" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeMRQModal()">×</button>
            <h4 class="modal-title">Warning</h4>
          </div>
    
          <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
            <!-- auto scroll -->
            <span><b> Policy will be deleted permanently. Are you sure you want to continue ?</b></span>
          </div>
    
          <div class="modal-footer">
            <button type="button" class="btn btn-danger medium" (click)="confirmPolicyDelete()">OK</button>
            <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
    
          </div>
    
        </div>
      </div>
    </div>
     
     

    <!-- <div class="container-fluid" role="main">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-content">
              <form id="fcForm" (ngSubmit)="f.form.valid"  enctype = "multipart/form-data" class="form-horizontal form-label-left" #f="ngForm"
                novalidate>
                <div class="">
                  <div class="x_content">
                    <div class="x_title">
                      <h3>Upload Rules And Policy</h3>
                      <div class="clearfix"></div>
                    </div> -->
                    <!--<div class="col-md-4 col-sm-6 col-xs-12">-->
                      <!--[(ngModel)]="myDocuments.docGroup"-->
                      <!--
                      <div class="col-md-2 col-sm-4 col-xs-6">
                      <div class="form-group label-floating">
                        <label> File Name </label>
                        <div>
                          <input type="text" class="form-control col-md-7 col-xs-12" [(ngModel)]="orgFiles.filName"
                            name="filName" #filName="ngModel" value="" 
                            placeholder="Enter File Name" required="required" autofocus="true" id="documentName" />
                          <span *ngIf="submitted && !filName.valid " class="help-block" style="color: red;">
                            Please Enter File Name</span>
                        </div>
                      </div>
                    </div>
                  -->
                    
                    <!-- <div class="col-md-2 col-sm-4 col-xs-6">
                      <div class="form-group label-floating">
                        <label> File Type </label>
                        <div>
                          <input type="text" class="form-control col-md-7 col-xs-12" [(ngModel)]="orgFiles.fileType"
                            name="fileType" #fileType="ngModel" value="" 
                            placeholder="Enter File Type" required="required" autofocus="true" id="documentType" />
                          <span *ngIf="submitted && !fileType.valid " class="help-block" style="color: red;">
                            Please Enter File Type</span>
                        </div>
                      </div>
                    </div>
                  
                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <div class="custom-file">
                        <label class="form-group label-floating"> Choose File </label>
                        <div style="padding-top: 10px;">
                          <i style="cursor: pointer;" class = "pull-right" (click)="clearSelection()">x</i>
                          <input type="file" id="file" class="custom-file-input" name="myFilename"
                            style="box-sizing: border-box; width: 175px; border: 0;" [(ngModel)]="uploadRulePolicy.file"
                            #file="ngModel" required="required" accept="application/pdf,application/vnd.ms-excel,image/x-png,image/gif,image/jpeg" enctype="multipart/form-data"
                            (change)="handleFileInput($event)" />
                          <p *ngIf="submitted && !file.valid " class="help-block" style="color: red;">
                            Please Choose File</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="actionBar">
                  <div class="clearfix"> </div>
                  <div class="pro-fab">
                    <button class="btn btn-success"  type="button" (click)="save(f.form.valid)">Save</button>
                    <button class="btn btn-danger" type="button" (click)="reFresh()">Clear</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>