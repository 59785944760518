<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp">

    </div>
    <!-- <div class="page-header d-xl-flex d-block ">
      <div class="page-leftheader">
        <div class="page-title">Department Details</div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <form id="fcForm" class="form-horizontal form-label-left" novalidate>
              <div class="x_content row p-1">

                <div class="col-md-5 col-sm-12 col-xs-12">
                  <div class="form-group label-floating">
                    <label class="form-label"> Department Name </label>

                    <input type="text" (input)="selectDepartment($event)" class="form-control col-md-12 col-xs-12"
                      placeholder="Enter Department Name" autocomplete="off" required="required" autofocus="true"
                      id="textType" />
                  </div>
                </div>
              </div>

              <div class="actionBar">
                <div class="clearfix"> </div>
                <div class="pro-fab">
                  <button class="btn btn-success" type="button" (click)="saveDepartment()">Submit</button>
                  <button class="btn btn-danger ms-2" type="button" (click)="searchDepartment()">Search</button>
                  <button class="btn btn-danger ms-2" type="button" (click)="reFresh()">Clear</button>
                </div>
              </div>
              <!-- (keyup.enter)="limitpara()" -->
              <!-- <div *ngIf="list!=null"> -->
              <div class="col-xs-12 mobile">
                <div class="scrollsearchresults">
                  <!-- infiniteScroll
                    [infiniteScrollDistance]="2"
                      [infiniteScrollUpDistance]="1.5"
                      [infiniteScrollThrottle]="100"
                      [scrollWindow]="false"
                    (scrolled)="onScroll()" -->

                  <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                    <thead class="tablerow">
                      <tr class="rowcolors stickyatTop">
                        <!-- <th class="border-bottom-0 fs-6">Department ID</th> -->
                        <th class="border-bottom-0 fs-7">Department Name </th>
                        <th class="border-bottom-0 fs-7">Created on </th>
                        <th class="border-bottom-0 fs-7">Created by </th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngFor="let item of list">
                        <!-- <td data-th = "Department ID">
                                   {{item.departmentId }}
                                 </td> -->
                        <td data-th="Department name">
                          {{item.name}}
                        </td>
                        <td data-th="Time Stamp">
                          {{item.timestamp | date}}
                        </td>
                        <td data-th="User ID">
                          {{item.userId}}
                        </td>

                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>