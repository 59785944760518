<div class="app-content main-content">
    <div class="side-app main-container" style="margin-top:-15px">

        <div class="alertcomp"></div>

        <div class="w-100" style="padding: 0;">

            <div class="card-body row justify-content-between">
                <!-- <div class="col-xs-9 col-md-9 col-sm-9 col-8 " style="padding: 0;">

                    <div class="my-3"> -->

                        <!-- <div class="page-leftheader">
                            <div class="page-title">Contract Client</div>
                        </div> -->

                    <!-- </div>
                </div> -->

                <div class="col-xs-3 col-md-3 col-sm-3 col-4 my-3" style="padding: 0;">
                    <button class="btn btn-primary w-100" id="navigateclient" (click)="clear()">
                        Add New Client
                    </button>
                </div>


            </div>
        </div>

        <div class="col-xl-12 col-md-12 col-lg-12" style="padding: 0; margin-bottom: 30px;">
            <div class="card">

                <div class="card-body row justify-content-between">
                    <div class="col-xs-11 col-md-11 col-sm-10 col-10">

                        <div class="my-3">

                            <select id="employeeMasterLucene" class="form-control" name="Employee Name"
                                id="employeename" placeholder="Search for Client" [(ngModel)]="searchBusinessName"
                                autocomplete="off" list="employeeMasterLucene" autofocus="autofocus">
                                <option data-value={{emp.business_name}} *ngFor="let emp of displayContractClientData">
                                    {{emp.business_name}}
                                </option>
                            </select>

                        </div>
                    </div>

                    <div class="col-xs-1 col-md-1 col-sm-2 col-2 my-3">
                        <button type="button" class="btn btn-primary w-100" (click)="getDataFromSearch()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>


                </div>
            </div>
        </div>

        <div class="row" *ngIf="displayContractClientData.length">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Contract Client Details</h4>
                    </div>
                    <div class="card-body">

                        <form id="contractClientsList" name="contractClientsList" type="multipart/form-data">
                            <div class="col-xs-12 mobile">

                                <div class="scrollsearchresults" id="scrolllength">
                                    <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class="rowcolors stickyatTop">
                                                <th class="border-bottom-0 w-5 fs-6">Client</th>
                                                <th class="border-bottom-0 w-5 fs-6">Location Name</th>
                                                <th class="border-bottom-0 w-5 fs-6">City</th>
                                                <th class="border-bottom-0 w-5 fs-6">State</th>
                                                <th class="border-bottom-0 w-5 fs-6">Contact Person</th>
                                                <th class="border-bottom-0 w-5 fs-6">Billing Type</th>
                                                <th class="border-bottom-0 w-5 fs-6">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let contractClient of displayContractClientData">
                                                <td data-th="contractclientName">{{contractClient.business_name}}
                                                </td>
                                                <td data-th="contractclientName">{{contractClient.location_name}}
                                                </td>
                                                <td data-th="contractclientName">{{contractClient.city}}
                                                </td>
                                                <td data-th="contractclientName">{{contractClient.state}}
                                                </td>
                                                <td data-th="contractclientName">{{contractClient.contact_person}}
                                                </td>
                                                <td data-th="contractclientName">{{contractClient.billing_name}}
                                                </td>
                                                <td>
                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="contractClientEdit(contractClient)" data-method="edit"
                                                        title="Edit">
                                                        <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                            data-original-title="edit"></i>
                                                    </button>


                                                    <button class="btn btn-danger btn-icon btn-sm me-2"
                                                        (click)="deleteContractClientDataStore(contractClient.id)"
                                                        data-method="delete" title="Delete"
                                                        data-bs-target="#showAlertWarning">
                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                            data-original-title="edit"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- (scroll)="scrollHandler($event)" -->
        <div class="empty-div-one" style="height: 10px;" id="add-update-client"></div>

        <div class="page-leftheader" style="margin-bottom: 0.75rem">
            <div class="page-title"><span id="alter-text">Add</span> Contract Client</div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content">

                        <form id="conCliForm" (ngSubmit)="contractClientForm.form.valid" name="addContractClientForm"
                            #contractClientForm="ngForm" novalidate>
                            <!-- {{contractClientForm.value | json}} -->
                            <section>
                                <div class="card-body">

                                    <div class="col-md-12 col-sm-12 col-xs-12 pe-4 ps-4">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Client Name</label>
                                            <div>
                                                <input type="text" class="form-control col-md-12 col-xs-12"
                                                    autocomplete="off" placeholder="Enter Client Name"
                                                    name="business_name" autofocus="true" #business_name="ngModel"
                                                    [(ngModel)]="contractClientData.business_name" required />

                                                <p *ngIf="submitted && !business_name.valid" class="help-block "
                                                    style="color: #ff5757;">
                                                    ⓘ Client name is required
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Contact Person Name</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Person Name"
                                                        name="contact_person" autofocus="true" #contact_person="ngModel"
                                                        [(ngModel)]="contractClientData.contact_person" required />

                                                    <p *ngIf="submitted && !contact_person.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Contact Person name is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Contact Number</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Contact Number"
                                                        maxlength="10" autofocus="true" name="phone" #phone="ngModel"
                                                        [(ngModel)]="contractClientData.phone" required />

                                                    <p *ngIf="submitted && !phone.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Contact number is required
                                                    </p>
                                                    <p *ngIf="contactNumberlength" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Enter 10 digit number
                                                    </p>
                                                    <p *ngIf="contactNumberOnly" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Enter only number
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">GST Number</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter GSTIN Number"
                                                        autofocus="true" name="gstin" #gstin="ngModel"
                                                        [(ngModel)]="contractClientData.gstin" />

                                                    <!-- <p *ngIf="submitted && !gstin.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ GSTIN number is required
                                                    </p> -->
                                                    <!-- {{gstinNumber ? '' : 'd-none' }} -->
                                                    <p *ngIf="gstinNumber" class="help-block " style="color: #ff5757;">
                                                        ⓘ Enter correct GSTIN number
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Location Name</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Location Name"
                                                        autofocus="true" name="location_name" #location_name="ngModel"
                                                        [(ngModel)]="contractClientData.location_name" required />

                                                    <p *ngIf="submitted && !location_name.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Location name is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Address 1</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Address Line 1"
                                                        autofocus="true" name="address1" #address1="ngModel"
                                                        [(ngModel)]="contractClientData.address1" required />

                                                    <p *ngIf="submitted && !address1.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Address line one is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Address 2</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Address Line 2"
                                                        autofocus="true" name="address2" #address2="ngModel"
                                                        [(ngModel)]="contractClientData.address2" required />

                                                    <p *ngIf="submitted && !address2.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Address line two is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Business City</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter City Name"
                                                        autofocus="true" name="city" #city="ngModel"
                                                        [(ngModel)]="contractClientData.city" required />

                                                    <p *ngIf="submitted && !city.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ City name is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">State</label>
                                                <div>
                                                    <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                        id="dGroup" name="state" #state="ngModel"
                                                        [(ngModel)]="contractClientData.state" required>

                                                        <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                            Select State</option>
                                                        <option [ngValue]="data" *ngFor="let data of indian_states">
                                                            {{data}}
                                                        </option>

                                                    </select>

                                                    <p *ngIf="submitted && !state.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ State is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                        <div class="form-group label-floating">
                                            <label class="form-label">PIN Code</label>
                                            <div>
                                                <input type="text" class="form-control col-md-12 col-xs-12"
                                                    autocomplete="off" placeholder="Enter PIN Code" autofocus="true"
                                                    maxlength="6" name="pincode" #pincode="ngModel"
                                                    [(ngModel)]="contractClientData.pincode" required />

                                                <p *ngIf="submitted && !pincode.valid" class="help-block "
                                                    style="color: #ff5757;">
                                                    ⓘ PIN code is required
                                                </p>
                                                <p *ngIf="pinCodelength" class="help-block " style="color: #ff5757;">
                                                    ⓘ Enter 6 digit PIN code
                                                </p>
                                                <p *ngIf="pinCodeOnly" class="help-block " style="color: #ff5757;">
                                                    ⓘ Enter only number
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row py-0">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-label page-title-x">Client Billing</div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Billing Type</label>
                                                <div>
                                                    <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                        name="uom" placeholder="Select Location" #uom="ngModel"
                                                        [(ngModel)]="contractClientData.billing_type" required>

                                                        <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                            Select Type</option>
                                                        <option [ngValue]="data.billing_type"
                                                            *ngFor="let data of billing_types">
                                                            {{data.billing_name}}
                                                        </option>
                                                    </select>

                                                    <p *ngIf="submitted && !uom.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Unit of measurement is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Measurement Unit</label>
                                                <div>
                                                    <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                        name="uom" placeholder="Select Location" #uom="ngModel"
                                                        [(ngModel)]="contractClientData.uom" required>

                                                        <option value="Month" [selected]="true">Month</option>
                                                    </select>

                                                    <p *ngIf="submitted && !uom.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Measurement Unit is required
                                                    </p>

                                                </div>

                                            </div>
                                        </div> -->
                                    </div>

                                    <div class="actionBar pe-4 ps-4">
                                        <div class="clearfix"> </div>
                                        <div>
                                            <button class="btn btn-success" type="button" id="save-btn"
                                                style="display: inline-block"
                                                (click)="saveContractClientData(contractClientForm.form.valid)">Save</button>
                                            <button class="btn btn-success" type="button" id="update-btn"
                                                style="display: none"
                                                (click)="updateContractClientData(contractClientForm.form.valid)">Update</button>
                                            <button class="btn btn-danger ms-2" type="button"
                                                (click)="clear()">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="clearfix"> </div>

                        </form>

                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="actionBar">

            <div class="clearfix"> </div>
            <div class="pro-fab">
                <button class="btn btn-success" type="button" id="save-btn" style="display: inline-block"
                    (click)="saveContractClientData()">Save</button>
                <button class="btn btn-success" type="button" id="update-btn" style="display: none"
                    (click)="updateContractClientData()">Update</button>
                <button class="btn btn-danger ms-2" type="button" (click)="clear()">Clear</button>
            </div>
        </div> -->
    </div>
</div>

<div id="showAlertWarning" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-alert-circle fs-80 text-warning lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger mb-3">Are you sure you want to delete this data?</h4>
                <!-- <p class="mg-b-20 mg-x-20">This record has data attached to it! it can't be deleted.</p> -->

                <button aria-label="Close" class="btn btn-danger pd-x-25 mx-1" data-bs-dismiss="modal"
                    (click)="deleteContractClientData()">Delete</button>
                <button aria-label="Close" class="btn btn-primary pd-x-25 mx-1" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertDataAttachedToClient" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-link fs-80 text-danger lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: The data can't be deleted!</h4>
                <p class="mg-b-20 mg-x-20">This record has data attached to it! it can't be deleted.</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantDelete" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-x-circle fs-80 text-danger lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: The data can't be deleted!</h4>
                <p class="mg-b-20 mg-x-20">This record couldn't be deleted! Please try again.</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCouldntDelete" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-x-circle fs-80 text-primary lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: Client not found!</h4>
                <p class="mg-b-20 mg-x-20">Couldn't delete data.</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>