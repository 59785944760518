import { NumberValueAccessor } from "@angular/forms";

export class EmployeeMaster {
    uniqueemployeeid: number;
    employeeid: String;
    name: String;
    dateofbirth: Date;
    gender: String;
    address: String;
    phone: String;
    mobile: String;
    emergencycontactno: String;
    fathersname: String;
    husbandsname: String;
    designation: String;
    branchid: String;
    pfno: String;
    esino: String;
    pannumber: String;
    joiningdate: Date;
    confirmationdate: Date;
    resignsubmitdate: Date;
    noticeperiod: number;
    leavingdate: Date;
    pfjoiningdate: Date;
    departmentid: number;
    shiftid: number;
    employeetype: String;
    bloodgroup: String;
    timestamp: Date;
    userid: String;
    aadharno: String;
    bankname: String;
    bankaccount: String;
    ifsccode: String;
    uannumber: String;
    esibranch: number;
    mothername: String;
    spousedateofbirth: Date;
    nominee: String;
    hometown: String;
    caste: String;
    castecategory: String;
    qualification: String;
    additionalqualification: String;
    email: String;
    cadrewiseseniority: String;
    designationwiseseniority: String;
    payscale: String;
    efbfcode: number;
    quarters: Boolean;
    hracode: String;
    ccacode: String
    epfeligible: Boolean;
    esieligible: Boolean;
    unionjoiningdate: Date;
    presentaddressl1: String;
    presentaddressl2: String;
    presentcity: String;
    presentstate: String;
    presentcountry: String;
    presentzipcode: String;
    permanentaddressl1: String;
    permanentaddressl2: String;
    permanentcity: String;
    permanentstate: String;
    permanentcountry: String;
    permanentzipcode: String;

    totalAdditionComponents: number;
    totalDeductionComponents: number;

    empId: number;
    annualHoliday: number;
    daysInMonth: number;
    daysWorked: number;
    daysPresent: number;
    daysAbsent: number;
    extraDays: number;
    oddPunch: number;
    earnedLeave: number;
    leaveTaken: number;
    weeklyHolidays: number;
    lop: any;


    // for Attendance Matrix .... 
    employeeId: any;
    employeeName: any;

    //for penalty, remark
    penalty:Boolean;
	remarks:String;

}

export class EmployeeDesignation {
    id: number;
    uniqueemployeeid: number;
    designationid: number;
	desgname: string;
    name: string;
	userid: number;
	timestamp: Date;
	fromDate: Date;
    toDate: string;
}