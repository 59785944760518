<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <div class="alertcompExcelUpload"></div>
        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
              <div class="page-title">Manually Fed Salary</div>
            </div>
          </div> -->

          
            <!-- <div class="page-header d-xl-flex d-block">
                <div class="page-leftheader">
                    <div class="page-title">Download</div>
                </div>
            </div> -->

        <!-- <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12"> -->
              <div class="card">
                <div class="card-body">
                    <div class="x_content row p-4">
                        <div class="col-md-4 col-sm-6 col-xs-6">
                            <div class="form-group label-floating">
                                <label class="form-label"> Month </label>
                            <div>
                            <select id="month" class="form-control" [(ngModel)]="selectedMonth" required="required"
                            #selectMonth = "ngModel"
                            [ngClass]="(!selectMonth.valid && selectMonth.touched) ? 'is-invalid' : ''" >
                                <option value="" >Select Month</option>
                                <option *ngFor="let month of months" [ngValue]="month">{{ month.name }}</option>
                            </select>
                                    </div>
                            <span class="help-text text-danger" *ngIf="(!selectMonth.valid && selectMonth.touched)">Please Select Month</span>    
                                </div>
                        </div>

                        <div class="col-md-4 col-sm-6 col-xs-6">
                            <div class="form-group label-floating">
                                <label class="form-label"> Year </label>
                            <div>
                                <select id="year" class="form-control" [(ngModel)]="year" required="required"
                                #selectYear = "ngModel"
                                [ngClass]="(!selectYear.valid && selectYear.touched) ? 'is-invalid' : ''" >
                                    <option value="" selected>Select Year</option>
                                    <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                                </select>
                            </div>
                            <span class="help-text text-danger" *ngIf="(!selectYear.valid && selectYear.touched)">Please Select Year</span>
                                </div>
                        </div>

                        <div class="col-md-4 col-sm-6 col-xs-6">
                            <div class="form-group label-floating">
                                <label class="form-label"> Employee Name /
                                    ID</label>
                            <div>
                        <div class="col-md-12 px-0">
                            <input type="search" class="form-control" name="Employee Name" id="employeename"
                                placeholder="Enter Employee Name / ID " [(ngModel)]="query" autocomplete="off"
                                (input)="getEmployeeByNameOrId()" list="employeeMasterLucene"
                                autofocus="autofocus">

                                <!-- <input type="hidden" [(ngModel)]="selecteduniqueeEmpId"> -->
                            <datalist id="employeeMasterLucene">
                                <option [value]="emp.name + ' / ' + emp.employeeid" *ngFor="let emp of employeeMasterLucene" >
                                    <!-- {{emp.name}} -->
                                </option>
                            </datalist>
                        </div>
                        </div>
                        </div>
                        </div>

                        <!-- <div class="col-md-4 col-sm-6 col-xs-6">
                            <div class="form-group label-floating">
                                <label class="form-label"> Employee </label>
                            <div>
                                <select id="employee" class="form-control" [(ngModel)]="selecteduniqueeEmpId">
                                    <option selected [value]="" selected>Select Employee</option>
                                    <option *ngFor="let elist of employeeList" [value]="elist.uniqueemployeeid">{{elist.name}}</option>
                                </select>
                                    </div>
                                </div>
                        </div> -->

                        <!-- <div class="col-md-3 col-sm-6 col-xs-12">
                            <div class="form-group label-floating">
                                <label class="form-label"> Directory </label>
                                <div style="display: flex;">
                                    <input id="directory" type="text" class="form-control" [value]="directoryPath" readonly />
                                    <button class="btn btn-outline-secondary m-0" (click)="selectDirectory()">
                                        <i class="fa fa-folder-open"></i> 
                                    </button>
                                </div>
                            </div>
                        </div> -->
                        
                        <!-- <div class="col-md-3 col-sm-6 col-xs-6">
                            <div class="form-group label-floating"> -->
                                <div class="form-group col-md-12">
                                <button type="button" class="btn btn-primary w-100 w-md-20 mt-2" (click)="selectYear.control.markAsTouched(); selectMonth.control.markAsTouched(); downloadFile()">Download</button>
                                    <!-- <div>
                                        <button type="button" class="btn btn-primary btn-block w-100 mt-3 mt-md-0 p-2 p-md-1"
                                        (click)="selectYear.control.markAsTouched(); selectMonth.control.markAsTouched(); downloadFile()"> Download
                                        </button>
                                </div> -->
                           </div>
                        <!-- </div>
                        </div> -->
                        </div>

                    </div>
                </div>
            <!-- </div>
           
        </div> -->
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Upload</div>
            </div>
        </div> -->
        <!-- <div class="form-row">
            <div class="form-group col-md-12">
                <div class="row mt-4" >
                    <div class="col-xl-12 col-md-12 col-lg-12"> -->
                        <div class="card">
                            <div class="card-body p-4">
                                <div class="col-xs-12 mobile">
                                    <div>
                                        <label for="form-label" class="form-label text-left">Select File</label>
                                        <!-- <input type="file" id="file" class="form-control" name="myFilename"
                                            style="box-sizing: border-box;" required="required"
                                            enctype="multipart/form-data" (change)="handleFileInput($event)" /> -->
                                            <!-- In your component's HTML file -->
                                        <input type="file" id="file" class="form-control custom-file-input" name="myFilename" required 
                                        enctype="multipart/form-data" (change)="handleFileInput($event)">
                                        <span class="help-text text-danger" *ngIf="(!isFileValid)">Upload only '.xls' file! </span>

                                    </div>
                                    <button type="button" class="btn btn-success w-100 w-md-20 mt-2" (click)="uploadFile()">Upload
                                        File</button>
                                </div>
                            </div>
                        </div>
                    <!-- </div>
                </div>
            </div>
        </div> -->

        <div class="modal fade" id="excelErrors" tabindex="-1" aria-labelledby="excelErrors"
        aria-hidden="true" style="display: none;">
        <div class="modal-dialog  modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="excelErrors" style="font-size: 20px;">Error Details </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <!-- <h5 class="card-title mt-3 mb-3">Error Details</h5> -->
                        <ul class="list-group">
                            <li class="list-group-item" *ngFor="let error of excelErrors">
                                {{ error }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        (click)="clear()">Close</button>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
    