<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">
        </div>
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">TDS Statement Report</div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">

                                <!-- report from -->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Report From</label>
                                        <div>
                                            <input class="form-control" type="date" name="fromDate" autocomplete="off"
                                                autofocus="true" [disabled]="false" 
                                                 (ngModelChange)="checkFromAndToDate()" required [(ngModel)]="fromDate">
                                        </div>
                                    </div>
                                </div>
                                <!-- report two  -->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Report To</label>
                                         <div>
                                            <input class="form-control" type="date" name="toDate" autocomplete="off"
                                                autofocus="true" [disabled]="false"  (ngModelChange)="checkFromAndToDate()"
                                                 required [(ngModel)]="toDate">   
                                             <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                ⓘ End date should be greater than start date
                                            </p> 
                                         </div> 
                                    </div>
                                </div>
                               
                            </div>
 
                                <!-- <div class="col-md-3 col-sm-3 col-xs-3 mb-4"> -->
                                    <div  class="col-md-3 col-sm-3 col-xs-3 mb-4" style="margin-left:auto">
                                    <div class="form-group m-0">
                                        <button type="button" id="navigatetarget" class="btn btn-primary w-45"
                                            style="margin-right: 10px" (click)="search();">
                                            Search
                                        </button>
                                        <button type="button" class="btn btn-danger w-45"  (click)="clear()" >
                                            Clear
                                            
                                        </button>
                                    </div>
                                    </div>
                                <!-- </div> -->
                                <!-- getTDSReport()" -->
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="empty-div-one" id="navigatedest"></div>

<div class="modal fade" id="secondaryModal" tabindex="-1" aria-labelledby="secondaryModalLabel" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog  modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="secondaryModalLabel" style="font-size: 20px;">Detailed Report</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-primary ms-auto" (click)="TDSreportCSVDownload()">
                    <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title "> TDS Statement Report </h4>
                <button type="button" class="btn btn-primary ms-auto" (click)="TDSreportCSVDownload()" >
                    <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                </button>
            </div>

            <div class="empty-div-one" id="navigatedest"></div>
            <div class="card-body">
                <div class="col-xs-12  mobile view">

                    <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                        [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                       id="scrolllength">
                       <!-- (scrolled)="onScroll()"  -->
                        <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                            id="hr-table">
                            <thead class="tablerow">
                                <tr class=" stickyatTop rowcolors">

                                    <th class="border-bottom-0 w-10 fs-7 fixedcolumn">Employee ID</th>
                                    <th class="border-bottom-0 w-10 fs-7 fixedcolumn  secondcolumn">Employee Name</th>
                                    <th class="border-bottom-0 w-10 fs-7">PAN Number</th>
                                    <th class="border-bottom-0 w-10 fs-7">Salary Date</th>
                                    <th class="border-bottom-0 w-10 fs-7">Taxable Amount</th>
                                    <th class="border-bottom-0 w-10 fs-7">TDS Amount</th>
                                </tr>
                            </thead>
                             <tbody>
                                <tr *ngFor="let info of tdsStmtInfo ">
                                     <td class="fixedcolumn backgroundFW" data-th="Employee Id"> {{info.empId}}</td>
                                    <td class="fixedcolumn secondcolumn  backgroundFW backgroundSW"
                                        data-th="Employee Name"> {{info.empName}}</td>
                                        <td data-th="TDS"> {{info.panNumber}}</td>                               
                                    <td data-th="TDS"> {{info.salaryDate | date }}</td>
                                    <td data-th="TDS"> {{info.taxableAmount | currency:'INR':true:'1.2-2'}}</td>
                                    <td data-th="TDS"> {{info.tdsAmount | currency:'INR':true:'1.2-2'}}</td>
                                </tr>
                            </tbody> 
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>

    </div>
</div>
</div>
<!-- </div>  -->