<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <div class="alertcompExcelUpload"></div>
        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
              <div class="page-title">Statutory Report </div>
            </div>
          </div> -->
            <!-- <div class="page-header d-xl-flex d-block"> -->
                <!-- <div class="page-leftheader">
                    <div class="page-title">Download</div>
                </div> -->
            <!-- </div> -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
              <div class="card">
                <div class="card-body">
                    <div class="x_content row p-4">
                        <div class="col-md-4 col-sm-6 col-xs-6">
                            <div class="form-group label-floating">
                                <label class="form-label"> Month </label>
                            <div>
                            <select id="month" class="form-control" [(ngModel)]="selectedMonth" required="required"
                            #selectMonth = "ngModel"
                            [ngClass]="(!selectMonth.valid && selectMonth.touched) ? 'is-invalid' : ''" >
                                <option value="" >Select Month</option>
                                <option *ngFor="let month of months" [ngValue]="month">{{ month.name }}</option>
                            </select>
                                    </div>
                            <span class="help-text text-danger" *ngIf="(!selectMonth.valid && selectMonth.touched)">Please Select Month</span>    
                                </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-6">
                            <div class="form-group label-floating">
                                <label class="form-label"> Year </label>
                            <div>
                                <select id="year" class="form-control" [(ngModel)]="year" required="required"
                                #selectYear = "ngModel"
                                [ngClass]="(!selectYear.valid && selectYear.touched) ? 'is-invalid' : ''" >
                                    <option value="" selected>Select Year</option>
                                    <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                                </select>
                            </div>
                            <span class="help-text text-danger" *ngIf="(!selectYear.valid && selectYear.touched)">Please Select Year</span>
                                </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group label-floating">
                                <label></label>
                            <div>
                                <button type="button" class="btn btn-primary w-100 mt-1" (click)="selectYear.control.markAsTouched(); selectMonth.control.markAsTouched(); downloadFile()">Download</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    
